import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import { usePasteEventWatcher } from "./hooks/use-paste-event-watcher";
import { useEvent } from "effector-react";
import { changeCurrentArticlePayloadEv } from "entities/articles/model";

interface IArticleEditorFeature {
  defaultArticlePayload?: string;
}

const ArticleEditorFeature: FC<IArticleEditorFeature> = ({
  defaultArticlePayload,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [articleEditableContentWidth, setArticleEditableContentWidth] =
    useState(0);
  const contentWidth = articleEditableContentWidth - 32;
  const { items } = usePasteEventWatcher({ contentRef, contentWidth, ref });
  const changeCurrentArticlePayload = useEvent(changeCurrentArticlePayloadEv);

  useEffect(() => {
    if (!ref.current) return;
    setArticleEditableContentWidth(ref.current.offsetWidth);
  }, [articleEditableContentWidth]);

  useEffect(() => {
    if (defaultArticlePayload)
      changeCurrentArticlePayload(defaultArticlePayload);
  }, [changeCurrentArticlePayload, defaultArticlePayload]);

  const rootContainer = document.querySelector("#rootContainer");

  if (defaultArticlePayload && rootContainer)
    (rootContainer as any).innerHTML = defaultArticlePayload;

  return (
    <div className={`h-full mt-4`}>
      <div
        className={`flex items-center justify-center text-left w-[${contentWidth}px] max-w-[${contentWidth}px]`}
        ref={ref}
      >
        {contentWidth ? (
          <div
            onInput={(event) =>
              changeCurrentArticlePayload(event.currentTarget.innerHTML)
            }
            className={"flex flex-col w-full"}
            id={"rootContainer"}
          >
            {items.map((item, index) => (
              <Fragment key={index}>{item.jsx}</Fragment>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ArticleEditorFeature;
