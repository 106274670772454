import React, { FC, useEffect } from "react";
import { INavbarItem } from "./types";
import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { getIcon, getActiveIcon } from "./helpers";

const NavbarItem: FC<INavbarItem> = ({ title, to, numberOfMessages }) => {
  const img = getIcon(title);
  const imgActive = getActiveIcon(title);

  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <NavLink
      to={to}
      className={classNames(
        "cursor-pointer flex justify-between items-center px-6 py-[15px] border-l-4",
        {
          "border-[#0767FD] bg-gradient-to-r from-[#F2F7FE] to-[rgba(242, 247, 254, 0)]":
            isActive,
          "border-white": !isActive,
        },
      )}
    >
      <div className="flex flex-row justify-start gap-[10px]">
        {img ? (
          <div className={`flex items-end justify-center w-4 h-4`}>
            <img src={isActive ? imgActive || "" : img || ""} alt="img" />
          </div>
        ) : (
          <div className="w-4 h-4"></div>
        )}
        <span className={"font-normal text-sm"}>{title}</span>
      </div>

      {numberOfMessages ? (
        <div
          className="w-4 h-4 rounded-full text-white text-[7px] flex items-center justify-center"
          style={{
            backgroundColor: "#0767FD",
          }}
        >
          {numberOfMessages}
        </div>
      ) : null}
    </NavLink>
  );
};

export { NavbarItem };
