import { createStore, createEffect, createEvent, guard } from "effector";
import { IPromocode, IUpdatePromocode, IAddPromocode } from "./types";
import { promocodeAPI } from "./api";

// effects
const getAllPromocodeFx = createEffect({
  handler: async () => {
    const data = await promocodeAPI.fetchAllPromocode();

    return data.data;
  },
});

const updatePromocodeFx = createEffect({
  handler: async (params: IUpdatePromocode.Params) => {
    const data = await promocodeAPI.updatePromocode(params);
  },
});

const deletePromocodeFx = createEffect({
  handler: async (params: string) => {
    const data = await promocodeAPI.deletePromocode(params);
  },
});

const addPromocodeFx = createEffect({
  handler: async (params: FormData) => {
    const response = await promocodeAPI.addPromocode(params);
    return response.data;
  },
});

// store
const $promocode = createStore<IPromocode.Response | null>(null).on(
  getAllPromocodeFx.doneData,
  (_, data) => data,
);

// tracking upload
const createGuard = (fx: any) => {
  guard({
    source: fx.doneData,
    filter: (data) => data !== null,
  }).watch(() => {
    getAllPromocodeFx();
  });
};

createGuard(updatePromocodeFx);
createGuard(deletePromocodeFx);
createGuard(addPromocodeFx);

export {
  /**
   * @effects
   */
  getAllPromocodeFx,
  updatePromocodeFx,
  deletePromocodeFx,
  addPromocodeFx,
  /**
   * @store
   */
  $promocode,
};
