import React from "react";

// icons
import user from "shared/icons/navbar/user.svg";
import moderators from "shared/icons/navbar/moderators.svg";
import school from "shared/icons/navbar/school.svg";
import specialists from "shared/icons/navbar/specialists.svg";
import userActive from "shared/icons/navbar/users-active.svg";
import moderatorsActive from "shared/icons/navbar/moderator-active.svg";
import schoolActive from "shared/icons/navbar/school-active.svg";
import specialistsActive from "shared/icons/navbar/specialists-active.svg";

const getIcon = (title: string) => {
  let img;

  switch (title) {
    case "Пользователи":
      img = user;
      break;
    case "Модераторы":
      img = moderators;
      break;
    case "Специалисты":
      img = specialists;
      break;
    case "Онлайн-школы":
      img = school;
      break;
    default:
      img = null;
      break;
  }
  return img;
};

const getActiveIcon = (title: string) => {
  let img;

  switch (title) {
    case "Пользователи":
      img = userActive;
      break;
    case "Модераторы":
      img = moderatorsActive;
      break;
    case "Специалисты":
      img = specialistsActive;
      break;
    case "Онлайн-школы":
      img = schoolActive;
      break;
    default:
      img = null;
      break;
  }
  return img;
};

export { getIcon, getActiveIcon };
