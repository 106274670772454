import { IDoctorModel } from "entities/doctors";
import { MGetAllDoctors } from "entities/doctors";

// doctor filtering
const filterDoctors = /* #__PURE__ */ (
  users: IDoctorModel[],
  sortUp: boolean,
) => {
  const statusOrder = sortUp
    ? ["ACTIVE", "UNREGISTERED", "INACTIVE", "BLOCKED", "DELETED"]
    : ["DELETED", "BLOCKED", "INACTIVE", "UNREGISTERED", "ACTIVE"];

  const compareByStatus = (a: IDoctorModel, b: IDoctorModel) =>
    statusOrder.indexOf(a.account.state) - statusOrder.indexOf(b.account.state);

  return [...users].sort(compareByStatus);
};

// return final values
const finalyValues = /* #__PURE__ */ (
  users: MGetAllDoctors.Response | null,
  sort: boolean,
) => {
  const list: Array<IDoctorModel> = users ? Object.values(users)[0] : [];
  const filteredUsers = filterDoctors(list, sort);

  return filteredUsers;
};

export { finalyValues };
