import { AxiosResponse } from "axios";
import { $httpAuth } from "shared/services/http";
import {
  MGetAccessTokenByRefreshToken,
  MAuthorizeUserByCredentials,
  IGetCode,
} from "./types";

class AuthAPI {
  authorizeUserByCredentials(
    params: MAuthorizeUserByCredentials.Params,
  ): Promise<AxiosResponse<MAuthorizeUserByCredentials.Response>> {
    return $httpAuth.post("auth/sign-in", params);
  }

  getAccessTokenByRefreshToken(
    token: MGetAccessTokenByRefreshToken.Params,
  ): Promise<AxiosResponse<MGetAccessTokenByRefreshToken.Response>> {
    return $httpAuth.get("auth/access-token", {
      headers: {
        "Refresh-Token": token,
      },
    });
  }

  getCode(params: IGetCode) {
    return $httpAuth.post(`auth/send-phone-code?is_sms=true`, params);
  }
}

export const authAPI = new AuthAPI();
