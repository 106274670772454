import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Table, TableItem } from "entities/users";
import { IUserModel } from "entities/users/types";
import { IPromoWidget } from "../types";
import { rowNames, rowNamesFeetback } from "../lib";
import { finalyValues } from "../lib/helpers";
import { SortButton } from "shared/ui/sort-button";
import Pagination from "widgets/page-pagination";
import { Promo } from "entities/promocode";

const PromocodeWidget: React.FC<IPromoWidget> = ({
  haveDescription,
  haveChat,
  users,
  page,
  searchValue,
}) => {
  // sorted and filtered
  const [sort, setSort] = useState(true);
  const filteredUsers =
    users && users.List
      ? users.List.filter((user: Promo) => {
          const fullName = user.name.toLowerCase();
          const searchLower = searchValue!.toLowerCase();
          return fullName.includes(searchLower);
        })
      : [];

  const finallyRowNames = haveDescription ? rowNamesFeetback : rowNames;
  const sortedUsers = finalyValues(filteredUsers, sort);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(sortedUsers.length / 7);
  const startIndex = (currentPage - 1) * 7;
  const endIndex = startIndex + 7;
  const usersOnCurrentPage = sortedUsers.slice(startIndex, endIndex);

  const goToPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className={"w-full h-[80%] rounded-xl overflow-auto flex flex-col"}>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        goToPage={goToPage}
      />
      <Table
        cells={finallyRowNames.map((cell) => (
          <div
            key={cell.id}
            className={classNames("flex")}
            style={{ width: cell.width }}
          >
            <span className={"text-[#111111] opacity-30"}>{cell.title}</span>
            {cell.title === "Статус" && (
              <SortButton sort={sort} setSort={setSort} />
            )}
          </div>
        ))}
        rows={usersOnCurrentPage.map((row: Promo, index: number) => (
          <TableItem
            index={startIndex + index + 1}
            key={row.id}
            image={""}
            firstName={row.name}
            secondName={""}
            status={row.status}
            haveChat={haveChat}
            id={row.id}
            page={page}
            isPromo={true}
          />
        ))}
      />
      <div className="mt-auto">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          goToPage={goToPage}
        />
      </div>
    </div>
  );
};

export { PromocodeWidget };
