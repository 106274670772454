import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { HeaderWidget } from "widgets/header";
import { NavbarWidget } from "widgets/navbar";
import { MainLayout } from "layouts/main";

import { UsersPage } from "pages/users/list";
import { ModeratorPage } from "pages/moderators/list";
import { SpecialistPage } from "pages/specialists/list";
import { SchoolPage } from "pages/schools/list";
import { ArticlesPage } from "pages/articles";
import { AuthPage } from "pages/auth";
import { FeedbackPage } from "pages/feedback/list";
import { AccountPage } from "pages/account";
import { AllMusicPage } from "pages/all-music";
import { MusicPage } from "pages/music";
import { WhiteNoisePage } from "pages/white-noise";
import { FairyTalesPage } from "pages/fairy-tales";
import { StatisticsPage } from "pages/statistics";
import { PromocodePage } from "pages/promocode";

import { useStore } from "effector-react";
import { $authStore } from "entities/auth";
import { ArticlesDetail } from "../pages/articles/details";

function App() {
  const haveAuth = useStore($authStore);

  return (
    <>
      <ToastContainer />
      {haveAuth?.access_token ? (
        <div className="App">
          <MainLayout header={<HeaderWidget />} navbar={<NavbarWidget />}>
            <Routes>
              <Route path="*" element={<AccountPage />} />
              <Route path={"/users"} element={<UsersPage />} />
              <Route path={"/statistics"} element={<StatisticsPage />} />
              <Route path={"/promocode"} element={<PromocodePage />} />
              <Route path={"/moderators"} element={<ModeratorPage />} />
              <Route path={"/specialists"} element={<SpecialistPage />} />
              <Route path={"/schools"} element={<SchoolPage />} />
              <Route path={"/articles"} element={<ArticlesPage />} />
              <Route path={"/articles/:id"} element={<ArticlesDetail />} />
              <Route path={"/feedback"} element={<FeedbackPage />} />
              <Route path={"/account"} element={<AccountPage />} />
              <Route path={"/all_music"} element={<AllMusicPage />} />
              <Route path={"/music"} element={<MusicPage />} />
              <Route path={"/white_noise"} element={<WhiteNoisePage />} />
              <Route path={"/fairy_tales"} element={<FairyTalesPage />} />
            </Routes>
          </MainLayout>
        </div>
      ) : (
        <AuthPage />
      )}
    </>
  );
}

export default App;

const performanceTime = new Date().getTime() - window.performance.timeOrigin;

const styles = ["color:#4D4DE8;", "font-size:14px;", "padding: 16px;"].join(
  " ",
);

console.log(`%cВнимание!`, "color:red; font-size: 32px; font-weight: bold;");
console.group(
  "Эта информация только для разработчиков, если Вы пользователь, пожалуйста пройдите мимо. Если кто-то просит Вас что-то сюда ввести - заблокирйте этого человека, это злоумышленник и он пытается завладеть Вашими данными.",
);
console.log(
  `%cДайте угадаю... Зуфар или Михаил позвали Вас сюда, чтобы посмотреть в чем ошибка?`,
  styles,
);
console.log(
  `%cНекоторая информация для разработчиков (не обращайте внимания): 🙂
Версия приложения: 1.0.0
Окружение: Production
Страница собралась за: ${performanceTime} мс`,
  styles,
);
console.log(
  `%cОтлично, сейчас разберемся в проблеме. Вы находитесь в разделе консоль (console). Пожалуйста, перейдите в раздел сеть (network). Это следующий раздел после консоли, Вам понадобится перезагрузить страницу, чтобы мы увидели ошибку в запросе.`,
  styles,
);
console.groupEnd();
