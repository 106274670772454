import React from "react";
import { MGetMusic, IMusic } from "entities/audio";

export const musicHandler = /* #__PURE__ */ (
  musicData: MGetMusic.Response | null,
) => {
  if (musicData !== null) {
    return Object.values(musicData.music);
  }
  return [];
};

export const updateMusic = async (
  isLoading: boolean,
  musicData: MGetMusic.Response | null,
  setMusic: React.Dispatch<React.SetStateAction<IMusic[]>>,
) => {
  if (!isLoading && musicData !== null) {
    const newMusic = musicHandler(musicData);
    setMusic(newMusic);
  }
};
