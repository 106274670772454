import { MGetAllUsers, usersAPI } from "../users";
import { createStore, createEffect, createEvent, guard } from "effector";
import { UserRoleEnum } from "../auth";
import { ICreateModerator, IUpdateModerator } from "./types";
import { moderatorsApi } from "./api";

// get

const getAllModeratorsFx = createEffect({
  handler: async (
    params: Omit<Partial<MGetAllUsers.Params>, "role"> | void,
  ) => {
    const data = await usersAPI.fetchAllAccounts({
      ...params,
      role: UserRoleEnum.MODERATOR,
    });

    return data.data;
  },
});

// add

const addModeratorFx = createEffect({
  handler: async (params: ICreateModerator.Params) => {
    const response = await moderatorsApi.addModerator({ ...params });
    return response.data;
  },
});

// update

const updateModeratorEmail = createEvent<IUpdateModerator.Email>();
const updateModeratorPhone = createEvent<IUpdateModerator.Phone>();
const updateModeratorState = createEvent<IUpdateModerator.State>();
const updateModeratorFirstName = createEvent<IUpdateModerator.FirstName>();
const updateModeratorSecondName = createEvent<IUpdateModerator.SecondName>();
const updateModeratorLastName = createEvent<IUpdateModerator.LastName>();

// tracking upload

const createGuard = (fx: any) => {
  guard({
    source: fx.doneData,
    filter: (data) => data !== null,
  }).watch(() => {
    getAllModeratorsFx();
  });
};

createGuard(addModeratorFx);

// store

const $moderatorUsers = createStore<MGetAllUsers.Response | null>(null).on(
  getAllModeratorsFx.doneData,
  (_, data) => data,
);

const $moderatorEmailStore = createStore({
  email: "",
}).on(updateModeratorEmail, (email, newData) => ({
  ...email,
  ...newData,
}));

const $moderatorPhoneStore = createStore({
  phone: "",
}).on(updateModeratorPhone, (phone, newData) => ({
  ...phone,
  ...newData,
}));

const $moderatorStateStore = createStore({
  state: "ACTIVE",
}).on(updateModeratorState, (state, newData) => ({
  ...state,
  state: newData.state,
}));

const $moderatorFirstNameStore = createStore({
  first_name: "",
}).on(updateModeratorFirstName, (first_name, newData) => ({
  ...first_name,
  first_name: newData.first_name,
}));

const $moderatorSecondNameStore = createStore({
  second_name: "",
}).on(updateModeratorSecondName, (second_name, newData) => ({
  ...second_name,
  second_name: newData.second_name,
}));

const $moderatorLastNameStore = createStore({
  last_name: "",
}).on(updateModeratorLastName, (last_name, newData) => ({
  ...last_name,
  last_name: newData.last_name,
}));

export {
  /**
   * @effects
   */
  getAllModeratorsFx,
  addModeratorFx,
  /**
   * @store
   */
  $moderatorUsers,
  $moderatorEmailStore,
  $moderatorPhoneStore,
  $moderatorStateStore,
  $moderatorFirstNameStore,
  $moderatorLastNameStore,
  $moderatorSecondNameStore,
  /**
   * @event
   */
  updateModeratorPhone,
  updateModeratorEmail,
  updateModeratorState,
  updateModeratorFirstName,
  updateModeratorSecondName,
  updateModeratorLastName,
};
