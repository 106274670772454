import React, { FC, useEffect, useState } from "react";
import { motion } from "framer-motion";
// shared
import { Button } from "shared/ui/button";
// entities
import { $accountInfo } from "entities/account/model";
import {
  putOnlineSchoolChangeInfoFx,
  patchAccountChangePhoneFx,
  patchDoctorChangeInfoFx,
} from "entities/account/model";
import { $moderatorProfileMeStore } from "entities/users";
// lib
import { IModuleEditingAccount } from "../types";
import { useStore } from "effector-react";

const ModuleEditingAccount: FC<IModuleEditingAccount> = ({
  visible,
  onChange,
  title,
  viewTitle = true,
}) => {
  // states
  const [showForm, setShowForm] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");

  // info about ROLE user
  const info = useStore($accountInfo)?.account;

  // change info school
  const handlerSchool = () => {
    if (viewTitle && title === "Имя") {
      putOnlineSchoolChangeInfoFx({
        email: info?.account.email ?? "",
        info: info?.account.info ?? "",
        name: inputValue,
      });
    } else if (viewTitle && title === "Почта") {
      putOnlineSchoolChangeInfoFx({
        email: inputValue,
        info: info?.account.info ?? "",
        name: info?.online_school.name ?? "",
      });
    } else if (viewTitle && title === "Информация") {
      putOnlineSchoolChangeInfoFx({
        email: info?.account.email ?? "",
        info: inputValue,
        name: info?.online_school.name ?? "",
      });
    } else if (viewTitle && title === "Телефон для входа") {
      patchAccountChangePhoneFx({
        id: info?.account.id ?? "",
        number: inputValue,
      });
    } else if (viewTitle && title === "Заголовок") {
    }
  };

  // change info doctor
  const handlerDoctor = () => {
    if (viewTitle && title === "Имя") {
      patchDoctorChangeInfoFx({
        email: info?.account.email ?? "",
        info: info?.account.info ?? "",
        gender: info?.account.gender ?? "",
        profession: info?.doctor.profession ?? "",
        first_name: firstName,
        second_name: lastName,
        last_name: middleName,
      });
    } else if (viewTitle && title === "Почта") {
      patchDoctorChangeInfoFx({
        email: inputValue,
        info: info?.account.info ?? "",
        gender: info?.account.gender ?? "",
        profession: info?.doctor.profession ?? "",
        first_name: info?.account.first_name ?? "",
        second_name: info?.account.second_name ?? "",
        last_name: info?.account.last_name ?? "",
      });
    } else if (viewTitle && title === "Информация") {
      patchDoctorChangeInfoFx({
        email: info?.account.email ?? "",
        info: inputValue,
        gender: info?.account.gender ?? "",
        profession: info?.doctor.profession ?? "",
        first_name: info?.account.first_name ?? "",
        second_name: info?.account.second_name ?? "",
        last_name: info?.account.last_name ?? "",
      });
    } else if (viewTitle && title === "Телефон для входа") {
      patchAccountChangePhoneFx({
        id: info?.account.id ?? "",
        number: inputValue,
      });
    } else if (
      viewTitle &&
      title === "Профессиональный бейджик (в одно слово)"
    ) {
      patchDoctorChangeInfoFx({
        email: info?.account.email ?? "",
        info: info?.account.info ?? "",
        gender: info?.account.gender ?? "",
        profession: inputValue,
        first_name: info?.account.first_name ?? "",
        second_name: info?.account.second_name ?? "",
        last_name: info?.account.last_name ?? "",
      });
    }
  };

  const handler = () => {
    if (info?.account.role === "DOCTOR") {
      handlerDoctor();
    } else if (info?.account.role === "ONLINE_SCHOOL") {
      handlerSchool();
    }
  };

  // hide form
  const hideForm = () => {
    setShowForm(false);
    setTimeout(() => {
      onChange(false);
      setShowForm(true);
    }, 200);
  };

  return visible ? (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      animate={{
        y: showForm ? "-100px" : "400px",
      }}
      className={"ml-16 absolute top-[40vh] left-[35vw]"}
    >
      <div
        className={
          "bg-white w-[418px] border-gray-ec border-[1px] flex flex-col items-center justify-between rounded-2xl p-8 shadow-sm relative"
        }
      >
        <div className={"w-full flex flex-col items-center"}>
          <span className={"text-[22px] font-medium mb-5 mt-3 text-center"}>
            {viewTitle && `Редактировать "`}
            {title}
            {viewTitle && `"`}
          </span>
        </div>
        {title === "Имя" && info?.account.role !== "ONLINE_SCHOOL" ? (
          <>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="Имя"
              className="border border-gray-300 rounded p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Фамилия"
              className="border border-gray-300 rounded p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent mt-4"
            />
            <input
              type="text"
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
              placeholder="Отчество"
              className="border border-gray-300 rounded p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent mt-4"
            />
          </>
        ) : (
          <textarea
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className={
              "border border-gray-300 rounded p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            }
          />
        )}
        <div className={"flex w-full mt-4"}>
          <Button
            onClick={() => {
              handler();
              hideForm();
            }}
            paddingVertical={12}
            className={"mr-4"}
          >
            {viewTitle ? "Изменить" : "Добавить"}
          </Button>
          <Button
            paddingVertical={12}
            background={"#FF7253"}
            onClick={hideForm}
          >
            Отмена
          </Button>
        </div>
      </div>
    </motion.div>
  ) : null;
};

export { ModuleEditingAccount };
