import React, { FC, useEffect, useState } from "react";
import { useStore } from "effector-react";
import { motion } from "framer-motion";
// shared
import { Button } from "shared/ui/button";
// entities
import { PhotoPlatesWidget } from "widgets/chat/photo";
import {
  patchChangeChatAvatarFx,
  patchChangeChatNameFx,
} from "entities/chats/model";
import { __BASE_URL__ } from "shared/services/http";
// lib
import { IModuleEditingChat } from "../types";

const ModuleEditingChat: FC<IModuleEditingChat> = ({
  onChange,
  title,
  chatAvatar,
  chatId,
}) => {
  // states
  const [showForm, setShowForm] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [avatarString, setAvatarString] = useState<string | undefined>(
    chatAvatar
  );

  const handler = () => {
    if (avatarFile !== null) {
      patchChangeChatAvatarFx({ chat_id: chatId, avatar: avatarFile as any });
    }

    patchChangeChatNameFx({ chat_id: chatId, new_name: inputValue });
    hideForm();
  };

  const hideForm = () => {
    setShowForm(false);
    setTimeout(() => {
      onChange(false);
      setShowForm(true);
    }, 200);
  };

  useEffect(() => {
    if (avatarFile !== null) {
      const reader = new FileReader();

      reader.onload = function (e) {
        if (e.target && e.target.result) {
          const imageUrl = e.target.result;
          setAvatarString(imageUrl as string);
        }
      };
      reader.readAsDataURL(avatarFile);
    }
  }, [avatarFile]);

  return (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      animate={{
        y: showForm ? "-100px" : "400px",
      }}
      className={"ml-16 absolute top-[40vh] left-[35vw]"}
    >
      <div
        className={
          "bg-[#EAF2FC] w-[418px] border-gray-ec border-[1px] flex flex-col items-center justify-between rounded-2xl p-8 shadow-sm relative"
        }
      >
        <div className={"w-full flex flex-col items-center"}>
          <span className={"text-[22px] font-medium mb-5 mt-3 text-center"}>
            {title}
          </span>
        </div>
        <div className="mb-4">
          <PhotoPlatesWidget
            title="Изображение чата"
            img={avatarString}
            saveImg={setAvatarFile}
          />
        </div>
        <input
          type="text"
          value={inputValue}
          placeholder="Название чата"
          onChange={(e) => setInputValue(e.target.value)}
          className={
            "border border-gray-300 rounded p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          }
        />
        <div className={"flex w-full mt-4"}>
          <Button onClick={handler} paddingVertical={12} className={"mr-4"}>
            Сохранить
          </Button>
          <Button
            onClick={() => onChange(false)}
            paddingVertical={12}
            background="#FF7523"
          >
            Закрыть
          </Button>
        </div>
      </div>
    </motion.div>
  );
};

export { ModuleEditingChat };
