import { useEffect, useState, useMemo } from "react";
import { SocketFabric } from "shared/services/socket";
import { IUserModel } from "../../users";
import { access_token } from "entities/auth";
import { useStore } from "effector-react";
import { $individualChatMessageStore } from "../model";

const useChatListWebSocket = () => {
  const socket = useMemo(
    () => new SocketFabric("wss://api.mama-api.ru/api/v1/chat/ws"),
    []
  );

  const [isConnected, setIsConnected] = useState(false);
  const [isError, setIsError] = useState("");

  useEffect(() => {
    // Соединение установлено
    socket.onOpen = () => {
      console.log("соединение установлено успешно");
      setIsConnected(true);
    };

    // Получено сообщение
    socket.onMessage = (event: IUserModel) => {
      console.log("Получены данные " + event);
    };

    // Соединение закрыто
    socket.onClose = (event) => {
      setIsConnected(false);
      if (event.wasClean) {
        console.log("Соединение закрыто чисто");
      } else {
        console.log("Обрыв соединения");
      }
      console.log("Код: " + event.code + " причина: " + event.reason);
    };

    // Ошибка соединения
    socket.onError = (error) => {
      console.log("соединение не установлено"); // Сообщение при ошибке
      console.error("Ошибка " + error);
      setIsError(error.type);
    };
  }, [isConnected]);

  const sendMessage = (
    message: string,
    chatId: string,
    typeChat: "solo" | "group",
    event: "message" | "join"
  ) => {
    socket.sendMessage(
      JSON.stringify({
        event: event,
        type_chat: typeChat,
        data: {
          access_token: "Bearer " + access_token,
          chat_id: chatId,
          message: message,
          reply: "",
          upload_data: [
            {
              file_path: "",
              type_file: "",
              filename: "",
            },
          ],
        },
      })
    );
  };

  return { isConnected, sendMessage };
};

export { useChatListWebSocket };
