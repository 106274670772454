import React, { FC, useState } from "react";
import { InformationPlate } from "shared/ui/information-plate";
import { Button } from "shared/ui/button";
import plus from "shared/icons/plus.svg";
import { AccountAllCourse } from "entities/account";
import {
  postOnlineSchoolAddCourseFx,
  getOnlineSchoolCoursesFX,
  deletedOnlineSchoolAddCourseFx,
} from "entities/account/model";
import { createEffect, createStore, guard } from "effector";

interface ICoursesWidget {
  items: AccountAllCourse.Respose | null;
  schoolId: string;
}

const CoursesWidget: FC<ICoursesWidget> = ({ items, schoolId }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");

  const handleAddCourse = () => {
    postOnlineSchoolAddCourseFx({
      link: link,
      online_school_id: schoolId,
      short_description: description,
      title: title,
    });

    // pop-up
    setPopupOpen(false);
    setTitle("");
    setDescription("");
    setLink("");
  };

  const handleDeletedCourse = (id: string) => {
    deletedOnlineSchoolAddCourseFx(id);
  };

  // tracking upload
  const createGuard = (fx: any) => {
    guard({
      source: fx.doneData,
      filter: (data) => data !== null,
    }).watch(() => {
      getOnlineSchoolCoursesFX({ school_id: schoolId });
    });
  };

  createGuard(deletedOnlineSchoolAddCourseFx);
  createGuard(postOnlineSchoolAddCourseFx);

  return (
    <div>
      <div className="text-gray-400 text-base mb-5">Курсы в профиле</div>
      {items?.list &&
        items.list.map((item, index) => (
          <div className="flex flex-col" key={index}>
            <div className="flex flex-col justify-end items-end gap-2.5 rounded-[10px] p-[20px] bg-[#EAF2FC] mb-5">
              <InformationPlate title="Заголовок" children={item.title} />
              <InformationPlate
                title="Краткое описание"
                children={item.short_description}
              />
              <InformationPlate title="Ссылка" children={item.link} />
              <Button
                children="удалить"
                width="30%"
                background="#FF7253"
                onClick={() => handleDeletedCourse(item.id)}
              />
            </div>
          </div>
        ))}
      <div className="w-[40px]">
        <Button
          children={plus}
          childrenIsImg={true}
          background="#EAF2FC"
          color="#0767FD"
          onClick={() => setPopupOpen(true)}
        />
      </div>
      {popupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-5 rounded-lg">
            <input
              type="text"
              placeholder="Заголовок"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="mb-3 p-2 border border-gray-300 rounded-md w-full"
            />
            <textarea
              placeholder="Краткое описание"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="mb-3 p-2 border border-gray-300 rounded-md w-full resize-none"
            />
            <input
              type="text"
              placeholder="Ссылка"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              className="mb-3 p-2 border border-gray-300 rounded-md w-full"
            />
            <div className="flex justify-end gap-8">
              <Button children="Добавить" onClick={handleAddCourse} />
              <Button children="Отмена" onClick={() => setPopupOpen(false)} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { CoursesWidget };
