import React, { FC } from "react";
import classNames from "classnames";
import ArrowLeft from "shared/icons/controls/arrow-left.svg";
import ArrowRight from "shared/icons/controls/arrow-right.svg";
import { box } from "widgets/pagination/lib/helpers";

interface IPagination {
  currentPage: number;
  totalPages: number;
  goToPage: (pageNumber: number) => void;
}

const Pagination: FC<IPagination> = ({ currentPage, totalPages, goToPage }) => {
  const maxVisiblePages = 10;
  let startPage = 1;
  let endPage = totalPages;

  if (totalPages > maxVisiblePages) {
    const halfVisiblePages = Math.floor(maxVisiblePages / 2);

    startPage = Math.max(1, currentPage - halfVisiblePages);
    endPage = Math.min(totalPages, currentPage + halfVisiblePages);

    if (currentPage - startPage < halfVisiblePages) {
      endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
    } else if (endPage - currentPage < halfVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }
  }

  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="pagination flex flex-row items-center justify-center my-3">
      <button
        onClick={() => goToPage(currentPage - 1)}
        disabled={currentPage === 1}
        className={classNames("mr-3 bg-white", box)}
      >
        <img src={ArrowLeft} alt="Previous" />
      </button>
      {pageNumbers.map((pageNumber) => (
        <button
          key={pageNumber}
          onClick={() => goToPage(pageNumber)}
          className={classNames(
            "bg-white",
            box,
            "w-8 h-8 flex justify-center items-center mr-3 bg-white",
            currentPage === pageNumber && "bg-gray-200",
          )}
        >
          {pageNumber}
        </button>
      ))}
      <button
        onClick={() => goToPage(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={classNames("ml-3 bg-white", box)}
      >
        <img src={ArrowRight} alt="Next" />
      </button>
    </div>
  );
};

export default Pagination;
