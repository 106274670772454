import React, { ChangeEvent, FC } from "react";

interface SearchInputProps {
  placeholder: string;
  onInputChange: (value: string) => void;
}

const SearchInput: FC<SearchInputProps> = ({ placeholder, onInputChange }) => {
  const handleInputChange = (e: any) => {
    const { value } = e.target;
    onInputChange(value); // Передаем значение введенного текста наверх
  };

  return (
    <input
      type="text"
      placeholder={placeholder}
      onChange={handleInputChange}
      className="border border-gray-300 rounded p-2"
    />
  );
};

export default SearchInput;
