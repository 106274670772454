import {
  MGetAllUsers,
  MUpdateUsers,
  MUpdateAccountState,
  MGetModeratorProfileMe,
} from "./types";
import { AxiosResponse } from "axios";
import { $http } from "shared/services/http";
import { UserStatusEnum } from "entities/auth";

class UsersAPI {
  /**
   * @description Получает список пользователей (устарело)
   * @param params
   */
  fetchAll(
    params: Partial<MGetAllUsers.Params> | void,
  ): Promise<AxiosResponse<MGetAllUsers.Response>> {
    console.warn(
      "Метод this.fetchAll является устаревшим, мигрируйте на this.fetchAllAccounts",
    );
    return $http.get("user", { params });
  }

  /**
   * @description Получает список пользователей
   * @param params
   */
  fetchAllAccounts(
    params: Partial<MGetAllUsers.Params> | void,
  ): Promise<AxiosResponse<MGetAllUsers.Response>> {
    return $http.get("account/list", { params });
  }

  /**
   * @description Изменяем статус пользователей
   */
  updateUsersStatus(
    id: string,
    status: UserStatusEnum,
  ): Promise<AxiosResponse<MUpdateUsers.Response>> {
    return $http.patch(`account/status`, null, {
      params: {
        id: id,
        status: status,
      },
    });
  }

  /**
   * @description Изменяем статус пользователей
   */
  updateAccounState(
    id: string,
    state: UserStatusEnum,
  ): Promise<AxiosResponse<MUpdateAccountState.Response>> {
    return $http.patch(`account/state`, null, {
      params: {
        id: id,
        state: state,
      },
    });
  }

  /**
   * @description Изменяем статус пользователей
   */
  fetchMe(): Promise<AxiosResponse<MGetModeratorProfileMe.Response>> {
    return $http.get("moderator/me");
  }
}

export const usersAPI = new UsersAPI();
