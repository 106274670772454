import React, { useEffect, useState } from "react";
import { useEvent, useStore } from "effector-react";
import { NavbarItem } from "entities/navbar";
import { $moderatorProfileMeStore, getMyProfileFx } from "entities/users";
import Logo from "shared/icons/logoBlackText.svg";
import { FeedbackModel, IFeedbackModel } from "entities/feedback";
import { AccountModel } from "entities/account";

const NavbarWidget = () => {
  // account info
  const accountInfo = useStore(AccountModel.$accountInfo);

  useEffect(() => {
    AccountModel.getAccountInfoFx();
  }, []);

  // is ADMIN or MODERATOR
  const getMyProfile = useEvent(getMyProfileFx);
  const moderatorProfileMe = useStore($moderatorProfileMeStore);
  const [userRole, setUserRole] = useState<string | undefined>("");
  const [error, setError] = useState<boolean>(false);
  const expandedRoles = ["ADMIN", "MODERATOR"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getMyProfile();
        setError(true);
      } catch (error) {
        setError(false);
      }

      void FeedbackModel.getFeedbackListFx();
    };

    void fetchData();
  }, [getMyProfile]);

  useEffect(() => {
    setUserRole(moderatorProfileMe?.account.role);
  }, [moderatorProfileMe]);

  // count nem feedback
  const feedbackList = useStore(FeedbackModel.feedbackList);
  const newFeedback = feedbackList?.feedback?.filter(
    (feedback: IFeedbackModel) => feedback.status === "NEW",
  );
  const numberOfNewFeedback = newFeedback ? newFeedback.length : 0;

  return (
    <div className={"h-full w-[242px] pt-8 border-gray-eb border-r-[1px]"}>
      <img className={"ml-6 mb-12"} src={Logo} alt={"Logo"} />
      {expandedRoles.includes(userRole || "") && error && (
        <>
          <NavbarItem title={"Пользователи"} to={"/users"} />
          <NavbarItem title={"Модераторы"} to={"/moderators"} />
          <NavbarItem title={"Специалисты"} to={"/specialists"} />
          <NavbarItem title={"Онлайн-школы"} to={"/schools"} />
        </>
      )}
      <NavbarItem title={"Статьи"} to={"/articles"} />
      {expandedRoles.includes(userRole || "") && error && (
        <>
          <NavbarItem
            title={"Обратная связь"}
            to={"/feedback"}
            numberOfMessages={numberOfNewFeedback}
          />
          <NavbarItem title={"Статистика"} to={"/statistics"} />
          <NavbarItem title={"Промокоды"} to={"/promocode"} />
        </>
      )}
      <NavbarItem title={"Аккаунт"} to={"/account"} />
      {expandedRoles.includes(userRole || "") && error && (
        <>
          <NavbarItem title={"Музыка"} to={"all_music"} />
        </>
      )}
    </div>
  );
};

export { NavbarWidget };
