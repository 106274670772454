import { CreateArticleFeature } from "widgets/articles";
import { useParams } from "react-router-dom";
import { ArticleFormFeature } from "features/articles/article-form";
import React, { useEffect } from "react";
import { useEvent, useStore } from "effector-react";
import { ArticlesModel } from "entities/articles";
import { BarLoader } from "react-spinners";

const ArticlesDetail = () => {
  const { id } = useParams();
  const getOneArticle = useEvent(ArticlesModel.getOneArticleFX);
  const currentArticle = useStore(ArticlesModel.$currentArticle);
  const isArticlesLoading = useStore(ArticlesModel.getOneArticleFX.pending);
  const defaultArticlePayload =
    currentArticle?.article.body.find((item) => item.type === "text")
      ?.payload ?? "";

  useEffect(() => {
    if (!id) return void -1;
    void getOneArticle({ id });
  }, [getOneArticle, id]);

  if (!id) return null;
  if (isArticlesLoading)
    return (
      <div className="flex items-center justify-center w-full h-[75vh]">
        <BarLoader color="#36D7B7" width={500} loading />
      </div>
    );

  return (
    <div className="flex flex-row flex-wrap w-full bg-[#F5F8FE] w-[calc(100vw - 242px)] max-w-[calc(100vw - 242px)]">
      <CreateArticleFeature haveAbilityToDelete />
      <ArticleFormFeature
        haveAbilityToDelete
        defaultTitle={currentArticle?.article.title}
        defaultTags={currentArticle?.article.tags?.join()}
        defaultArticlePayload={defaultArticlePayload}
      />
    </div>
  );
};

export { ArticlesDetail };
