import React, { FC, useState, Dispatch, SetStateAction } from "react";
import { PhotoPlate } from "shared/ui/photo-plate";
import { Button } from "shared/ui/button";
import { ModuleCreateFeature } from "features/module/module-create";
import icon from "shared/icons/camera.svg";

interface IPhotoPlatesItem {
  title: string;
  img: string | undefined;
  saveImg?: Dispatch<File | null>;
}

const PhotoPlatesWidget: FC<IPhotoPlatesItem> = ({ title, img, saveImg }) => {
  const [visible, setVisible] = useState(false);

  const handleAddButtonClick = () => {
    setVisible(!visible);
  };
  return (
    <div>
      <PhotoPlate
        key={1}
        title={title}
        img={img}
        button={
          <Button
            children={"Сменить"}
            background="rgba(7, 103, 253, 0.1)"
            color="#0767FD"
            onClick={handleAddButtonClick}
          />
        }
      />
      {visible && (
        <div className="overlay">
          <ModuleCreateFeature
            icon={icon}
            title="Выбрать файл"
            onChange={handleAddButtonClick}
            visible={visible}
            isImgInput={false}
            placeholderForInput="Добавить файл"
            type="file"
            categoryMusic="music"
            typeModule="changeChatAvatar"
            saveImg={saveImg}
          />
        </div>
      )}
    </div>
  );
};

export { PhotoPlatesWidget };
