import { IMusic } from "entities/audio";
import { rowNames, rowNamesMusic } from "widgets/users/users-items/lib";

// music filtering
const filterMusic = /* #__PURE__ */ (
  musicResponse: IMusic[],
  sortUp: boolean,
) => {
  const statusOrder = sortUp
    ? ["ACTIVE", "SUSPENDED", "UNKNOWN"]
    : ["SUSPENDED", "ACTIVE", "UNKNOWN"];

  const compareByStatus = (a: IMusic, b: IMusic) => {
    const statusAIndex = statusOrder.indexOf(a.status || "UNKNOWN");
    const statusBIndex = statusOrder.indexOf(b.status || "UNKNOWN");

    return statusAIndex - statusBIndex;
  };

  return [...musicResponse].sort(compareByStatus);
};

// return final values
const finalyValues = /* #__PURE__ */ (
  music: IMusic[],
  sort: boolean,
  isAllMusic: boolean,
) => {
  const list = music ? Object.values(music) : [];
  const rowsTitle = isAllMusic ? rowNamesMusic : rowNames;
  const filteredMusic = filterMusic(list, sort);

  return { rowsTitle, filteredMusic };
};

export { finalyValues };
