import React, { FC, useEffect, useState } from "react";
import { useEvent, useStore } from "effector-react";
import classNames from "classnames";
// shared
import { Select } from "shared/ui/select";
import { Button } from "shared/ui/button";
// entities
import { goFeedbackChatFx } from "entities/feedback/model";
// lib
import { FeedbackStatusEnum, IFeedbackItem } from "./types";
import {
  finalyValues,
  getUserStatus,
  row,
  rowText,
} from "../../helpers/table-item";
import { changeStatusFeedbackFx } from "entities/feedback/model";
import { ChatFeature } from "features/chats";
import { ChatModel } from "entities/chats";
import { MChatById } from "entities/chats/types";

const TableItem: FC<IFeedbackItem> = ({
  image,
  index,
  firstName,
  secondName,
  lastName,
  description,
  status,
  id,
  page,
  id_account,
}) => {
  const statusUser = getUserStatus(status);
  const [selectedUserStatus, setSelectedUserStatus] = useState(status);
  const [isVisible, setIsVisible] = useState(false);

  const { avatarUser, nameUser, statusOptions, statusBgc } = finalyValues(
    image,
    firstName,
    secondName,
    page,
    status,
    lastName,
  );

  // chat
  const setCurrentSoloChat = useEvent(ChatModel.setCurrentSoloChatEv);
  const getChat = useEvent(ChatModel.postChatByIdFx);
  const storeChat = useStore(ChatModel.$chatByIdStore);

  const handleStatusChange = (selectedValue: string) => {
    setSelectedUserStatus(selectedValue as FeedbackStatusEnum);

    changeStatusFeedbackFx({
      id: id || "",
      status: selectedValue as string,
    });
  };

  const handleViewFeedbackChat = async (idChat: MChatById.Params) => {
    setIsVisible(true);
    try {
      const chat = await goFeedbackChatFx(idChat);
      console.log(chat.chat);
      //@ts-ignore
      setCurrentSoloChat(chat.chat);
    } catch (error) {
      console.error("Ошибка при загрузке чата:", error);
    }
  };

  return (
    <div
      className={
        "flex items-center justify-between w-full py-[11px] p-6 hover:bg-white rounded-xl cursor-pointer"
      }
    >
      <div className={classNames(rowText, "w-[6%]")}>
        <span className={classNames(rowText)}>{index}</span>
      </div>
      <div className={classNames(row, "w-[13.5%]")}>
        <div className={"flex items-center "}>
          <img
            src={avatarUser}
            width={29}
            height={29}
            className={"rounded-full mr-4 w-[29px] h-[29px]"}
            alt="AvatarIcon"
          />
          <span className={classNames(rowText)}>{nameUser}</span>
        </div>
      </div>
      {description && (
        <div
          className={classNames(row, "w-[33.5%]")}
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: 3,
            lineHeight: "14px",
          }}
        >
          <span className={classNames(rowText, "text-[10px]")}>
            {description}
          </span>
        </div>
      )}
      <div className={classNames(row, "w-[23.5%]")}>
        <span
          className={classNames(rowText, "px-4 py-[7px] rounded-[6px]")}
          style={{
            backgroundColor: statusBgc,
          }}
        >
          {statusUser}
        </span>
      </div>

      <div className={classNames(row, "w-[11%]")}>
        <Select
          label={"Выберите статус"}
          options={statusOptions as { value: string; label: string }[]}
          onSelect={handleStatusChange}
        />
      </div>

      <div className={"w-[5%]"}>
        <Button
          children={"в чат"}
          background="#FFF"
          color="#000"
          haveBorder={true}
          borderColor="#CFD6E3"
          paddingVertical={7}
          onClick={() =>
            handleViewFeedbackChat({
              account_id: id_account,
            })
          }
        />
      </div>
      {isVisible && <ChatFeature setVisible={setIsVisible} />}
    </div>
  );
};

export { TableItem };
