import React, { FC, useState } from "react";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
// shared
import { Button } from "shared/ui/button";
import { Select } from "shared/ui/select";
import tel from "shared/icons/tel.svg";
// entities
import { AuthInput } from "entities/auth/ui";
import { MusicInput } from "entities/audio/ui/input";
import { addMusicFx } from "entities/audio/model";
import { ChangeAvatarInput } from "entities/account/ui/input";
import { putAccountAvatarFx } from "entities/account/model";
// lib
import { IOrganizationAddNewForm } from "../types";
import { IModuleCreateFeature } from "../types";

const ModuleCreateFeature: FC<IModuleCreateFeature> = ({
  visible,
  onChange,
  title,
  icon,
  options,
  isImgInput = true,
  placeholderForInput = "Номер телефона для входа",
  type = "tel",
  categoryMusic = "music",
  typeModule,
  saveImg,
}) => {
  const [showForm, setShowForm] = useState(true);
  const { register, handleSubmit } = useForm<IOrganizationAddNewForm>();

  const [musicFile, setMusicFile] = useState<File | null>(null);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);

  const handler = () => {
    if (musicFile !== null && typeModule === "addMusic") {
      const formData = new FormData();
      formData.append("music", musicFile);
      formData.append("category", categoryMusic);

      // todo
      addMusicFx(formData as any);
    } else if (avatarFile !== null && typeModule === "changeAvatar") {
      const formData = new FormData();
      formData.append("avatar", avatarFile);

      //todo
      putAccountAvatarFx(formData as any);
    } else if (avatarFile !== null && typeModule === "changeChatAvatar") {
      const formData = new FormData();
      formData.append("avatar", avatarFile);
      //@ts-ignore
      saveImg(avatarFile);
    }
  };

  const hideForm = () => {
    setShowForm(false);
    setTimeout(() => {
      onChange(false);
      setShowForm(true);
    }, 200);
  };

  return visible ? (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      animate={{
        y: showForm ? "-100px" : "400px",
      }}
      className={"ml-16 absolute top-[40vh] left-[35vw]"}
    >
      <div
        className={
          "bg-white w-[418px] border-gray-ec border-[1px] flex flex-col items-center justify-between rounded-2xl p-8 shadow-sm relative"
        }
      >
        <div
          className={
            "w-[52px] h-[52px] border-gray-ec border-[1px] rounded-full absolute z-10 flex items-center justify-center top-[-28px] bg-white"
          }
        >
          <img src={icon} alt="icon" />
        </div>
        <div className={"w-full flex flex-col items-center"}>
          <span className={"text-[22px] font-medium mb-5 mt-3 text-center"}>
            {title}
          </span>
          {typeModule === "addMusic" ? (
            <MusicInput type={type} onFileChange={setMusicFile} />
          ) : typeModule === "changeAvatar" ||
            typeModule === "changeChatAvatar" ? (
            <ChangeAvatarInput type={type} onFileChange={setAvatarFile} />
          ) : (
            <AuthInput
              register={register("phone")}
              type={"tel"}
              img={isImgInput ? tel : void 0}
              placeholder={placeholderForInput}
            />
          )}
          {options && <Select label="Активный" options={options} />}
        </div>
        <div className={"flex w-full mt-4"}>
          <Button
            onClick={() => {
              handler();
              hideForm();
            }}
            paddingVertical={12}
            className={"mr-4"}
          >
            Добавить
          </Button>
          <Button
            paddingVertical={12}
            background={"#FF7253"}
            onClick={hideForm}
          >
            Отмена
          </Button>
        </div>
      </div>
    </motion.div>
  ) : null;
};

export { ModuleCreateFeature };
