import React, { FC, useEffect, useState } from "react";
import phone from "shared/icons/tel.svg";
import mail from "shared/icons/mailActive.svg";
import { IInformationPlate } from "./types";
import { ModuleEditingAccount } from "features/module/module-editing-account-info";
import { useStore } from "effector-react";
import { $accountInfo } from "entities/account/model";

const InformationPlate: FC<IInformationPlate> = ({
  title,
  children,
  type = "",
}) => {
  const [viewFeatures, setViewFeatures] = useState(false);
  const info = useStore($accountInfo)?.account;

  const doNotShow = ["Заголовок", "Краткое описание", "Ссылка"];

  const handleClick = () => {
    if (
      (info?.account.role === "MODERATOR" && title === "Имя") ||
      (info?.account.role === "MODERATOR" && title === "Почта")
    ) {
      setViewFeatures(false);
    } else {
      setViewFeatures(true);
    }
  };

  const isModeratorName = () => {
    if (info?.account.role !== "MODERATOR" && title === "Имя") {
      return setViewFeatures(false);
    } else {
      return true;
    }
  };

  return (
    <div
      className={
        "flex items-center justify-between flex-row w-[354px] cursor-pointer rounded-[10px] bg-white py-[7px] my-[5px]"
      }
      onClick={!doNotShow.includes(title) ? handleClick : void 0}
    >
      <div className="flex flex-col px-[16px] py-[10px] gap-[4px]">
        <div className="text-xs text-gray-400">{title}</div>
        <pre className="text-sm whitespace-pre-wrap break-words overflow-x-auto max-w-full">
          {children}
        </pre>
      </div>
      <div className="flex justify-center w-[60px]">
        {type === "tel" && <img src={phone} alt="info" />}
        {type === "mail" && <img src={mail} alt="info" />}
      </div>
      {viewFeatures && (
        <ModuleEditingAccount
          visible={true}
          title={title}
          onChange={setViewFeatures}
        />
      )}
    </div>
  );
};

export { InformationPlate };
