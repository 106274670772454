export const musicCategory = [
  {
    title: "Музыка",
    path: "/music",
  },
  {
    title: "Белый шум",
    path: "/white_noise",
  },
  {
    title: "Сказки",
    path: "/fairy_tales",
  },
];
