import { IStatisticsModel } from "./types";
import { $http } from "shared/services/http";

class StatisticsAPI {
  getStatistics(params: IStatisticsModel.Params) {
    return $http.post(`/moderator/create/stat/excel`, params, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // Устанавливаем Content-Type в application/json
      },
      responseType: "arraybuffer",
    });
  }

  getStatisticsPay(params: IStatisticsModel.Params) {
    return $http.post(`/moderator/create/stat`, params, {
      headers: {
        Accept: "application/json",
      },
      responseType: "arraybuffer",
    });
  }
}

export const statisticsAPI = new StatisticsAPI();
