import React, { FC } from "react";
import { IAbuseTable } from "./types";
import classNames from "classnames";

const Table: FC<IAbuseTable> = ({ cells, rows }) => (
  <div>
    <div
      className={classNames(
        "flex w-full mb-4 justify-between bg-[#EAF2FC] bg-gray-ea rounded-xl p-6",
      )}
    >
      {cells}
    </div>
    {rows}
  </div>
);

export { Table };
