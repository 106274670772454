import React, { FC, useEffect } from "react";
import { useEvent, useStore } from "effector-react";
// entities
import { AuthInput } from "entities/auth/ui";
import { ArticlesModel } from "entities/articles";
// shared
import { TagsInput } from "shared/ui/input/article-tags";
import { useWindowResolution } from "shared/lib";
import { calculateNumberPercentage } from "shared/lib";
import { SelectCategory } from "shared/ui/select-category";
// helpers
import { IArticleFormFeature } from "./types";
import ArticleEditorFeature from "../article-editor/ui";

const ArticleFormFeature: FC<IArticleFormFeature> = ({
  defaultTitle,
  defaultArticlePayload,
  defaultTags,
}) => {
  // values
  const { width } = useWindowResolution();
  const staticWidth = width - 242 - 32;
  const viewAgeCategory = useStore(ArticlesModel.$viewAgeCategoryArticle);

  // title
  const changeCurrentArticlePayloadTitle = useEvent(
    ArticlesModel.changeCurrentArticlePayloadTitleEv,
  );
  const articleTitle = useStore(ArticlesModel.$currentArticleTitlePayload);
  useEffect(() => {
    if (defaultTitle)
      ArticlesModel.changeCurrentArticlePayloadTitleEv(defaultTitle);
  }, [defaultTitle]);

  // tags
  const changeCurrentArticlePayloadTags = useEvent(
    ArticlesModel.changeTagsArticleEv,
  );
  const articleTags = useStore(ArticlesModel.$tagsArticle);
  useEffect(() => {
    if (defaultTags) ArticlesModel.changeTagsArticleEv(defaultTags);
  }, [defaultTags]);

  return (
    <div
      style={{ width: calculateNumberPercentage(staticWidth, 70) }}
      className="flex flex-col min-h-[80vh] bg-white rounded-[24px] p-6 ml-4 m-[30px]"
    >
      <AuthInput
        value={articleTitle}
        placeholder="Название статьи"
        onChange={changeCurrentArticlePayloadTitle}
      />
      <TagsInput
        value={articleTags}
        placeholder="Теги (введите теги через запятую и без пробелов)"
        onChange={changeCurrentArticlePayloadTags}
      />
      <div className="flex flex-row gap-[8px] items-center">
        <SelectCategory
          type="Категории"
          minWidth={22}
          maxWidth={22}
          options={[
            { label: "Здоровье ребенка", value: "Здоровье ребенка" },
            { label: "Первая помощь", value: "Первая помощь" },
            {
              label: "Грудное и искусственное вскармлевание",
              value: "Грудное и искусственное вскармлевание",
            },
            { label: "Сон", value: "Сон" },
            { label: "Прикорм", value: "Прикорм" },
            { label: "Развитее ребенка", value: "Развитее ребенка" },
            { label: "Мама после родов", value: "Мама после родов" },
            {
              label: "Путешествие с ребенком",
              value: "Путешествие с ребенком",
            },
            { label: "Полезные девайсы", value: "Полезные девайсы" },
          ]}
        />
        <SelectCategory
          type="Возраст"
          minWidth={20}
          maxWidth={20}
          options={[
            { label: "До 6 месяцев", value: "from0to0.5" },
            { label: "6–12 месяцев", value: "from0.5to1" },
            { label: "12–24 месяцев", value: "from1to2" },
            { label: "От 24 месяцев", value: "from2to" },
          ]}
        />
        <div className="flex flex-row gap-1">
          {viewAgeCategory.map((item, index) => (
            <div className="p-2 bg-[#CFD6E3] rounded-lg" key={index}>
              {item}
            </div>
          ))}
        </div>
      </div>
      <ArticleEditorFeature defaultArticlePayload={defaultArticlePayload} />
    </div>
  );
};

export { ArticleFormFeature };
