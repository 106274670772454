import React, { FC } from "react";
import { ITitle } from "./types";
import classNames from "classnames";
const Title: FC<ITitle> = ({ children, className, dark }) => (
  <h1
    className={classNames(
      { "text-white": !dark },
      "font-semibold text-[30px]",
      className,
    )}
  >
    {children}
  </h1>
);

export { Title };
