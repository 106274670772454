import React, { useState } from "react";
import classNames from "classnames";
import { TableItem } from "entities/feedback/ui/table-item";
import { Table } from "shared/ui/table";
import { SortButton } from "shared/ui/sort-button";
import Pagination from "widgets/page-pagination";
import { rowNamesFeetback } from "../lib";
import { finalyValues } from "../lib/helpers";
import { IFeedbackModel } from "entities/feedback";
import { IFeedbackWodget } from "../types";

const FeedbackWidget: React.FC<IFeedbackWodget> = ({ items, page }) => {
  const [sort, setSort] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const filteredFeedback = finalyValues(items?.feedback || [], sort);
  const itemsPerPage = 6;
  const totalPages = Math.ceil(filteredFeedback.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const feedbackOnCurrentPage = filteredFeedback.slice(startIndex, endIndex);

  const goToPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className={"w-full h-[80%] rounded-xl overflow-auto flex flex-col"}>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        goToPage={goToPage}
      />

      <Table
        cells={rowNamesFeetback.map((cell) => (
          <div
            key={cell.id}
            className={classNames("flex")}
            style={{ width: cell.width }}
          >
            <span className={"text-[#111111] opacity-30"}>{cell.title}</span>
            {cell.title === "Статус" && (
              <SortButton sort={sort} setSort={setSort} />
            )}
          </div>
        ))}
        rows={feedbackOnCurrentPage.map(
          (row: IFeedbackModel, index: number) => (
            <TableItem
              index={index + 1}
              key={row.account.id}
              image={row.account.avatar}
              firstName={row.account.first_name}
              secondName={row.account.second_name}
              description={row.text ? row.text : "текст отзыва"}
              status={row.status}
              id={row.id}
              id_account={row.account_id}
              page={page}
            />
          ),
        )}
      />
      <div className="mt-auto">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          goToPage={goToPage}
        />
      </div>
    </div>
  );
};

export { FeedbackWidget };
