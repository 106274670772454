import React, { FC, useState } from "react";
import classNames from "classnames";
// shared
import ArrowBottom from "shared/icons/controls/arrow-bottom.svg";
// lib
import { ISelect } from "./types";
// entities
import { MusicModel } from "entities/audio";
import { updateModeratorState } from "entities/moderator/model";
import { updateDoctorState } from "entities/doctors/model";
import { updateSchoolState } from "entities/school/model";

const Select: FC<ISelect<string>> = ({
  renderLeftIcon,
  className,
  placeholder = "Выберите статус",
  minWidth = 100,
  options,
  maxWidth = 100,
  onSelect,
  id,
  type,
}) => {
  const [active, setActive] = useState<boolean>(false);
  const [inputPlaceholder, setInputPlaceholder] = useState("Выберите статус");

  const handleOptionClick = (selectedLabel: string, view: string) => {
    setInputPlaceholder(view);

    setActive(false);
    if (type === "moderator") {
      updateModeratorState({ state: selectedLabel });
    } else if (type === "doctor") {
      updateDoctorState({ state: selectedLabel });
    } else if (type === "school") {
      updateSchoolState({ state: selectedLabel });
    }

    if (selectedLabel === "DELETE") {
      const shouldDelete = window.confirm("Вы уверены, что хотите удалить?");
      if (shouldDelete) {
        MusicModel.deleteMusicFx(id || "");
      }
    } else {
      if (onSelect) {
        onSelect(selectedLabel);
      }
    }
  };

  return (
    <div
      className={classNames("h-[41px] relative", className)}
      style={{ minWidth: `${minWidth}%`, maxWidth: `${maxWidth}%` }}
    >
      <div
        onClick={() => setActive((prev) => !prev)}
        className={classNames(
          "flex items-center justify-between bg-white rounded-[8px] px-4 border-gray-input border-[1px] h-full cursor-pointer",
        )}
      >
        {renderLeftIcon && (
          <img src={renderLeftIcon} className={"mr-2"} alt="" />
        )}
        <span className={"text-sm mr-2"}>{inputPlaceholder}</span>
        <img src={ArrowBottom} alt="" />
      </div>
      {active && (
        <div
          className={classNames(
            "absolute z-10 bg-[#EAF2FC] w-[100%] top-[110%] rounded-[10px] text-sm p-[10px]",
          )}
        >
          {options.map((option) => (
            <div
              key={option.value}
              style={{
                cursor: "pointer",
                margin: "10px",
                padding: "7px",
                borderRadius: "10px",
                transition: "0.3s",
              }}
              onClick={() => handleOptionClick(option.value, option.label)}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#fff";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#EAF2FC";
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export { Select };
