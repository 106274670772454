import React, { FC } from "react";
import { motion } from "framer-motion";
// shared
import { Button } from "shared/ui/button";
import { Select } from "shared/ui/select";
import tel from "shared/icons/tel.svg";
import email from "shared/icons/mailActive.svg";
import { ModuleInput } from "shared/ui/input-module";
// entities
import { IModuleCreateUsersFeature } from "../module-create/types";
import { ModeratorModel } from "entities/moderator/";
import { SpecialistModel } from "entities/doctors";
import { SchoolModel } from "entities/school";

const ModuleCreateUsersFeature: FC<IModuleCreateUsersFeature> = ({
  visible,
  onChange,
  title,
  icon,
  options,
  role,
}) => {
  const handleUpdateUserData = (field: string, value: string) => {
    switch (role) {
      case "moderator":
        switch (field) {
          case "email":
            ModeratorModel.updateModeratorEmail({ email: value });
            break;
          case "phone":
            ModeratorModel.updateModeratorPhone({ phone: value });
            break;
          case "second_name":
            ModeratorModel.updateModeratorSecondName({ second_name: value });
            break;
          case "first_name":
            ModeratorModel.updateModeratorFirstName({ first_name: value });
            break;
          case "last_name":
            ModeratorModel.updateModeratorLastName({ last_name: value });
            break;
          default:
            break;
        }
        break;
      case "doctor":
        switch (field) {
          case "email":
            SpecialistModel.updateDoctorEmail({ email: value });
            break;
          case "phone":
            SpecialistModel.updateDoctorPhone({ phone: value });
            break;
          case "second_name":
            SpecialistModel.updateDoctorSecondName({ second_name: value });
            break;
          case "first_name":
            SpecialistModel.updateDoctorFirstName({ first_name: value });
            break;
          case "last_name":
            SpecialistModel.updateDoctorLastName({ last_name: value });
            break;
          case "specialization":
            SpecialistModel.updateDoctorSpecialization({
              specialization: value,
            });
            break;
          default:
            break;
        }
        break;
      case "school":
        switch (field) {
          case "email":
            SchoolModel.updateSchoolEmail({ email: value });
            break;
          case "phone":
            SchoolModel.updateSchoolPhone({ phone: value });
            break;
          case "name":
            SchoolModel.updateSchoolFirstName({ first_name: value });
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  const handle = () => {
    let email,
      phone,
      state,
      first_name,
      second_name,
      last_name,
      specialization,
      name;

    switch (role) {
      case "moderator":
        email = ModeratorModel.$moderatorEmailStore.getState().email;
        phone = ModeratorModel.$moderatorPhoneStore.getState().phone;
        state = ModeratorModel.$moderatorStateStore.getState().state;
        first_name =
          ModeratorModel.$moderatorFirstNameStore.getState().first_name;
        second_name =
          ModeratorModel.$moderatorSecondNameStore.getState().second_name;
        last_name = ModeratorModel.$moderatorLastNameStore.getState().last_name;
        ModeratorModel.addModeratorFx({
          email,
          phone,
          state,
          first_name,
          second_name,
          last_name,
        });
        break;
      case "doctor":
        email = SpecialistModel.$doctorEmailStore.getState().email;
        phone = SpecialistModel.$doctorPhoneStore.getState().phone;
        state = SpecialistModel.$doctorStateStore.getState().state;
        first_name =
          SpecialistModel.$doctorFirstNameStore.getState().first_name;
        second_name =
          SpecialistModel.$doctorSecondNameStore.getState().second_name;
        last_name = SpecialistModel.$doctorLastNameStore.getState().last_name;
        specialization =
          SpecialistModel.$doctorSpecializationStore.getState().specialization;
        SpecialistModel.addDoctorFx({
          email,
          phone,
          state,
          first_name,
          second_name,
          last_name,
          specialization,
        });
        break;
      case "school":
        email = SchoolModel.$schoolEmailStore.getState().email;
        phone = SchoolModel.$schoolPhoneStore.getState().phone;
        state = SchoolModel.$schoolStateStore.getState().state;
        first_name = SchoolModel.$schoolFirstNameStore.getState().first_name;
        SchoolModel.addSchoolFx({ email, phone, state, first_name });
        break;
      default:
        break;
    }
  };

  const hideForm = () => onChange(false);

  return visible ? (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      animate={{ y: "-100px" }}
      className="ml-16 absolute top-[25vh] left-[35vw]"
    >
      <div className="bg-white w-[418px] border-gray-ec border-[1px] flex flex-col items-center justify-between rounded-2xl p-8 shadow-sm relative">
        <div className="w-[52px] h-[52px] border-gray-ec border-[1px] rounded-full absolute z-10 flex items-center justify-center top-[-28px] bg-white">
          <img src={icon} alt="icon" />
        </div>
        <div className="w-full flex flex-col items-center">
          <span className="text-[22px] font-medium mb-5 mt-3 text-center">
            {title}
          </span>
          <>
            {role === "doctor" || role === "moderator" ? (
              <ModuleInput
                type="text"
                placeholder="Фамилия"
                onChange={(value) => handleUpdateUserData("second_name", value)}
              />
            ) : null}
            {role === "doctor" || role === "moderator" ? (
              <ModuleInput
                type="text"
                placeholder="Имя"
                onChange={(value) => handleUpdateUserData("first_name", value)}
              />
            ) : null}
            {role === "doctor" || role === "moderator" ? (
              <ModuleInput
                type="text"
                placeholder="Отчество"
                onChange={(value) => handleUpdateUserData("last_name", value)}
              />
            ) : null}
            {role === "doctor" ? (
              <ModuleInput
                type="text"
                placeholder="Специальность"
                onChange={(value) =>
                  handleUpdateUserData("specialization", value)
                }
              />
            ) : null}
            {role === "school" ? (
              <ModuleInput
                type="text"
                placeholder="Название школы"
                onChange={(value) => handleUpdateUserData("name", value)}
              />
            ) : null}
            <ModuleInput
              type="tel"
              placeholder="Номер телефона для входа +7**********"
              img={tel}
              onChange={(value) => handleUpdateUserData("phone", value)}
            />
            <ModuleInput
              type="email"
              placeholder="Почта"
              img={email}
              onChange={(value) => handleUpdateUserData("email", value)}
            />
          </>
          {options && <Select label="Активный" options={options} type={role} />}
        </div>
        <div className="flex w-full mt-4">
          <Button
            onClick={() => {
              handle();
              hideForm();
            }}
            paddingVertical={12}
            className="mr-4"
          >
            Добавить
          </Button>
          <Button paddingVertical={12} background="#FF7253" onClick={hideForm}>
            Отмена
          </Button>
        </div>
      </div>
    </motion.div>
  ) : null;
};

export { ModuleCreateUsersFeature };
