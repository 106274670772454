import React, { useCallback, useEffect, useState } from "react";
import { __BASE_URL__ } from "shared/services/http";
import { useEvent, useStore } from "effector-react";
import { ArticlesModel } from "entities/articles";
import cameraIcon from "shared/icons/camera.svg";
import { EditableItem } from "../editable-item";

export interface IUsePasteEventWatcher {
  contentRef: React.RefObject<HTMLDivElement>;
  contentWidth: number;
  ref: React.RefObject<HTMLDivElement>;
}

const usePasteEventWatcher = ({
  contentRef,
  contentWidth,
  ref,
}: IUsePasteEventWatcher) => {
  // const changeArticleEditor = useEvent(ArticlesModel.changeArticleEditorEvent);
  // const currentArticleEditor = useStore(ArticlesModel.$currentArticleEditor);

  const uploadArticlePhoto = useEvent(ArticlesModel.uploadFileImageFx);
  const uploadNewPhoto = useCallback(
    async (el: File, imgSource: string) => {
      const photo = await uploadArticlePhoto(el);

      if (contentRef.current) {
        const $el = document.querySelector(
          `img[src="${imgSource}"]`,
        ) as HTMLImageElement;

        $el.src = `${__BASE_URL__}/resources/article/photo/${photo.filename}`;
        $el.oncontextmenu = (e) => e.preventDefault();
        $el.onclick = (e) => e.preventDefault();
        $el.onmousedown = (e) => e.preventDefault();
        $el.onmouseup = (e) => e.preventDefault();
        $el.contentEditable = "false";

        const cloned$el = $el.cloneNode(true);
        const wrapperDiv = document.createElement("div");
        wrapperDiv.className =
          "w-full flex items-center justify-center cursor-default select-none";
        wrapperDiv.onclick = (e) => e.preventDefault();
        wrapperDiv.onmousedown = (e) => e.preventDefault();
        wrapperDiv.onmouseup = (e) => e.preventDefault();
        wrapperDiv.appendChild(cloned$el);
        wrapperDiv.contentEditable = "false";
        wrapperDiv.style.marginBottom = "32px";

        $el.replaceWith(wrapperDiv);
        $el.remove();

        setItems((prev) => [
          ...prev,
          {
            type: "text",
            jsx: (
              <EditableItem
                pushNewItem={() => {
                  setItems((prev) => [
                    ...prev,
                    {
                      type: "text",
                      jsx: (
                        <EditableItem
                          key={prev[0].jsx.key ? prev[0].jsx.key + 1 : void -1}
                          contentWidth={contentWidth}
                          contentRef={contentRef}
                        />
                      ),
                    },
                  ]);
                }}
                key={prev[0].jsx.key ? prev[0].jsx.key + 1 : void -1}
                contentWidth={contentWidth}
                contentRef={contentRef}
              />
            ),
          },
        ]);
        contentRef.current.blur();
      }
    },
    [contentRef, contentWidth, uploadArticlePhoto],
  );

  const [items, setItems] = useState([
    {
      type: "text",
      jsx: (
        <EditableItem
          pushNewItem={() => {
            setItems((prev) => [
              ...prev,
              {
                type: "text",
                jsx: (
                  <EditableItem
                    key={prev[0].jsx.key ? prev[0].jsx.key + 1 : void -1}
                    contentWidth={contentWidth}
                    contentRef={contentRef}
                  />
                ),
              },
            ]);
          }}
          key={0}
          contentWidth={contentWidth}
          contentRef={contentRef}
        />
      ),
    },
  ]);

  const foo = useCallback(
    (e: ClipboardEvent) => {
      if (!e.clipboardData || !contentRef.current) return;

      const parsed = new DOMParser().parseFromString(
        e.clipboardData.getData("text/html"),
        "text/html",
      );

      const parsedImages = parsed.querySelectorAll("img");

      Array.from(e.clipboardData.files).forEach((file, index) =>
        uploadNewPhoto(file, parsedImages[index].src),
      );

      if (!parsedImages.length) {
        e.preventDefault();
        const $ref = contentRef.current;
        const selection = document.getSelection();

        if (!selection || !$ref) return;

        /**
         * @description TODO Потом порефакторинг на ноды
         * @warn
         * @deprecated
         */
        document.execCommand(
          "insertHTML",
          false,
          e.clipboardData.getData("text/plain"),
        );

        selection.removeAllRanges();
      }
    },
    [contentRef, uploadNewPhoto],
  );

  useEffect(() => {
    if (!ref.current) return;
    const $ref = ref.current;

    $ref.addEventListener("paste", foo);

    return () => $ref.removeEventListener("paste", foo);
  }, [foo, ref]);

  return { items };
};

export { usePasteEventWatcher };
