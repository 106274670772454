import React, { FC, useState } from "react";
import { IMusicInput } from "./types";
import classNames from "classnames";
import "./style.css";

const MusicInput: FC<IMusicInput> = ({
  type,
  maxLength,
  disabled,
  onFileChange,
}) => {
  const [selectedFileName, setSelectedFileName] = useState("");

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setSelectedFileName(file?.name as string);
    if (file) {
      onFileChange(file);
    }
  };

  return (
    <div
      className={classNames(
        "flex items-center border-gray-input border-[1px] w-full h-[50px] min-h-[50px] rounded-[10px] mb-3.5",
        { "opacity-50": disabled },
      )}
    >
      <form method="post" encType="multipart/form-data">
        <label className="input-file">
          <span className="input-file-text overflow-hidden text-ellipsis whitespace-nowrap">
            {selectedFileName}
          </span>
          <input
            name="file"
            onChange={handleFileChange}
            maxLength={maxLength}
            type={type}
            accept={"audio/*"}
          />
          <span className="input-file-btn">Выбрать файл</span>
        </label>
      </form>
    </div>
  );
};

export { MusicInput };
