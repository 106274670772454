import React, { FC } from "react";

import { CreateArticleFeature } from "../create-article/ui";
import { ArticleFormFeature } from "features/articles/article-form";

const ArticleWidget: FC<{
  $ref: React.MutableRefObject<HTMLDivElement | null>;
}> = ({ $ref }) => {
  return (
    <div className="flex flex-row flex-wrap w-full">
      <CreateArticleFeature $ref={$ref} />
      <ArticleFormFeature />
    </div>
  );
};

export { ArticleWidget };
