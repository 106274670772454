import React, { FC } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { IRouteButton } from "./types";

const ButtonRoute: FC<IRouteButton> = ({
  children,
  path,
  className,
  disabled,
}) => {
  return (
    <Link to={path}>
      <div
        style={{
          padding: "9px",
        }}
        className={classNames(
          "rounded-lg w-full flex justify-center items-center bg-[#0767FD]",
          className,
          {
            "cursor-pointer": !disabled,
            "opacity-75": disabled,
          },
        )}
      >
        <span style={{ color: "white", fontSize: "14px" }}>{children}</span>
      </div>
    </Link>
  );
};

export { ButtonRoute };
