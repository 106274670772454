// В файле ChatGroups.tsx
import React, { FC } from "react";

import arrow from "shared/icons/controls/arrow-bottom.svg";
import { IChatGroup } from "./type";
import { animated } from "react-spring";
import { useDropdownAnimation } from "./helpers";

const ChatGroups: FC<IChatGroup> = ({ title, groups }) => {
  const { isOpen, springProps, rotateArrow, toggleDropdown } =
    useDropdownAnimation();

  return (
    <div className="flex flex-col py-[8px] w-[292px]">
      <div
        className="flex justify-between font-[500] text-sm cursor-pointer"
        onClick={toggleDropdown}
      >
        <div
          className="px-[16px]"
          style={{ color: isOpen ? "black" : "#666E80" }}
        >
          {title}
        </div>
        <animated.img
          src={arrow}
          alt="arrow"
          style={{ transform: rotateArrow.transform }}
        />
      </div>
      <animated.div className="overflow-hidden" style={{ ...springProps }}>
        {groups
          ? groups.map((item, index) => <div key={index}>{item}</div>)
          : "Нет чатов"}
      </animated.div>
    </div>
  );
};

export { ChatGroups };
