import { IArticleModel } from "entities/audio";
import { MGetAllArticles } from "entities/articles";
import { IAccountModel } from "entities/doctors";
/**
 *
 * @param articles
 * @param sort
 */
const finallyValues = (
  articles: MGetAllArticles.Response | null,
  sort: boolean,
  accountId: string,
  isAdmin: boolean,
) => {
  const statusOrder = sort
    ? ["published", "draft", "deleted"]
    : ["deleted", "draft", "published"];

  let accountArticles;

  if (!isAdmin) {
    accountArticles =
      articles?.articles.filter((item) => item.account?.id === accountId) || [];
  } else {
    accountArticles = articles?.articles || [];
  }

  const compareByStatus = (
    a: MGetAllArticles.Response["articles"][0],
    b: MGetAllArticles.Response["articles"][0],
  ) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);

  return articles ? accountArticles.sort(compareByStatus) : [];
};

/**
 *
 * @param statusArticle
 */
const articlesValues = /* #__PURE__ */ (statusArticle: string | undefined) => {
  const statusColor =
    statusArticle === "published"
      ? "rgba(173, 240, 141, 0.4)"
      : statusArticle === "draft"
        ? "rgba(192, 192, 192, 0.4)"
        : statusArticle === "deleted"
          ? "rgba(242, 100, 100, 0.4)"
          : "rgba(255, 223, 110, 0.4)";

  const statusTitle =
    statusArticle === "published"
      ? "Опубликована"
      : statusArticle === "draft"
        ? "Черновик"
        : statusArticle === "deleted"
          ? "Удалена"
          : "Статус не установлен";

  return [statusColor, statusTitle];
};

export { finallyValues, articlesValues };
