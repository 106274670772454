import React, { FC } from "react";
import { InformationPlate } from "shared/ui/information-plate";

interface InformationItem {
  title: string;
  children: string;
  type?: string;
}

interface IPlatesWidget {
  items: InformationItem[];
}

const PlatesWidget: FC<IPlatesWidget> = ({ items }) => {
  return (
    <div>
      {items.map((item, index) => (
        <div key={index} className="flex flex-col items-start gap-2">
          <InformationPlate
            title={item.title}
            children={item.children}
            type={item.type}
          />
        </div>
      ))}
    </div>
  );
};

export { PlatesWidget };
