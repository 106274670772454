import React, { FC, useEffect, useState } from "react";
import { PhotoPlate } from "shared/ui/photo-plate";
import { Button } from "shared/ui/button";
import { ModuleCreateFeature } from "features/module/module-create";
import icon from "shared/icons/camera.svg";

interface IPhotoPlatesItem {
  title: string;
  img: string | undefined;
  typeModule?: string;
}

const PhotoPlatesWidget: FC<IPhotoPlatesItem> = ({
  title,
  img,
  typeModule = "changeAvatar",
}) => {
  const [visible, setVisible] = useState(false);

  const handleAddButtonClick = () => {
    setVisible(!visible);
  };

  const nullImg = "https://api.mama-api.ru/api/v1/resources/avatar/null";

  return (
    <div>
      <PhotoPlate
        key={1}
        title={title}
        img={img !== nullImg ? img : icon}
        button={
          <Button
            children={"Сменить"}
            background="rgba(7, 103, 253, 0.1)"
            color="#0767FD"
            onClick={handleAddButtonClick}
          />
        }
      />
      {visible && (
        <div className="overlay">
          <ModuleCreateFeature
            icon={icon}
            title="Выбрать файл"
            onChange={handleAddButtonClick}
            visible={visible}
            isImgInput={false}
            placeholderForInput="Добавить файл"
            type="file"
            categoryMusic="music"
            typeModule={typeModule}
          />
        </div>
      )}
    </div>
  );
};

export { PhotoPlatesWidget };
