const rowNames = [
  {
    id: 1,
    title: "№",
    width: "5%",
  },
  {
    id: 2,
    title: "Теги",
    width: "15%",
  },
  {
    id: 3,
    title: "Категории",
    width: "15%",
  },
  {
    id: 4,
    title: "Заголовок",
    width: "40%",
  },
  {
    id: 5,
    title: "Автор",
    width: "10%",
  },
  {
    id: 6,
    title: "Статус",
    width: "15%",
  },
];

export { rowNames };
