import { MGetAllDoctors } from "./types";
import { AxiosResponse } from "axios";
import { $http } from "shared/services/http";
import { ICreateModerator } from "entities/moderator";

class DoctorsAPI {
  fetchAllDoctors(
    params: Partial<MGetAllDoctors.Params> | void,
  ): Promise<AxiosResponse<MGetAllDoctors.Response>> {
    return $http.get("doctor", { params });
  }
  addDoctor(params: ICreateModerator.Params) {
    return $http.post(`/moderator/create/doctor`, params);
  }
}

export const doctorsAPI = new DoctorsAPI();
