const rowNamesFeetback = [
  {
    id: 1,
    title: "№",
    width: "6%",
  },
  {
    id: 2,
    title: "Пользователь",
    width: "13.5%",
  },
  {
    id: 3,
    title: "Текст отзыва",
    width: "33.5%",
  },
  {
    id: 4,
    title: "Статус",
    width: "23.5%",
  },
  {
    id: 5,
    title: "Действие",
    width: "17.5%",
  },
];

export { rowNamesFeetback };
