import {
  createStore,
  createEffect,
  restore,
  guard,
  createEvent,
} from "effector";
import { MCreateArticle, MGetMusic, MUpdateMusic } from "./types";
import { musicAPI } from "./api";

// get
const fetchMusicByType = async (params: MGetMusic.Params) => {
  const response = await musicAPI.fetchMusic(params.music);
  return response.data.music;
};

const getMusicFx = createEffect({
  handler: async () => fetchMusicByType({ music: "music" }),
});

const getFairyTalesFx = createEffect({
  handler: async () => fetchMusicByType({ music: "story" }),
});

const getWhiteNoiseFx = createEffect({
  handler: async () => fetchMusicByType({ music: "whiteNoise" }),
});

const createMusicStore = (fx: typeof getMusicFx) =>
  createStore<MGetMusic.Response | null>(null).on(fx.doneData, (_, data) => ({
    music: data,
  }));

// patch
const patchMusicStatusFx = createEffect({
  handler: async (params: MUpdateMusic.Params) => {
    const response = await musicAPI.updateMusicStatus(
      params.music_id,
      params.status,
    );
    return response.data;
  },
});

// delete

const deleteMusicFx = createEffect({
  handler: async (musicId: string) => {
    const response = await musicAPI.deleteMusic(musicId);
    return response.data;
  },
});

// add

const addMusicFx = createEffect({
  handler: async (data: MCreateArticle.Body) => {
    const response = await musicAPI.addMusic(data);
    return response.data;
  },
});

// tracking upload and delete

const createGuard = (fx: any) => {
  guard({
    source: fx.doneData,
    filter: (data) => data !== null,
  }).watch(() => {
    getMusicFx();
    getFairyTalesFx();
    getWhiteNoiseFx();
  });
};

createGuard(patchMusicStatusFx);
createGuard(deleteMusicFx);
createGuard(addMusicFx);

// store
const $musicFx = createMusicStore(getMusicFx);
const $fairyTalesFx = createMusicStore(getFairyTalesFx);
const $whiteNoiseFx = createMusicStore(getWhiteNoiseFx);
const $musicStatusFx = restore(patchMusicStatusFx, null);

const changeCurrentSongEvent = createEvent<string>();
const changeCurrentSongPlayingStatusEvent = createEvent<boolean>();

const $isSongPlay = createStore<boolean>(false)
  .on(changeCurrentSongEvent, () => true)
  .on(changeCurrentSongPlayingStatusEvent, (_, status) => status);
const $currentSongPlay = createStore<string | null>(null).on(
  changeCurrentSongEvent,
  (_, data) => data,
);

export {
  /**
   * @effects
   */
  getMusicFx,
  getFairyTalesFx,
  getWhiteNoiseFx,
  patchMusicStatusFx,
  deleteMusicFx,
  addMusicFx,
  /**
   * @store
   */
  $musicFx,
  $fairyTalesFx,
  $whiteNoiseFx,
  $musicStatusFx,
  $currentSongPlay,
  $isSongPlay,
  /**
   * @events
   */
  changeCurrentSongEvent,
  changeCurrentSongPlayingStatusEvent,
};
