import AvatarIcon from "shared/icons/avatar.svg";
import { FeedbackStatusEnum } from "../ui/table-item/types";

const row = "flex justify-between items-center";
const rowText = "text-[14px] font-normal";

const getUserStatus = /* #__PURE__ */ (status: FeedbackStatusEnum | string) => {
  switch (status) {
    case FeedbackStatusEnum.NEW:
      return "Новое";
    case FeedbackStatusEnum.COMPLETED:
      return "Обработано";
    default:
      return "Не установлен";
  }
};

const getStatusBackgroundColor = /* #__PURE__ */ (
  status: FeedbackStatusEnum
) => {
  switch (status) {
    case FeedbackStatusEnum.NEW:
      return "rgba(255, 223, 110, 0.4)";
    case FeedbackStatusEnum.PROGRESS:
      return "rgba(255, 223, 110, 0.4)";
    case FeedbackStatusEnum.COMPLETED:
      return "rgba(173, 240, 141, 0.4)";
    default:
      return "rgba(173, 240, 141, 0.4)";
  }
};

const finalyValues = /* #__PURE__ */ (
  image: string | undefined,
  firstName: string,
  secondName: string,
  page: string | undefined,
  status: FeedbackStatusEnum,
  lastName: string | undefined
) => {
  const avatarUser = image
    ? // это потом в константу вынести, и аргументом {image принимать}
      image
    : AvatarIcon;
  const nameUser = firstName
    ? `${firstName} ${secondName ? secondName : ""} ${lastName ? lastName : ""}`
    : "Имя не указано";

  const statusOptions = [
    { value: FeedbackStatusEnum.NEW, label: "Не обработанные" },
    { value: FeedbackStatusEnum.COMPLETED, label: "Обработано" },
    /*     { value: FeedbackStatusEnum.PROGRESS, label: "В обработке" }, */
  ];

  const statusBgc = getStatusBackgroundColor(status);

  return { avatarUser, nameUser, statusOptions, statusBgc };
};

export { getUserStatus, getStatusBackgroundColor, finalyValues, row, rowText };
