import { Promo } from "entities/promocode";

// user filtering
const filterUsers = /* #__PURE__ */ (users: Promo[], sortUp: boolean) => {
  const statusOrder = sortUp
    ? ["Не активирован", "Активен", "Использован"]
    : ["Активен", "Использован", "Не активирован"];

  const compareByStatus = (a: Promo, b: Promo) =>
    statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);

  return [...users].sort(compareByStatus);
};

// return final values
const finalyValues = /* #__PURE__ */ (users: Promo[], sort: boolean) => {
  const list: Promo[] = Array.isArray(users) ? users : [];
  const filteredUsers = filterUsers(list, sort);

  return filteredUsers;
};

export { finalyValues };
