export enum UserRoleEnum {
  ADMIN = "ADMIN",
  MODERATOR = "MODERATOR",
  DOCTOR = "DOCTOR",
  USER = "USER",
  ONLINE_SCHOOL = "ONLINE_SCHOOL",
}

export enum UserStatusEnum {
  ACTIVE = "ACTIVE",
  SUBSCRIBED = "SUBSCRIBED",
  PROMO_CODE = "PROMO_CODE",
  TRIAL = "TRIAL",
  NO_SUBSCRIBED = "NO_SUBSCRIBED",
  DELETED = "DELETED",
  BLOCKED = "BLOCKED",
  UNREGISTERED = "UNREGISTERED",
  INACTIVE = "INACTIVE",
}

export enum MusicStatusEnum {
  ACTIVE = "ACTIVE",
  DELETE = "DELETE",
  UNREGISTERED = "UNREGISTERED",
  SUSPENDED = "SUSPENDED",
}

export module MAuthorizeUserByCredentials {
  export interface Params {
    phone: string;
    fcm_token: string;
    code: string;
  }
  export interface Response {
    refresh_token: string;
    role: UserRoleEnum;
    state: UserStatusEnum;
  }
}

export module MGetAccessTokenByRefreshToken {
  export type Params = string | undefined;
  export interface Response extends MAuthorizeUserByCredentials.Response {
    access_token?: string;
  }
}

export interface IGetCode {
  phone: string;
}
