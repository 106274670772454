import { AxiosResponse } from "axios";
import { $http } from "shared/services/http";
// types
import {
  MIndividualChatsList,
  MGroupChatsList,
  MChatById,
  MIndividualChatsMessage,
  IndividualChat,
  GroupChatList,
  MChatChange,
} from "./types";

class ChatsAPI {
  fetchAllIndividualChatsList(): Promise<
    AxiosResponse<MIndividualChatsList.Response>
  > {
    return $http.get(`/chat`);
  }

  fetchAllGroupChatsList(): Promise<AxiosResponse<MGroupChatsList.Response>> {
    return $http.get(`/chat/group`);
  }

  getChatById(
    param: MChatById.Params,
  ): Promise<AxiosResponse<IndividualChat | GroupChatList | null>> {
    return $http.post(`/chat/create`, param);
  }

  getIndividualChatMessage(
    params: MIndividualChatsMessage.Params,
  ): Promise<AxiosResponse<MIndividualChatsMessage.Response>> {
    return $http.get(`/chat/message/${params.type_chat}`, { params });
  }

  patchChangeChatAvatar(params: MChatChange.ParamsForAvatar) {
    return $http.patch("/moderator/create/chat/avatar", params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  patchChangeChatName(params: MChatChange.ParamsForName) {
    return $http.patch("/moderator/create/chat", params);
  }
}
export const chatsAPI = new ChatsAPI();
