import React, { useState, useEffect } from "react";
import { BarLoader } from "react-spinners";
// shared
import { Title } from "shared/ui/title";
import { BaseInput } from "shared/ui/input";
import { Button } from "shared/ui/button";
import SearchIcon from "shared/icons/controls/search.svg";
import icon from "shared/icons/musicIcon.svg";
// widgets
import { ModuleCreateFeature } from "features/module/module-create";
import { MusicWidget } from "widgets/music/ui";
import { Pagination } from "widgets/pagination";
// effector
import { useStore } from "effector-react";
import { MGetMusic, MusicModel } from "entities/audio";
import { musicHandler } from "pages/music/lib/helpers";

const WhiteNoisePage = () => {
  const [visible, setVisible] = useState(false);
  const [music, setMusic] = useState<MGetMusic.Response["music"]>([]);
  const [searchText, setSearchText] = useState("");

  const musicData = useStore(MusicModel.$whiteNoiseFx);
  const isLoading = useStore(MusicModel.getWhiteNoiseFx.pending);

  const handleAddButtonClick = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    const updateMusic = async () => {
      if (!isLoading && musicData !== null) {
        const newMusic = musicHandler(musicData);
        setMusic(newMusic);
      }
    };

    updateMusic();
  }, [isLoading, musicData]);

  useEffect(() => {
    MusicModel.getWhiteNoiseFx();
  }, []);

  return (
    <>
      <div style={{ height: "calc(100vh - 70px)" }} className={"bg-[#F5F8FE]"}>
        <div className={"w-full px-4 py-4 h-[95%]"}>
          <Title className={"mb-6"} dark>
            Белый шум
          </Title>
          <div
            className={
              "flex justify-between items-center mb-5 bg-[#EAF2FC] p-3 rounded-xl"
            }
          >
            <div className={"w-[38%]"}>
              <BaseInput
                placeholder={"Найти"}
                renderLeftIcon={SearchIcon}
                onInputChange={setSearchText}
              />
            </div>
            <div className={"flex"}>
              <div className={"w-[201px]"}>
                <Button onClick={handleAddButtonClick}>Добавить</Button>
              </div>
            </div>
          </div>

          {!isLoading ? (
            <MusicWidget music={music} searchText={searchText} />
          ) : (
            <div className="flex items-center justify-center w-full h-[74%]">
              <BarLoader color="#36D7B7" width={500} loading={isLoading} />
            </div>
          )}
        </div>
      </div>

      {visible && (
        <div className="overlay">
          <ModuleCreateFeature
            icon={icon}
            title="Выбрать файл"
            onChange={handleAddButtonClick}
            visible={visible}
            isImgInput={false}
            placeholderForInput="Добавить файл"
            type="file"
            categoryMusic="whiteNoise"
            typeModule="addMusic"
          />
        </div>
      )}
    </>
  );
};

export { WhiteNoisePage };
