import React, { FC, useEffect } from "react";
import { IPhotoPlate } from "./types";
import { __BASE_URL__ } from "shared/services/http";
import userPhoto from "shared/icons/camera.svg";

const PhotoPlate: FC<IPhotoPlate> = ({ title, img, button }) => {
  return (
    <div
      className={
        "flex items-center justify-between flex-col w-[296px] cursor-pointer rounded-[10px] bg-white p-[20px] gap-2.5"
      }
    >
      <div className="text-xs text-gray-400">{title}</div>
      <div className="w-[256px] h-[256px] rounded-[10px] flex items-center justify-center">
        <img src={img ?? userPhoto} className="w-full" alt="avatar" />
      </div>
      {button}
    </div>
  );
};

export { PhotoPlate };
