import { createEffect } from "effector/effector.umd";
import { usersAPI } from "./api";
import {
  MGetAllUsers,
  MUpdateUsers,
  MUpdateAccountState,
  MGetModeratorProfileMe,
} from "./types";
import { createStore, restore, guard } from "effector";
import { getAllModeratorsFx } from "entities/moderator/model";
import { getAllSchoolsWithNameFx } from "entities/school/model";
import { getAllDoctorsFx } from "entities/doctors/model";

// get
const getAllUsersFx = createEffect({
  /**
   * @deprecated
   * Использовать fetchAllAccounts
   * @param params
   */
  handler: async (params: Partial<MGetAllUsers.Params> | void) => {
    const data = await usersAPI.fetchAll(params);

    return data.data;
  },
});

// patch
const patchUsersStatusFx = createEffect({
  handler: async (params: MUpdateUsers.Params) => {
    const response = await usersAPI.updateUsersStatus(params.id, params.status);
    return response.data;
  },
});

const patchAccounStateFx = createEffect({
  handler: async (params: MUpdateAccountState.Params) => {
    const response = await usersAPI.updateAccounState(params.id, params.state);
    return response.data;
  },
});

// tracking upload
const createGuard = (fx: any) => {
  guard({
    source: fx.doneData,
    filter: (data) => data !== null,
  }).watch(() => {
    getAllUsersFx();
    getAllModeratorsFx();
    getAllSchoolsWithNameFx();
    getAllDoctorsFx();
  });
};

createGuard(patchUsersStatusFx);
createGuard(patchAccounStateFx);

// store
const $usersLoaded = createStore(false).on(getAllUsersFx.doneData, () => true);
const $usersStatusFx = restore(patchUsersStatusFx, null);
const $accounSStore = restore(patchAccounStateFx, null);
const $users = createStore<MGetAllUsers.Response | null>(null).on(
  getAllUsersFx.doneData,
  (_, data) => data,
);

const getMyProfileFx = createEffect({
  handler: async () => {
    const response = await usersAPI.fetchMe();
    return response.data;
  },
});

const $moderatorProfileMeStore =
  createStore<null | MGetModeratorProfileMe.Response>(null).on(
    getMyProfileFx.doneData,
    (_, data) => data,
  );

// reset value
const setModeratorProfileMe = () => {
  $moderatorProfileMeStore.reset();
};

export {
  /**
   * @effects
   */
  getAllUsersFx,
  patchUsersStatusFx,
  patchAccounStateFx,
  getMyProfileFx,
  setModeratorProfileMe,
  /**
   * @store
   */
  $users,
  $usersLoaded,
  $usersStatusFx,
  $accounSStore,
  $moderatorProfileMeStore,
};
