import { ISchoolModel } from "entities/school";
import { MGetAllUsers } from "entities/users";
import { MGetAllDoctors } from "entities/doctors";
import { IOnlineSchoolModel } from "entities/school";

// moderator filtering
const filterModerators = /* #__PURE__ */ (
  users: IOnlineSchoolModel[],
  sortUp: boolean,
) => {
  const statusOrder = sortUp ? ["ACTIVE", "INACTIVE"] : ["INACTIVE", "ACTIVE"];

  const compareByStatus = (a: IOnlineSchoolModel, b: IOnlineSchoolModel) =>
    statusOrder.indexOf(a.account.state) - statusOrder.indexOf(b.account.state);

  return [...users].sort(compareByStatus);
};

// return final values
const finalyValues = /* #__PURE__ */ (
  users: IOnlineSchoolModel | null,
  sort: boolean,
) => {
  const list: Array<IOnlineSchoolModel> = users ? Object.values(users)[0] : [];
  const filteredUsers = filterModerators(list, sort);

  return filteredUsers;
};

export { finalyValues };
