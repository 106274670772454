import React, { useState } from "react";
import { FilterButtonsProps } from "./types";

const FilterButtons: React.FC<FilterButtonsProps> = ({ options, onChange }) => {
  const [activeButton, setActiveButton] = useState<string | null>(null);

  const handleFilterChange = (value: string | null) => {
    onChange(value);
    setActiveButton(value);
  };

  return (
    <div className="flex rounded-[10px] border-[2px] border-[#EAF2FC] bg-[#EAF2FC]">
      {options.map((option) => (
        <button
          key={option.value}
          className={`rounded-[8px] p-[8px] ${
            activeButton === option.value ? "bg-white" : ""
          }`}
          onClick={() => handleFilterChange(option.value)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

export default FilterButtons;
