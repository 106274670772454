const rowNames = [
  {
    id: 1,
    title: "№",
    width: "6%",
  },
  {
    id: 2,
    title: "Название",
    width: "23.5%",
  },
  {
    id: 3,
    title: "Статус",
    width: "23.5%",
  },
  {
    id: 5,
    title: "Действие",
    width: "11%",
  },
];

const rowNamesMusic = [
  {
    id: 1,
    title: "№",
    width: "6%",
  },
  {
    id: 2,
    title: "Название",
    width: "23.5%",
  },
  {
    id: 3,
    title: "Действие",
    width: "11%",
  },
];

const rowNamesFeetback = [
  {
    id: 1,
    title: "№",
    width: "6%",
  },
  {
    id: 2,
    title: "Пользователь",
    width: "23.5%",
  },
  {
    id: 3,
    title: "Текст отзыва",
    width: "23.5%",
  },
  {
    id: 4,
    title: "Статус",
    width: "23.5%",
  },
  {
    id: 5,
    title: "Действие",
    width: "11%",
  },
  {
    id: 6,
    title: "",
    width: "5%",
  },
];

const rows = [
  {
    key: 1,
    image:
      "https://sun9-43.userapi.com/s/v1/ig2/0VkK0Q3bcuyFLJLPJuL3p81sSR22ggLCGwbMYtJEjeRBvlFuiJAgdbKZ7HyPKqpy7dJI7q9Gk1tXvav30CawGoUO.jpg?size=100x0&quality=96&crop=13,0,200,200&ava=1",
  },
  {
    key: 2,
    image:
      "https://sun9-43.userapi.com/s/v1/ig2/0VkK0Q3bcuyFLJLPJuL3p81sSR22ggLCGwbMYtJEjeRBvlFuiJAgdbKZ7HyPKqpy7dJI7q9Gk1tXvav30CawGoUO.jpg?size=100x0&quality=96&crop=13,0,200,200&ava=1",
  },
  {
    key: 3,
    image:
      "https://sun9-43.userapi.com/s/v1/ig2/0VkK0Q3bcuyFLJLPJuL3p81sSR22ggLCGwbMYtJEjeRBvlFuiJAgdbKZ7HyPKqpy7dJI7q9Gk1tXvav30CawGoUO.jpg?size=100x0&quality=96&crop=13,0,200,200&ava=1",
  },
];

export { rowNames, rows, rowNamesFeetback, rowNamesMusic };
