export enum FeedbackStatusEnum {
  NEW = "NEW",
  PROGRESS = "PROGRESS",
  COMPLETED = "COMPLETED",
}

export enum FeedbackTypeEnum {
  WITH_ANSWER = "WITH_ANSWER",
  WITHOUT_ANSWER = "WITHOUT_ANSWER",
}

export interface IFeedbackItem {
  image?: string;
  index: number;
  firstName: string;
  secondName: string;
  lastName?: string;
  description?: string;
  status: FeedbackStatusEnum;
  onClick?: () => void;
  id: string;
  id_account: string;
  page?: string;
}
