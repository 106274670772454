import axios from "axios";
import { AppStorage } from "./app-storage";
/**
 * @description TODO нельзя импортировать из внешнего мира
 */
import {
  $authStore,
  requestAccessTokenByRefreshTokenFx,
  userLogoutEvent,
} from "entities/auth";

const __BASE_URL__ = "https://api.mama-api.ru/api/v1";

const storage = new AppStorage();

const $httpAuth = axios.create({
  baseURL: __BASE_URL__,
});

const $http = axios.create({
  baseURL: __BASE_URL__,
});

$http.interceptors.request.use((config) => {
  const token = storage.getItem("access_token");

  config.headers.set("Authorization", `Bearer ${token}`);
  return config;
});

$http.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        const refreshToken = $authStore.getState()?.refresh_token;

        try {
          await requestAccessTokenByRefreshTokenFx(refreshToken);
        } catch (error) {
          userLogoutEvent();
        }
      }
    }

    return Promise.reject(error);
  }
);

export { $httpAuth, $http, __BASE_URL__ };
