import React, { FC } from "react";

interface IChatGrop {
  title: string;
  subTitle: string;
  img: string;
  countNewMessage?: number;
  sender: string;
  onClick: () => void;
}

const ChatGrop: FC<IChatGrop> = ({
  title,
  subTitle,
  img,
  countNewMessage,
  sender,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className="flex flex-row justify-center cursor-pointer items-center border-l-2 border-transparent px-[16px] py-[8px] gap-[8px] relative"
    >
      <div className="w-[40px] h-10 rounded-full overflow-hidden flex items-center">
        <img src={img} alt="img" className="w-full" />
      </div>
      <div className="flex flex-col">
        <div className="font-[500] w-[190px]">{title}</div>
        <div className="flex gap-[2px] items-center">
          <span className="text-xs">{sender} </span>
          <span
            className="text-xs text-[#666E80] w-[160px]"
            style={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 1,
              lineHeight: "14px",
              height: 14,
            }}
          >
            {subTitle}
          </span>
        </div>
      </div>
      {countNewMessage !== 0 ? (
        <div className="flex items-center justify-center w-[24px] h-[24px] text-[12px] rounded-full bg-[#0767FD] text-white absolute right-0">
          {countNewMessage! > 99 ? "99+" : countNewMessage}
        </div>
      ) : null}
    </div>
  );
};

export { ChatGrop };
