import React, { FC, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { BarLoader } from "react-spinners";
import { Title } from "shared/ui/title";
import { BaseInput } from "shared/ui/input";
import { Button } from "shared/ui/button";
import SearchIcon from "shared/icons/controls/search.svg";
import { useStore } from "effector-react";
import { PromocodeWidget } from "widgets/promocode";
import {
  getAllPromocodeFx,
  $promocode,
  addPromocodeFx,
} from "entities/promocode/model";

const PromocodePage: FC = () => {
  const allPromocode = useStore($promocode);
  const [showForm, setShowForm] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [excelFile, setExcelFile] = useState<File | null>(null);
  const [selectedFileName, setSelectedFileName] = useState("");

  useEffect(() => {
    getAllPromocodeFx();
  }, []);

  const isLoading = useStore(getAllPromocodeFx.pending);

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const file = event.target.files?.[0];

    setSelectedFileName(file?.name as string);
    if (files && files.length > 0) {
      setExcelFile(files[0]);
    }
  };

  const handleUpload = () => {
    if (excelFile) {
      const formData = new FormData();
      formData.append("promocode", excelFile);
      addPromocodeFx(formData);
      setExcelFile(null);
      setModalOpen(false);
    }
  };

  return (
    <div style={{ height: "calc(100vh - 70px)" }} className={"bg-[#F5F8FE]"}>
      <div className={"w-full px-4 py-4 h-[100%]"}>
        <Title className={"mb-6"} dark>
          Промокоды
        </Title>
        <div
          className={
            "flex justify-between items-center mb-5 bg-[#EAF2FC] p-3 rounded-xl"
          }
        >
          <div className={"w-[38%]"}>
            <BaseInput
              placeholder={"Найти пользователя"}
              renderLeftIcon={SearchIcon}
              onInputChange={handleSearch}
            />
          </div>
          <div className={"flex"}>
            <div className={"w-[201px]"}>
              <Button onClick={() => setModalOpen(true)}>
                Загрузить из Excel-файла
              </Button>
            </div>
          </div>
        </div>

        {!isLoading ? (
          <PromocodeWidget
            haveDescription={false}
            statusFilter={null}
            users={allPromocode}
            page="promo"
            searchValue={searchValue}
          />
        ) : (
          <div className="flex items-center justify-center w-full h-[74%]">
            <BarLoader color="#36D7B7" width={500} loading={isLoading} />
          </div>
        )}
      </div>
      {modalOpen && (
        <div>
          <motion.div
            onClick={(e) => e.stopPropagation()}
            animate={{
              y: showForm ? "-100px" : "400px",
            }}
            className={"ml-16 absolute top-[40vh] left-[35vw]"}
          >
            <div
              className={
                "bg-white w-[100%] border-gray-ec border-[1px] flex flex-col items-center justify-between rounded-2xl p-8 shadow-sm relative"
              }
            >
              <div className={"w-full flex flex-col items-center mb-4"}>
                <span
                  className={"text-[22px] font-medium mb-5 mt-3 text-center"}
                >
                  Загрузить из Excel-файла
                </span>

                <label className="input-file">
                  <span className="input-file-text overflow-hidden text-ellipsis whitespace-nowrap">
                    {selectedFileName}
                  </span>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    accept=".xlsx, .xls"
                    className="hidden"
                    id="file-upload-modal"
                  />
                  <span className="input-file-btn">Выбрать файл</span>
                </label>
              </div>

              <div className={"flex w-full"}>
                <Button onClick={handleUpload} className="mr-4">
                  Загрузить
                </Button>
                <Button
                  onClick={() => setModalOpen(false)}
                  background="#FF7523"
                >
                  Закрыть
                </Button>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export { PromocodePage };
