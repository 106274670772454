import { IUserModel } from "entities/users";
import { MGetAllUsers } from "entities/users";

// review check
const getFeedback = /* #__PURE__ */ (
  haveDescription: boolean | undefined,
  rowAccountInfo: string | undefined,
): string | undefined =>
  haveDescription && rowAccountInfo ? rowAccountInfo : "Нет отзыва";

// user filtering
const filterUsers = /* #__PURE__ */ (users: IUserModel[], sortUp: boolean) => {
  const statusOrder = sortUp
    ? [
        "ACTIVE",
        "SUBSCRIBED",
        "PROMO_CODE",
        "TRIAL",
        "NO_SUBSCRIBED",
        "DELETED",
        "BLOCKED",
        "UNREGISTERED",
        "SUSPENDED",
      ]
    : [
        "SUSPENDED",
        "UNREGISTERED",
        "BLOCKED",
        "DELETED",
        "NO_SUBSCRIBED",
        "TRIAL",
        "PROMO_CODE",
        "SUBSCRIBED",
        "ACTIVE",
      ];

  const compareByStatus = (a: IUserModel, b: IUserModel) =>
    statusOrder.indexOf(a.account.status) -
    statusOrder.indexOf(b.account.status);

  return [...users].sort(compareByStatus);
};

// return final values
const finalyValues = /* #__PURE__ */ (users: IUserModel[], sort: boolean) => {
  const list: IUserModel[] = Array.isArray(users) ? users : [];
  const filteredUsers = filterUsers(list, sort);

  return filteredUsers;
};

export { getFeedback, finalyValues };
