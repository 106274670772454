import React, { FC } from "react";
import iconCheck from "shared/icons/chat/iconCheck.svg";

interface IChatMessage {
  title?: string;
  label?: string;
  message: string;
  time: string;
  isYoursMessage: boolean;
  delivered: boolean;
  icon?: string;
}

const ChatMessage: FC<IChatMessage> = ({
  title,
  label,
  message,
  time,
  icon,
  isYoursMessage,
  delivered,
}) => {
  return (
    <div
      className="flex flex-row items-end w-[386px] gap-[4px] pb-[4px]"
      style={{
        paddingRight: isYoursMessage ? "0px" : "16px",
        justifyContent: isYoursMessage ? "flex-end" : "flex-start",
      }}
    >
      {icon && (
        <img className="w-[40px] h-[40px] rounded-full" src={icon} alt="icon" />
      )}
      <div className="max-w-[322px] p-[12px] flex flex-col gap-[8px] bg-blue-100 rounded-[8px]">
        {title && (
          <div className="flex flex-row gap-[4px]">
            <div className="text-sm text-[#0767FD] font-[500]">{title}</div>
            <div className="text-[10px] text-white font-[500] px-[3px] py-[1px] bg-[#A4AFC2] rounded-[8px]">
              {label}
            </div>
          </div>
        )}
        <div className="text-sm">{message}</div>
        <div className="flex justify-end items-center gap-[4px]">
          <div className="flex  text-xs text-[#A4AFC2]">{time}</div>
          {delivered && (
            <div>
              <img src={iconCheck} alt="check" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { ChatMessage };
