export const rowNames = [
  {
    id: 1,
    title: "№",
    width: "6%",
  },
  {
    id: 2,
    title: "Название",
    width: "23.5%",
  },
  {
    id: 3,
    title: "Специальность",
    width: "23.5%",
  },
  {
    id: 4,
    title: "Статус",
    width: "23.5%",
  },
  {
    id: 5,
    title: "Действие",
    width: "11%",
  },
];
