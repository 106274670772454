import React, { FC } from "react";
import classNames from "classnames";
import { Controller } from "react-hook-form";

interface IBaseInput {
  type?: string;
  placeholder: string;
  renderRightIcon?: string;
  renderLeftIcon?: string;
  className?: string;
  register?: any;
  control?: any;
  name?: string;
  onClickRightIcon?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  onInputChange: (value: string) => void;
}

const BaseInput: FC<IBaseInput> = ({
  type,
  placeholder,
  renderRightIcon,
  renderLeftIcon,
  className,
  register,
  control,
  name,
  onClickRightIcon,
  onChange,
  value,
  onInputChange,
}) => {
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const sendMessage = () => {
    if (onClickRightIcon) {
      onClickRightIcon();
    }
  };

  return (
    <div>
      <div
        className={classNames(
          "w-full h-[49px] border border-1 border-gray-input rounded-lg px-6 flex justify-between items-center bg-white",
          className,
        )}
      >
        {renderLeftIcon ? (
          <img src={renderLeftIcon} className={"mr-4"} alt="" />
        ) : null}
        {name && control ? (
          <Controller
            render={({ field }) => (
              <input
                type={type}
                className={
                  "w-full h-full bg-transparent outline-none text-gray-36 mr-4"
                }
                placeholder={placeholder}
                onChange={(e) => {
                  field.onChange(e);
                  onChange && onChange(e);
                  onInputChange(e.target.value);
                }}
                onBlur={field.onBlur}
                value={value}
                ref={field.ref}
                onKeyPress={handleKeyPress}
              />
            )}
            control={control}
            name={name}
          />
        ) : (
          <input
            type={type}
            className={
              "w-full h-full bg-transparent outline-none text-gray-36 mr-4"
            }
            placeholder={placeholder}
            {...register}
            onChange={(e) => {
              register?.onChange(e);
              onChange && onChange(e);
              onInputChange(e.target.value);
            }}
            value={value}
            onKeyPress={handleKeyPress}
          />
        )}
        {renderRightIcon ? (
          <img
            className={"cursor-pointer"}
            onClick={sendMessage}
            src={renderRightIcon}
            alt=""
          />
        ) : null}
      </div>
    </div>
  );
};

export { BaseInput };
