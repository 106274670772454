import { MGetAllDoctors, doctorsAPI } from "../doctors";
import { createStore, createEffect, createEvent, guard } from "effector";
import { UserRoleEnum } from "../auth";
import { ICreateModerator, IUpdateModerator } from "entities/moderator";

// get
const getAllDoctorsFx = createEffect({
  handler: async (
    params: Omit<Partial<MGetAllDoctors.Params>, "role"> | void,
  ) => {
    const data = await doctorsAPI.fetchAllDoctors({
      ...params,
      role: UserRoleEnum.DOCTOR,
    });

    return data.data;
  },
});

// add

const addDoctorFx = createEffect({
  handler: async (params: ICreateModerator.Params) => {
    const response = await doctorsAPI.addDoctor({ ...params });
    return response.data;
  },
});

// update

const updateDoctorEmail = createEvent<IUpdateModerator.Email>();
const updateDoctorPhone = createEvent<IUpdateModerator.Phone>();
const updateDoctorState = createEvent<IUpdateModerator.State>();
const updateDoctorFirstName = createEvent<IUpdateModerator.FirstName>();
const updateDoctorSecondName = createEvent<IUpdateModerator.SecondName>();
const updateDoctorLastName = createEvent<IUpdateModerator.LastName>();
const updateDoctorSpecialization =
  createEvent<IUpdateModerator.Specialization>();

// tracking upload

const createGuard = (fx: any) => {
  guard({
    source: fx.doneData,
    filter: (data) => data !== null,
  }).watch(() => {
    getAllDoctorsFx();
  });
};

createGuard(addDoctorFx);

// store

const $doctorsUsers = createStore<MGetAllDoctors.Response | null>(null).on(
  getAllDoctorsFx.doneData,
  (_, data) => data,
);

const $doctorEmailStore = createStore({
  email: "",
}).on(updateDoctorEmail, (email, newData) => ({
  ...email,
  ...newData,
}));

const $doctorPhoneStore = createStore({
  phone: "",
}).on(updateDoctorPhone, (phone, newData) => ({
  ...phone,
  ...newData,
}));

const $doctorStateStore = createStore({
  state: "",
}).on(updateDoctorState, (state, newData) => ({
  ...state,
  state: newData.state,
}));

const $doctorFirstNameStore = createStore({
  first_name: "",
}).on(updateDoctorFirstName, (first_name, newData) => ({
  ...first_name,
  first_name: newData.first_name,
}));

const $doctorSecondNameStore = createStore({
  second_name: "",
}).on(updateDoctorSecondName, (second_name, newData) => ({
  ...second_name,
  second_name: newData.second_name,
}));

const $doctorLastNameStore = createStore({
  last_name: "",
}).on(updateDoctorLastName, (last_name, newData) => ({
  ...last_name,
  last_name: newData.last_name,
}));

const $doctorSpecializationStore = createStore({
  specialization: "",
}).on(updateDoctorSpecialization, (specialization, newData) => ({
  ...specialization,
  specialization: newData.specialization,
}));

export {
  /**
   * @effects
   */
  getAllDoctorsFx,
  addDoctorFx,
  /**
   * @store
   */
  $doctorsUsers,
  $doctorEmailStore,
  $doctorPhoneStore,
  $doctorStateStore,
  $doctorFirstNameStore,
  $doctorLastNameStore,
  $doctorSecondNameStore,
  $doctorSpecializationStore,
  /**
   * @event
   */
  updateDoctorEmail,
  updateDoctorPhone,
  updateDoctorState,
  updateDoctorFirstName,
  updateDoctorLastName,
  updateDoctorSecondName,
  updateDoctorSpecialization,
};
