import { MusicStatusEnum } from "entities/auth";
import iconPlay from "shared/icons/buttonPlay.svg";
import iconMusic from "shared/icons/musicIcon.svg";

const getMusicStatus = /* #__PURE__ */ (status: string) => {
  switch (status) {
    case MusicStatusEnum.ACTIVE:
      return "Активен";
    case MusicStatusEnum.SUSPENDED:
      return "Приостановлен";
    default:
      return "Не установлен";
  }
};

const finalyValues = /* #__PURE__ */ (
  duration: number,
  title: string | undefined,
  status: string,
) => {
  const icon = duration > 0 ? iconPlay : iconMusic;
  const musicTitle = title ? title : "Неизвестный исполнитель";
  const musicStatus = getMusicStatus(status);
  const row = "flex justify-between items-center";
  const rowText = "text-[14px] font-normal";

  return { icon, musicTitle, musicStatus, row, rowText };
};

export { getMusicStatus, finalyValues };
