import React, { FC } from "react";
import arrow from "shared/icons/controls/arrow-bottom.svg";
import { ISortButton } from "./types";

export const SortButton: FC<ISortButton> = ({ sort, setSort }) => {
  return (
    <div
      className="flex items-center ml-2 cursor-pointer border py-[2px] px-[4px] rounded-[4px] border-[#CFD6E3] select-none"
      onClick={() => setSort(!sort)}
    >
      {sort && (
        <div>
          <img src={arrow} alt="arrow" />
        </div>
      )}
      {!sort && (
        <div className="rotate-180">
          <img src={arrow} alt="arrow" />
        </div>
      )}
    </div>
  );
};
