import React, { useRef, useState, ChangeEvent } from "react";

import { ArticleWidget } from "widgets/articles/article";
import { ArticleListWidget } from "../../features/articles/list";
import { BaseInput } from "../../shared/ui/input";
import SearchIcon from "../../shared/icons/controls/search.svg";
import { Button } from "../../shared/ui/button";

const ArticlesPage = () => {
  const $ref = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [addNewArticle, setAddNewArticle] = useState(false);

  const moveToCreateFeature = () => {
    /*    $ref?.current?.scrollIntoView({ behavior: "smooth" }); */
    setAddNewArticle(true);
  };

  const handleInputChange = (value: string) => {
    setSearchValue(value);
  };

  return (
    <div className="bg-[#F5F8FE] w-[calc(100vw - 242px)] max-w-[calc(100vw - 242px)]">
      {!addNewArticle && (
        <div
          className={
            "flex justify-between items-center mb-5 bg-[#EAF2FC] p-3 rounded-xl m-6"
          }
        >
          <div className={"w-[38%]"}>
            <BaseInput
              onInputChange={handleInputChange}
              value={searchValue}
              placeholder={"Найти статью"}
              renderLeftIcon={SearchIcon}
            />
          </div>
          <div className={"flex"}>
            <div className={"w-[201px]"}>
              <Button onClick={moveToCreateFeature}>Написать статью</Button>
            </div>
          </div>
        </div>
      )}
      {addNewArticle && (
        <div
          className={
            "flex justify-between items-center mb-5 bg-[#EAF2FC] p-3 rounded-xl m-6"
          }
        >
          <div className={"w-[38%]"}></div>
          <div className={"flex"}>
            <div className={"w-[201px]"}>
              <Button
                onClick={() => {
                  setAddNewArticle(false);
                }}
              >
                К списку статей
              </Button>
            </div>
          </div>
        </div>
      )}
      {!addNewArticle && <ArticleListWidget searchValue={searchValue} />}
      {addNewArticle && <ArticleWidget $ref={$ref} />}
    </div>
  );
};

export { ArticlesPage };
