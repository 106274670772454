import { IUserModel } from "entities/users";
import { IFeedbackModel, MFeedbackList } from "entities/feedback";

// user filtering
const filterUsers = /* #__PURE__ */ (
  users: IFeedbackModel[],
  sortUp: boolean,
) => {
  const statusOrder = sortUp
    ? ["NEW", "PROGRESS", "COMPLETED"]
    : ["COMPLETED", "PROGRESS", "NEW"];

  const compareByStatus = (a: IFeedbackModel, b: IFeedbackModel) =>
    statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);

  return [...users].sort(compareByStatus);
};

const finalyValues = /* #__PURE__ */ (
  users: IFeedbackModel[] | null,
  sort: boolean,
) => {
  const list: Array<IFeedbackModel> = users ? Object.values(users) : [];
  return filterUsers(list, sort);
};

export { finalyValues };
