import React, { useEffect, useState } from "react";
import { Title } from "shared/ui/title";
import { AuthInput } from "entities/auth/ui";
import { FilledButton } from "shared/ui/button";
import { useForm } from "react-hook-form";
import { IAuthFormData } from "./types";
import { useStore } from "effector-react";
import {
  authorizeUserByCredentialsFx,
  requestAccessTokenByRefreshTokenFx,
  $authStore,
  $getCodeFx,
} from "entities/auth/model";
import { AppStorage } from "shared/services/app-storage";

const AuthForm = () => {
  const userData = useStore($authStore);
  const [visibleCodeInput, setVisibleCodeInput] = useState(false);
  const [visibleNumberError, setVisibleNumberError] = useState(false);
  const [visibleCodeError, setVisibleCodeError] = useState(false);
  const storage = new AppStorage();

  const { register, handleSubmit, watch } = useForm<IAuthFormData>();
  const isPhoneFulfilled =
    watch("phone")?.length === 13 || watch("phone")?.length === 12;

  const sendCode = handleSubmit(async (data) => {
    const accessToken = storage.getItem("access_token") as string;

    try {
      await authorizeUserByCredentialsFx({
        code: data.code,
        fcm_token: accessToken,
        phone: data.phone,
      });
    } catch (error) {
      setVisibleCodeError(true);
    }
  });

  const enterNumber = handleSubmit(async (data) => {
    try {
      await $getCodeFx({ phone: data.phone });
      setVisibleNumberError(false);
      setVisibleCodeInput(true);
    } catch (error) {
      setVisibleNumberError(true);
    }
  });

  useEffect(() => {
    const refreshToken = userData?.refresh_token;
    void requestAccessTokenByRefreshTokenFx(`Bearer ${refreshToken}`);
  }, [userData]);

  return (
    <div
      className={
        "w-[418px] h-[327px] border-gray-ec border-[1px] flex flex-col items-center justify-between rounded-2xl p-8 shadow-sm"
      }
    >
      <Title className={"text-2xl font-medium mb-5"} dark>
        Авторизация
      </Title>
      <form className={"w-full"}>
        <AuthInput
          placeholder={"Номер телефона"}
          maxLength={13}
          register={register("phone")}
        />
        {visibleNumberError && (
          <div className="text-sm text-[#FF7253] text-right">
            Номер не зарегистрирован
          </div>
        )}

        {visibleCodeInput && (
          <AuthInput
            placeholder={"Отправленный код"}
            maxLength={4}
            register={register("code")}
          />
        )}
        {visibleCodeError && (
          <div className="text-sm text-[#FF7253] text-right">Неверный код</div>
        )}
      </form>
      {!visibleCodeInput && (
        <FilledButton
          disabled={!isPhoneFulfilled}
          onClick={enterNumber}
          className={"mt-[19px]"}
        >
          Получить код
        </FilledButton>
      )}
      {visibleCodeInput && (
        <FilledButton
          disabled={!isPhoneFulfilled}
          onClick={sendCode}
          className={"mt-[19px]"}
        >
          Войти
        </FilledButton>
      )}
    </div>
  );
};

export { AuthForm };
