import React, { FC } from "react";
import { IAuthInput } from "entities/auth/ui/auth-input/types";
import classNames from "classnames";
import { Controller } from "react-hook-form";

const TagsInput: FC<IAuthInput> = ({
  type,
  placeholder,
  maxLength,
  register,
  disabled,
  value,
  onChange: onChangeDirectly,
}) => {
  return (
    <div
      className={classNames(
        "flex items-center border-gray-input border-[1px] w-full h-[50px] min-h-[50px] rounded-[10px] px-4 mb-3.5",
        { "opacity-50": disabled },
      )}
    >
      <>
        <input
          onChange={
            onChangeDirectly
              ? (event) => onChangeDirectly(event.target.value)
              : void function () {}
          }
          value={value}
          disabled={disabled}
          maxLength={maxLength}
          type={type}
          className={
            "w-full h-full bg-transparent outline-none text-sm text-gray-36 mr-4"
          }
          placeholder={placeholder}
          {...register}
        />
      </>
    </div>
  );
};

export { TagsInput };
