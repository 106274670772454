import React, { FC, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { saveAs } from "file-saver";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BarLoader } from "react-spinners";
import { Title } from "shared/ui/title";
import { BaseInput } from "shared/ui/input";
import { Button } from "shared/ui/button";
import SearchIcon from "shared/icons/controls/search.svg";
import { useStore } from "effector-react";
import { UsersWidget } from "widgets/users/users-items";
import { getAllUsersFx, $users, $usersLoaded } from "entities/users/model";
import { IStatisticsModel } from "entities/statistics/types";
import { getStatisticsFX } from "entities/statistics/model";

const UsersPage: FC = () => {
  const allUsers = useStore($users);
  const [showForm, setShowForm] = useState(true);
  const usersLoaded = useStore($usersLoaded);
  const [searchValue, setSearchValue] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [fromTime, setFromTime] = useState<Date | null>(null);
  const [toTime, setToTime] = useState<Date | null>(null);

  useEffect(() => {
    getAllUsersFx();
  }, []);

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleDownloadExcel = () => {
    setModalOpen(true);
  };

  const handleExportToExcel = () => {
    const params: IStatisticsModel.Params = {
      from_time: fromTime?.toISOString() || "",
      to_time: toTime?.toISOString() || "",
    };

    getStatisticsFX(params)
      .then((response) => {
        const blob = new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        saveAs(blob, "statistics.xlsx");
        setModalOpen(false);
      })
      .catch((error) => {
        console.error("Ошибка при загрузке файла Excel:", error);
      });
  };

  return (
    <div style={{ height: "calc(100vh - 70px)" }} className={"bg-[#F5F8FE]"}>
      <div className={"w-full px-4 py-4 h-[100%]"}>
        <Title className={"mb-6"} dark>
          Пользователи
        </Title>
        <div
          className={
            "flex justify-between items-center mb-5 bg-[#EAF2FC] p-3 rounded-xl"
          }
        >
          <div className={"w-[38%]"}>
            <BaseInput
              placeholder={"Найти пользователя"}
              renderLeftIcon={SearchIcon}
              onInputChange={handleSearch}
            />
          </div>
          <div className={"flex"}>
            <div className={"w-[201px]"}>
              <Button onClick={handleDownloadExcel}>
                Выгрузить в Excel-файл
              </Button>
            </div>
          </div>
        </div>

        {usersLoaded ? (
          <UsersWidget
            haveDescription={false}
            statusFilter={null}
            users={allUsers}
            page="fiveStatus"
            searchValue={searchValue}
          />
        ) : (
          <div className="flex items-center justify-center w-full h-[74%]">
            <BarLoader color="#36D7B7" width={500} loading={!usersLoaded} />
          </div>
        )}
      </div>
      {modalOpen && (
        <div>
          <motion.div
            onClick={(e) => e.stopPropagation()}
            animate={{
              y: showForm ? "-100px" : "400px",
            }}
            className={"ml-16 absolute top-[40vh] left-[35vw]"}
          >
            <div
              className={
                "bg-white w-[418px] border-gray-ec border-[1px] flex flex-col items-center justify-between rounded-2xl p-8 shadow-sm relative"
              }
            >
              <div className={"w-full flex flex-col items-center"}>
                <span
                  className={"text-[22px] font-medium mb-5 mt-3 text-center"}
                >
                  Выгрузить в Excel-файл
                </span>
              </div>
              <div className={"flex flex-col"}>
                <DatePicker
                  selected={fromTime}
                  onChange={(date: Date | null) => setFromTime(date)}
                  placeholderText="с"
                  className="p-2 rounded-lg w-[350px] border-blue-500 border-2 mb-4"
                />
                <DatePicker
                  selected={toTime}
                  onChange={(date: Date | null) => setToTime(date)}
                  placeholderText="по"
                  className="p-2 rounded-lg w-[350px] border-blue-500 border-2 mb-4"
                />
              </div>
              <div className={"flex w-full"}>
                <Button onClick={handleExportToExcel} className="mr-4">
                  Выгрузить в Excel
                </Button>
                <Button
                  onClick={() => setModalOpen(false)}
                  background="#FF7523"
                >
                  Закрыть
                </Button>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export { UsersPage };
