import { MGetAllArticles, MUploadFileImage } from "./types";

import { AxiosResponse } from "axios";
import { $http } from "shared/services/http";
import {
  MCreateArticle,
  MGetOneArticle,
  MRequestUUID,
  IChangeArticleStatus,
} from "../audio";
/* import { sendFormDataRequest } from "shared/services/http"; */

class ArticlesAPI {
  /**
   * @description Загружает картинку для статьи возвращает id изображения
   * @returns {MUploadFileImage.Body}
   * @param body
   */
  uploadFileImage(
    body: MUploadFileImage.Params,
  ): Promise<AxiosResponse<MUploadFileImage.Body>> {
    return $http.post("article/photo", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  /**
   * @description Возвращает список моделей статей, параметры необязательны
   * @param params
   */
  getAllArticles(
    params: MGetAllArticles.Params | void,
  ): Promise<AxiosResponse<MGetAllArticles.Response>> {
    return $http.get("article/all", {
      params,
    });
  }

  /**
   * @description Создает новую статью
   * @param body
   */

  createArticle(
    body: MCreateArticle.Body,
  ): Promise<AxiosResponse<MCreateArticle.Response>> {
    const formData = new FormData();

    formData.append("id", body.id);
    formData.append("account_id", body.account_id);
    formData.append("title", body.title);
    for (const tag of body.tags) formData.append("tags", tag.trim());
    for (const age_category of body.age_category)
      formData.append("age_category", age_category.trim());
    formData.append("body", JSON.stringify(body.body));
    formData.append("type", JSON.stringify(body.type || ""));
    formData.append("category", body.category || "");
    formData.append("status", body.status || "published");
    if (body.photo) formData.append("photo", body.photo);

    return $http.post("article", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  changeArticleStatus(
    body: IChangeArticleStatus.Params,
  ): Promise<AxiosResponse<IChangeArticleStatus.Response>> {
    return $http.post("article/status", body);
  }

  /**
   * @description Здесь отправляем запрос на изменение текущей статьи
   * @param body
   */
  changeArticle(
    body: MCreateArticle.Body,
  ): Promise<AxiosResponse<MCreateArticle.Response>> {
    const formData = new FormData();

    formData.append("id", body.id);
    formData.append("account_id", body.account_id);
    formData.append("title", body.title);

    for (const tag of body.tags)
      formData.append("tags", JSON.stringify(tag).trim());

    formData.append("body", JSON.stringify(body.body));
    formData.append("type", JSON.stringify(body.type || ""));
    formData.append("status", body.status || "published");

    return $http.put("article", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  /**
   * @description Получает уникальный id статьи
   */
  requestUUID(): Promise<AxiosResponse<MRequestUUID.Response>> {
    return $http.get("article");
  }

  getOne(
    params: MGetOneArticle.Params,
  ): Promise<AxiosResponse<MGetOneArticle.Response>> {
    return $http.get(`article/${params.id}`);
  }
}

export const articlesAPI = new ArticlesAPI();
