import React, { useState } from "react";
import classNames from "classnames";
import { Table, TableItem } from "entities/users";
import { rowNames } from "../lib";
import Pagination from "widgets/page-pagination";
import { IDoctorWidget } from "../types";
import { finalyValues } from "../lib/helpers";
import { SortButton } from "shared/ui/sort-button";

const DoctorWidget: React.FC<IDoctorWidget> = ({
  haveChat,
  page,
  users,
  searchText,
}) => {
  // sorted and filtered
  const [sort, setSort] = useState(true);
  const filteredUsers = finalyValues(users, sort);
  const searchedUsers = searchText
    ? filteredUsers.filter((user) => {
        const fullName =
          `${user.account.first_name} ${user.account.second_name} ${user.account.last_name}`.toLowerCase();
        const searchTextLower = searchText.toLowerCase();
        return (
          fullName.includes(searchTextLower) ||
          user.account.state.toLowerCase().includes(searchTextLower) ||
          (user.profession &&
            user.profession.toLowerCase().includes(searchTextLower))
        );
      })
    : filteredUsers;

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(searchedUsers.length / 7);
  const startIndex = (currentPage - 1) * 7;
  const endIndex = startIndex + 7;
  const usersOnCurrentPage = searchedUsers.slice(startIndex, endIndex);

  const goToPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className={"w-full h-[80%] rounded-xl overflow-auto flex flex-col"}>
      <div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          goToPage={goToPage}
        />
        <Table
          cells={rowNames.map((cell) => (
            <div
              key={cell.id}
              className={classNames("flex")}
              style={{ width: cell.width }}
            >
              <span className={"text-[#111111] opacity-30"}>{cell.title}</span>
              {cell.title === "Статус" ? (
                <SortButton sort={sort} setSort={setSort} />
              ) : null}
            </div>
          ))}
          rows={usersOnCurrentPage.map((row, index: number) => (
            <TableItem
              index={startIndex + index + 1}
              key={row.id}
              image={row.account.avatar}
              firstName={row.account.first_name}
              secondName={row.account.second_name}
              lastName={row.account.last_name}
              status={row.account.state}
              haveChat={haveChat}
              id={row.account.id}
              page={page}
              speciality={row.profession}
              viewSpeciality={true}
            />
          ))}
        />
      </div>
      <div className="mt-auto">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          goToPage={goToPage}
        />
      </div>
    </div>
  );
};

export { DoctorWidget };
