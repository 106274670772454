/**
 * @nosideeffects
 * @param imageFile
 * @description Принимает на вход payload файла и возвращает временную ссылку на время загрузки
 */
const useImageTempUri = /* #__PURE__ */ (
  imageFile: File | null,
): string | void => {
  if (!imageFile) return void String;
  return URL.createObjectURL(imageFile);
};

export { useImageTempUri };
