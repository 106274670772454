import React, { FC } from "react";
import { IButton } from "./types";
import classNames from "classnames";

const Button: FC<IButton> = ({
  children,
  childrenIsImg,
  className,
  background = "#0767FD",
  color = "#fff",
  onClick,
  paddingVertical = 9,
  disabled,
  haveBorder,
  borderColor,
  fontSize = "16px",
  width = "100%",
}) => {
  return (
    <div
      onClick={!disabled ? onClick : undefined}
      style={{
        background,
        paddingTop: paddingVertical,
        paddingBottom: paddingVertical,
        border: haveBorder ? `1px solid ${borderColor}` : "none",
        width: width,
      }}
      className={classNames(
        "rounded-lg w-full flex justify-center items-center",
        className,
        {
          "cursor-pointer": !disabled,
          "opacity-75": disabled,
        },
      )}
    >
      {childrenIsImg === true ? (
        <img src={children as string} alt="img" />
      ) : (
        <span style={{ color, fontSize: fontSize }}>{children}</span>
      )}
    </div>
  );
};

export { Button };
