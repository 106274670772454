import { createStore, createEffect, createEvent, guard } from "effector";
import { chatsAPI } from "./api";
import {
  MIndividualChatsList,
  MGroupChatsList,
  MChatById,
  MIndividualChatsMessage,
  IndividualChat,
  Message,
  GroupChatList,
  MChatChange,
} from "./types";
import { accountAPI } from "entities/account";

/**
 * @info
 * @GET_individual_chat_list
 *
 */

const getIndividualChatsListFx = createEffect({
  handler: async () => {
    const response = await chatsAPI.fetchAllIndividualChatsList();
    return response.data;
  },
});

// store individual chat list

const $individualChatsListStore =
  createStore<MIndividualChatsList.Response | null>(null).on(
    getIndividualChatsListFx.doneData,
    (_, data) => data,
  );

/**
 * @info
 * @GET_group_chat_list
 *
 */
//===============================================================================
const getGroupChatsListFx = createEffect({
  handler: async () => {
    const response = await chatsAPI.fetchAllGroupChatsList();
    return response.data;
  },
});

// store group chat list
const $groupChatsListStore = createStore<MGroupChatsList.Response | null>(
  null,
).on(getGroupChatsListFx.doneData, (_, data) => data);

const setCurrentGroupChatEv = createEvent<GroupChatList>();
const $currentGroupChat = createStore<GroupChatList | null>(null).on(
  setCurrentGroupChatEv,
  (_, data) => data,
);

const getGroupChatMessageFx = createEffect({
  handler: async (param: MIndividualChatsMessage.Params) => {
    const response = await chatsAPI.getIndividualChatMessage(param);
    return response.data;
  },
});

/**
 * @info
 * @POST_chat_by_id
 *
 */

const postChatByIdFx = createEffect({
  handler: async (param: MChatById.Params) => {
    const response = await chatsAPI.getChatById(param);
    return response.data;
  },
});

// store chat by id
const $chatByIdStore = createStore<IndividualChat | GroupChatList | null>(
  null,
).on(postChatByIdFx.doneData, (_, data) => data);

/**
 * @info
 * @GET_individual_chat_message
 *
 * @param
 * chat_id, limit, offset, type_chat
 */
const getIndividualChatMessageFx = createEffect({
  handler: async (param: MIndividualChatsMessage.Params) => {
    const response = await chatsAPI.getIndividualChatMessage(param);
    return response.data;
  },
});

// Добавляем событие для добавления нового сообщения
const addMessage = createEvent<Message>();

const $individualChatMessageStore =
  createStore<MIndividualChatsMessage.Response | null>(null)
    .on(getIndividualChatMessageFx.doneData, (_, data) => data)
    .on(addMessage, (state, newMessage) => {
      if (state) {
        const updatedMessages = [newMessage, ...state.messages]; // добавляем новое сообщение в начало массива
        return { ...state, messages: updatedMessages };
      } else {
        return null;
      }
    });

const setCurrentSoloChatEv = createEvent<
  IndividualChat | GroupChatList | null
>();
const $currentSoloChat = createStore<IndividualChat | GroupChatList | null>(
  null,
).on(setCurrentSoloChatEv, (_, data) => data);

// store group chat message
const $groupChatMessageStore =
  createStore<MIndividualChatsMessage.Response | null>(null)
    .on(getGroupChatMessageFx.doneData, (_, data) => data)
    .on(addMessage, (state, newMessage) =>
      state ? { ...state, messages: [...state.messages, newMessage] } : null,
    );

/**
 * @info
 * @PATCH_change_chat
 *
 */

const patchChangeChatAvatarFx = createEffect({
  handler: async (data: MChatChange.ParamsForAvatar) => {
    const response = await chatsAPI.patchChangeChatAvatar(data);
    return response.data;
  },
});

/**
 * @info
 * @PATCH_change_chat
 *
 */

const patchChangeChatNameFx = createEffect({
  handler: async (data: MChatChange.ParamsForName) => {
    const response = await chatsAPI.patchChangeChatName(data);
    return response.data;
  },
});

// tracking upload
const createGuard = (fx: any) => {
  guard({
    source: fx.doneData,
    filter: (data) => data !== null,
  }).watch(() => {
    getGroupChatsListFx();
  });
};

createGuard(patchChangeChatAvatarFx);
createGuard(patchChangeChatNameFx);

export {
  /**
   * @effects
   */
  getIndividualChatsListFx,
  getGroupChatsListFx,
  postChatByIdFx,
  getIndividualChatMessageFx,
  getGroupChatMessageFx,
  patchChangeChatAvatarFx,
  patchChangeChatNameFx,
  /**
   * @events
   */
  setCurrentSoloChatEv,
  addMessage,
  setCurrentGroupChatEv,
  /**
   * @store
   */
  $individualChatsListStore,
  $groupChatsListStore,
  $chatByIdStore,
  $individualChatMessageStore,
  $currentSoloChat,
  $currentGroupChat,
  $groupChatMessageStore,
};
