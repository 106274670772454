import React, { FC, useState } from "react";
import { saveAs } from "file-saver";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Title } from "shared/ui/title";
import { Button } from "shared/ui/button";
import { getStatisticsFX, getStatisticsPayFX } from "entities/statistics/model";
import { IStatisticsModel } from "entities/statistics/types";

const StatisticsPage: FC = () => {
  const [fromTimeExcel, setFromTimeExcel] = useState<Date | null>(null);
  const [toTimeExcel, setToTimeExcel] = useState<Date | null>(null);
  const [fromTimePayment, setFromTimePayment] = useState<Date | null>(null);
  const [toTimePayment, setToTimePayment] = useState<Date | null>(null);

  const handleDownload = (type: string) => {
    let fromTime, toTime;

    if (type === "excel") {
      fromTime = fromTimeExcel;
      toTime = toTimeExcel;
    } else {
      fromTime = fromTimePayment;
      toTime = toTimePayment;
    }

    const params: IStatisticsModel.Params = {
      from_time: fromTime?.toISOString() || "",
      to_time: toTime?.toISOString() || "",
    };

    const downloadFunction =
      type === "excel" ? getStatisticsFX : getStatisticsPayFX;
    const filename = type === "excel" ? "Users.xlsx" : "Payment.xlsx";

    downloadFunction(params)
      .then((response) => {
        const blob = new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        saveAs(blob, filename);
      })
      .catch((error) => {
        console.error("Ошибка при загрузке файла Excel:", error);
      });
  };

  return (
    <div style={{ minHeight: "calc(100vh - 70px)" }} className={"bg-[#F5F8FE]"}>
      <div className={"w-full px-4 py-4 h-[95%]"}>
        <Title className={"mb-6"} dark>
          Статистика
        </Title>
        <div className="flex flex-row justify-start items-center gap-5 bg-[#EAF2FC] p-3 rounded-lg mb-4">
          <div className="w-64">
            <DatePicker
              selected={fromTimeExcel}
              onChange={(date: Date | null) => setFromTimeExcel(date)}
              placeholderText="с"
              className="p-2 rounded-lg"
            />
          </div>
          <div className="w-64">
            <DatePicker
              selected={toTimeExcel}
              onChange={(date: Date | null) => setToTimeExcel(date)}
              placeholderText="по"
              className="p-2 rounded-lg"
            />
          </div>
          <Button
            children="Выгрузить пользователей в Excel"
            width="300px"
            onClick={() => handleDownload("excel")}
          />
        </div>
        {/*  <div className="flex flex-row justify-start items-center gap-5 bg-[#EAF2FC] p-3 rounded-lg mb-4">
          <div className="w-64">
            <DatePicker
              selected={fromTimePayment}
              onChange={(date: Date | null) => setFromTimePayment(date)}
              placeholderText="с"
              className="p-2 rounded-lg"
            />
          </div>
          <div className="w-64">
            <DatePicker
              selected={toTimePayment}
              onChange={(date: Date | null) => setToTimePayment(date)}
              placeholderText="по"
              className="p-2 rounded-lg"
            />
          </div>
          <Button
            children="Получить статистику оплаты"
            width="300px"
            onClick={() => handleDownload("payment")}
          />
        </div> */}
      </div>
    </div>
  );
};

export { StatisticsPage };
