import React, { FC, useState, useEffect } from "react";
import { useEvent, useStore } from "effector-react";
//shared
import settingsImg from "shared/icons/chat/settings.svg";
import closeImg from "shared/icons/chat/close.svg";
// lib
import { ISettingsPanel } from "./types";
import { ModuleEditingChat } from "features/module/module-editing-chat";
import { getMyProfileFx } from "entities/users";
import { AccountModel } from "entities/account";

const SettingsPanel: FC<ISettingsPanel> = ({
  title,
  groupTitle = "",
  setVisible,
  chatAvatar,
  chatId,
  isIndividualChat,
}) => {
  const [visiblePannel, setVisiblePannel] = useState(false);
  const getMyProfile = useEvent(getMyProfileFx);

  useEffect(() => {
    void getMyProfile();
  }, [getMyProfile]);

  const accountInfo = useStore(AccountModel.$accountInfo);

  return (
    <div className="flex flex-row justify-between items-center px-[16px]">
      <div className="flex flex-row text-sm gap-[8px]">
        <div className="text-[#A4AFC2]">{groupTitle}</div>
        <div className="font-500">{title}</div>
      </div>
      <div className="flex flex-row gap-[8px]">
        {accountInfo?.account.account.role === "ADMIN" && isIndividualChat ? (
          <div className="p-[8px]" onClick={() => setVisiblePannel(true)}>
            <img src={settingsImg} alt="img" className="cursor-pointer" />
          </div>
        ) : null}
        <div
          className="flex p-[8px] cursor-pointer"
          onClick={() => setVisible(false)}
        >
          <img src={closeImg} alt="img" />
        </div>
      </div>
      {visiblePannel && (
        <div className="overlay left-0">
          <ModuleEditingChat
            onChange={setVisiblePannel}
            title="Настройки чата"
            chatAvatar={chatAvatar}
            chatId={chatId}
          />
        </div>
      )}
    </div>
  );
};

export { SettingsPanel };
