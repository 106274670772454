import { createEffect, createEvent, createStore, sample } from "effector";
import { authAPI } from "./api";
import {
  MAuthorizeUserByCredentials,
  MGetAccessTokenByRefreshToken,
  UserRoleEnum,
  UserStatusEnum,
  IGetCode,
} from "./types";
import { AppStorage } from "shared/services/app-storage";

const storage = new AppStorage();

const refresh_token = storage.getItem("refresh_token");
const access_token = storage.getItem("access_token") as string | undefined;
const role = storage.getItem("role") as UserRoleEnum | null;
const state = storage.getItem("state") as UserStatusEnum | null;

const hasUserInitialAccess = refresh_token && role && state;

/**
 * @description Сохраняет полученные креды в хранилище приложения
 * @param data
 */
const saveCredentialsToStorage = (
  data: MGetAccessTokenByRefreshToken.Response
) => {
  storage.setItem("refresh_token", data.refresh_token);
  storage.setItem("role", data.role);
  storage.setItem("state", data.state);
  if (data.access_token) storage.setItem("access_token", data.access_token);
};

const authorizeUserByCredentialsFx = createEffect({
  handler: async (dto: MAuthorizeUserByCredentials.Params) => {
    const data = await authAPI.authorizeUserByCredentials(dto);

    saveCredentialsToStorage({
      refresh_token: data.data.refresh_token,
      role: data.data.role,
      state: data.data.state,
      access_token: void 0,
    });

    return data.data;
  },
});

const requestAccessTokenByRefreshTokenFx = createEffect({
  handler: async (dto: MGetAccessTokenByRefreshToken.Params) => {
    const data = await authAPI.getAccessTokenByRefreshToken(dto);
    saveCredentialsToStorage(data.data);

    return data.data;
  },
});

const userLogoutEvent = createEvent();
const logoutPrivateScopeFx = createEffect({
  /**
   * @description Очищает все хранилице целиком, осторожно при масшабировании
   */
  handler: () => storage.clear(),
});

const $authStore = createStore<MGetAccessTokenByRefreshToken.Response | null>(
  hasUserInitialAccess ? { refresh_token, role, state, access_token } : null
)
  .on(authorizeUserByCredentialsFx.doneData, (_, data) => data)
  .on(requestAccessTokenByRefreshTokenFx.doneData, (_, data) => data)
  .on(userLogoutEvent, () => null);

sample({
  clock: userLogoutEvent,
  target: logoutPrivateScopeFx,
});

const $getCodeFx = createEffect({
  handler: async (params: IGetCode) => {
    const response = await authAPI.getCode({ ...params });
    return response.data;
  },
});

export {
  authorizeUserByCredentialsFx,
  $authStore,
  $getCodeFx,
  requestAccessTokenByRefreshTokenFx,
  userLogoutEvent,
  access_token,
};
