import React, { FC, useEffect, useState } from "react";
import classNames from "classnames";
// shared
import { Select } from "shared/ui/select";
import { Button } from "shared/ui/button";
// entities
import { patchUsersStatusFx, patchAccounStateFx } from "entities/users/model";
import { updatePromocodeFx, deletePromocodeFx } from "entities/promocode/model";
import { UserStatusEnum } from "entities/auth";
// lib
import { IStudyItem } from "./types";
import { getUserStatus, row, rowText, finalyValues } from "./helpers";

const TableItem: FC<IStudyItem> = ({
  image,
  index,
  firstName,
  secondName,
  lastName,
  description,
  status,
  haveChat,
  id,
  page,
  speciality,
  viewSpeciality = false,
  isPromo = false,
}) => {
  const statusUser = getUserStatus(status);
  const [selectedUserStatus, setSelectedUserStatus] = useState(status);
  const { avatarUser, nameUser, statusOptions, statusBgc } = finalyValues(
    image,
    firstName,
    secondName,
    page,
    status,
    lastName,
  );

  const handleStatusChange = (selectedValue: string) => {
    setSelectedUserStatus(selectedValue as UserStatusEnum);

    const promo = (selectedValue: string) => {
      if (selectedValue === "Удалить") {
        deletePromocodeFx(id);
      } else {
        updatePromocodeFx({
          id: id || "",
          status: selectedValue,
          type: "",
        });
      }
    };

    const account = (selectedValue: string) => {
      patchAccounStateFx({
        id: id || "",
        state: selectedValue as UserStatusEnum,
      });
    };

    const users = (selectedValue: string) => {
      patchUsersStatusFx({
        id: id || "",
        status: selectedValue as UserStatusEnum,
      });
    };

    page === "twoStatus"
      ? account(selectedValue)
      : page === "promo"
        ? promo(selectedValue)
        : users(selectedValue);
  };

  return (
    <div
      className={
        "flex items-center justify-between w-full py-[11px] p-6 hover:bg-white rounded-xl cursor-pointer"
      }
    >
      <div className={classNames(rowText, "w-[6%]")}>
        <span className={classNames(rowText)}>{index}</span>
      </div>
      <div className={classNames(row, "w-[23.5%]")}>
        <div className={"flex items-center "}>
          {!isPromo && (
            <div className="w-[29px] h-[29px] mr-4">
              <img
                src={avatarUser}
                width={29}
                height={29}
                className={"rounded-full  w-full h-full object-cover"}
                alt="AvatarIcon"
              />
            </div>
          )}
          <span className={classNames(rowText)}>{nameUser}</span>
        </div>
      </div>
      {description && (
        <div
          className={classNames(row, "w-[23.5%]")}
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: 3,
            lineHeight: "14px",
          }}
        >
          <span className={classNames(rowText, "text-[10px]")}>
            {description}
          </span>
        </div>
      )}
      {viewSpeciality && (
        <div className={classNames(row, "w-[23.5%]")}>
          <span className={classNames(rowText, "py-[7px] rounded-[6px]")}>
            {speciality ? speciality : "Специальность не указана"}
          </span>
        </div>
      )}
      <div className={classNames(row, "w-[23.5%]")}>
        <span
          className={classNames(rowText, "px-4 py-[7px] rounded-[6px]")}
          style={{
            backgroundColor: statusBgc,
          }}
        >
          {statusUser}
        </span>
      </div>

      <div className={classNames(row, "w-[11%]")}>
        <Select
          label={"Выберите статус"}
          options={statusOptions}
          onSelect={handleStatusChange}
        />
      </div>

      {haveChat && (
        <div className={"w-[5%]"}>
          <Button
            children={"в чат"}
            background="#FFF"
            color="#000"
            haveBorder={true}
            borderColor="#CFD6E3"
            paddingVertical={7}
          />
        </div>
      )}

      {description && <div className={"w-[5%]"}></div>}
    </div>
  );
};

export { TableItem };
