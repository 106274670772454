import React, { FC, useEffect, useState } from "react";
import { BarLoader } from "react-spinners";
// shared
import { Title } from "shared/ui/title";
import FilterButtons from "shared/ui/filter-buttons";
// effector
import { useStore } from "effector-react";
// lib
import { filterOptions } from "./model";
import { FeedbackModel, IFeedbackModel } from "entities/feedback";
import { FeedbackWidget } from "widgets/feedback/items";

const FeedbackPage: FC = () => {
  const allUsers = useStore(FeedbackModel.feedbackList);
  const loading = useStore(FeedbackModel.getFeedbackListFx.pending);

  useEffect(() => {
    void FeedbackModel.getFeedbackListFx();
  }, []);

  const allFeedback = allUsers?.feedback ?? [];
  const [filteredFeedback, setFilteredFeedback] =
    useState<IFeedbackModel[]>(allFeedback);

  const [allFeedbackState, setAllFeedbackState] =
    useState<IFeedbackModel[]>(allFeedback);

  useEffect(() => {
    setAllFeedbackState(allFeedback);
    setFilteredFeedback(allFeedback);
  }, [allUsers]);

  const setStatusFilter = (status: string | null) => {
    const filtered = status
      ? allFeedbackState.filter((item) => item.status === status)
      : allFeedbackState;
    setFilteredFeedback(filtered);
  };

  return (
    <div style={{ height: "calc(100vh - 70px)" }} className={"bg-[#F5F8FE]"}>
      <div className={"w-full px-4 py-4 h-full"}>
        <div className="flex flex-row items-center gap-[18px]">
          <Title className={"mb-6"} dark>
            Обратная связь
          </Title>
          <FilterButtons options={filterOptions} onChange={setStatusFilter} />
        </div>

        {!loading ? (
          <FeedbackWidget
            key={`feedback-${Date.now()}`}
            haveDescription={false}
            statusFilter={null}
            items={{ feedback: filteredFeedback }}
            haveChat={true}
            page="feedback"
          />
        ) : (
          <div className="flex items-center justify-center w-full h-[74%]">
            <BarLoader color="#36D7B7" width={500} loading={true} />
          </div>
        )}
      </div>
    </div>
  );
};

export { FeedbackPage };
