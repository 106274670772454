import React, { useState } from "react";
// shared
import { Title } from "shared/ui/title";
import icon from "shared/icons/musicIcon.svg";
// features
import { ModuleCreateFeature } from "features/module/module-create";
// widgets
import { MusicWidget } from "widgets/music/ui";
import { Pagination } from "widgets/pagination";
// lib
import { musicCategory } from "./lib/mocks-data";

const AllMusicPage = () => {
  const [visible, setVisible] = useState(false);

  const handleAddButtonClick = () => {
    setVisible(!visible);
  };

  return (
    <>
      <div style={{ height: "calc(100vh - 70px)" }} className={"bg-[#F5F8FE]"}>
        <div className={"flex flex-col w-full px-4 py-4 h-[100%]"}>
          <Title className={"mb-6"} dark>
            Музыка
          </Title>
          <MusicWidget music={musicCategory} isAllMusic={true} />
        </div>
      </div>

      {visible && (
        <div className="overlay">
          <ModuleCreateFeature
            icon={icon}
            title="Выбрать файл"
            onChange={handleAddButtonClick}
            visible={visible}
            isImgInput={false}
            placeholderForInput="Добавить файл"
            typeModule="addMusic"
          />
        </div>
      )}
    </>
  );
};

export { AllMusicPage };
