import { $http } from "shared/services/http";
import { ICreateModerator } from "entities/moderator";

class SchoolsAPI {
  addSchool(params: ICreateModerator.Params) {
    return $http.post(`/moderator/create/online-school`, params);
  }

  getSchools() {
    return $http.get(`/online-school/all`);
  }
}

export const schoolAPI = new SchoolsAPI();
