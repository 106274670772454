import { useEffect, useState } from "react";
import { IUseWindowResolution } from "../types";

/**
 * @description Хук возвращает разрешение внутреннего окна, реагирует на событие resize
 */
const useWindowResolution: () => IUseWindowResolution = () => {
  const { innerWidth, innerHeight } = window;
  const [{ width, height }, setCurrentWindowResolution] = useState({
    width: innerWidth,
    height: innerHeight,
  });

  /**
   * @description Слушатель события resize в объекте window, устанавливает текущее разрешение в состояние
   * @param e
   */
  const fooListener = (e: UIEvent) => {
    setCurrentWindowResolution({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", fooListener);
    return () => window.removeEventListener("resize", fooListener);
  }, []);

  return {
    width,
    height,
  };
};

export { useWindowResolution };
