class SocketFabric {
  constructor(private readonly uri: string) {}
  private readonly _socket = new WebSocket(this.uri);

  /**
   * @description Соединение с сервером установлено. Передает сокету callback функцию
   * @param fn
   */
  set onOpen(fn: () => void) {
    this._socket.onopen = fn;
  }
  /**
   * @description Получено новое сообщение. Передает сокету callback функцию
   * @param fn
   */
  set onMessage(fn: (data: any) => void) {
    this._socket.onmessage = fn;
  }
  /**
   * @description Получено новое сообщение. Передает сокету callback функцию
   * @param data
   */
  sendMessage(data: any) {
    this._socket.send(data);
  }
  /**
   * @description Соединение прервано. Передает сокету callback функцию
   * @param fn
   */
  set onClose(fn: (data: CloseEvent) => void) {
    this._socket.onclose = fn;
  }
  /**
   * @description Произошла ошибка. Передает сокету callback функцию
   * @param fn
   */
  set onError(fn: (data: Event) => void) {
    this._socket.onerror = fn;
  }
}

export { SocketFabric };
