import React, { FC, useEffect, useState } from "react";
import { useStore } from "effector-react";
// shared
import { Title } from "shared/ui/title";
import { __BASE_URL__ } from "shared/services/http";
// widgets
import { PlatesWidget } from "widgets/account/plates";
import { PhotoPlatesWidget } from "widgets/account/photo";
import { CoursesWidget } from "widgets/account/courses";
// entities
import { AccountModel } from "entities/account";

const AccountPage: FC = () => {
  const accountInfo = useStore(AccountModel.$accountInfo);
  const schoolCourses = useStore(AccountModel.$schoolCourses);
  const isLoadingCourses = useStore(
    AccountModel.getOnlineSchoolCoursesFX.pending,
  );

  const [showCoursesWidget, setShowCoursesWidget] = useState(false);

  const userRole = accountInfo?.account.account.role;
  const firstName = accountInfo?.account.account.first_name;
  const secondName = accountInfo?.account.account.second_name;
  const lastName = accountInfo?.account.account.last_name;
  const schoolName = accountInfo?.account.online_school.name;
  const profession = accountInfo?.account.doctor.profession;
  const phone = accountInfo?.account.account.phone;
  const email = accountInfo?.account.account.email;
  const info = accountInfo?.account.account.info;
  const avatar = accountInfo?.account.account.avatar;
  const schoolId = accountInfo?.account.online_school.id ?? "";

  useEffect(() => {
    AccountModel.getOnlineSchoolCoursesFX({ school_id: schoolId });
  }, []);

  useEffect(() => {
    if (!isLoadingCourses && schoolCourses !== undefined) {
      setShowCoursesWidget(true);
    }
  }, [isLoadingCourses, schoolCourses]);

  const fullName =
    userRole === "ONLINE_SCHOOL"
      ? schoolName ?? ""
      : ` ${secondName ?? ""} ${firstName ?? ""}  ${lastName ?? ""}`;

  const namePlate = {
    title: "Имя",
    children: `${fullName.trim() === "" ? "имя не указано" : fullName}`,
  };

  const badgePlate = {
    title: "Профессиональный бейджик (в одно слово)",
    children: `${profession === "" ? "неизвестно" : profession}`,
  };

  const phonePlate = {
    title: "Телефон для входа",
    children: `${phone ?? "неизвестно"} `,
    type: "tel",
  };

  const emailPlate = {
    title: "Почта",
    children: `${email ?? "неизвестно"}`,
    type: "mail",
  };

  const infoPlate = {
    title: "Информация",
    children: info ?? "нет информации",
    type: "text",
  };

  let plates: { title: string; children: string; type?: string }[] = [];

  switch (userRole) {
    case "ADMIN":
      plates = [phonePlate, emailPlate];
      break;
    case "MODERATOR":
      plates = [namePlate, phonePlate, emailPlate];
      break;
    case "DOCTOR":
      plates = [namePlate, badgePlate, phonePlate, emailPlate, infoPlate];
      break;
    case "ONLINE_SCHOOL":
      plates = [namePlate, phonePlate, emailPlate, infoPlate];
      break;
    default:
      break;
  }

  return (
    <div style={{ minHeight: "calc(100vh - 70px)" }} className={"bg-[#F5F8FE]"}>
      <div className={"w-full px-4 py-4 h-[95%]"}>
        <Title className={"mb-6"} dark>
          Аккаунт
        </Title>
        <div className="flex flex-row justify-start gap-2.5">
          <PlatesWidget items={plates} />
          {userRole !== "ADMIN" && (
            <PhotoPlatesWidget
              title="Фото профиля"
              img={avatar}
            />
          )}
          <div className="flex flex-col gap-2.5">
            {userRole === "ONLINE_SCHOOL" ? (
              !isLoadingCourses && schoolCourses !== undefined ? (
                <CoursesWidget items={schoolCourses} schoolId={schoolId} />
              ) : (
                <p>Загрузка курсов...</p>
              )
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export { AccountPage };
