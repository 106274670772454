import React, { useEffect, useState } from "react";
import { rowNames } from "../model";
import classNames from "classnames";
import { SortButton } from "shared/ui/sort-button";
import { Table } from "shared/ui/table";
import { useStore } from "effector-react";
import { ArticlesModel } from "entities/articles";
import { BarLoader } from "react-spinners";
import { TableItem } from "./table-item";
import { useNavigate } from "react-router-dom";
import { finallyValues } from "../hepers";
import { AccountModel } from "entities/account";
import { $moderatorProfileMeStore } from "entities/users";

interface ArticleListProps {
  searchValue: string;
}

const ArticleListWidget: React.FC<ArticleListProps> = ({ searchValue }) => {
  // account role
  const accountInfo = useStore(AccountModel.$accountInfo);
  const moderatorProfileMe = useStore($moderatorProfileMeStore);
  const expandedRoles = ["ADMIN", "MODERATOR"];
  const isAdmin = expandedRoles.includes(moderatorProfileMe?.account.role || "")
    ? true
    : false;
  // filter and search
  const articles = useStore(ArticlesModel.$articlesList);
  const isLoading = useStore(ArticlesModel.getAllArticlesFx.pending);
  const navigate = useNavigate();
  const [sort, setSort] = useState(true);
  const filteredArticles = finallyValues(
    articles,
    sort,
    accountInfo?.account.account.id ?? "",
    isAdmin,
  ).filter((article) =>
    article.title.toLowerCase().includes(searchValue.toLowerCase()),
  );

  useEffect(() => {
    void ArticlesModel.getAllArticlesFx({
      sort_by: "created_at",
      sort_order: "desc",
    });
  }, []);

  if (isLoading)
    return (
      <div className="flex items-center justify-center w-full h-[80vh]">
        <BarLoader color="#36D7B7" width={500} loading />
      </div>
    );

  return (
    <>
      <div className="flex flex-col gap-2 min-h-[80vh] rounded-[24px] p-6 ml-4 m-[30px]">
        <Table
          cells={rowNames.map((cell) => (
            <div
              key={cell.id}
              className={classNames("flex")}
              style={{ width: cell.width }}
            >
              <span className={"text-[#111111] opacity-30"}>{cell.title}</span>
              {cell.title === "Статус" ? (
                <SortButton sort={sort} setSort={setSort} />
              ) : null}
            </div>
          ))}
          rows={
            filteredArticles.map((row, index: number) => (
              <TableItem
                onClick={() => navigate(`/articles/${row.id}`)}
                avatar={row?.account?.avatar || ""}
                tags={row.tags}
                category={[row.category, ...row.age_category]}
                index={index + 1}
                key={index + 1}
                id={row.id}
                name={`${row.account?.first_name} ${
                  row.account?.second_name ?? ""
                } ${row.sub_account_online_school?.name ?? ""}`}
                title={row.title}
                statusArticle={row.status}
              />
            )) || []
          }
        />
      </div>
    </>
  );
};

export { ArticleListWidget };
