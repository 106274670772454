import { MGetAllUsers, usersAPI } from "../users";
import { createStore, createEffect, createEvent, guard } from "effector";
import { UserRoleEnum } from "../auth";
import { ICreateModerator, IUpdateModerator } from "entities/moderator";
import { schoolAPI } from "./api";
import { IOnlineSchoolModel } from "./types";

// get

const getAllSchoolsFx = createEffect({
  handler: async (
    params: Omit<Partial<MGetAllUsers.Params>, "role"> | void,
  ) => {
    const data = await usersAPI.fetchAllAccounts({
      ...params,
      role: UserRoleEnum.ONLINE_SCHOOL,
    });

    return data.data;
  },
});

const getAllSchoolsWithNameFx = createEffect({
  handler: async () => {
    const data = await schoolAPI.getSchools();
    return data.data;
  },
});

// add

const addSchoolFx = createEffect({
  handler: async (params: ICreateModerator.Params) => {
    const response = await schoolAPI.addSchool({ ...params });
    return response.data;
  },
});

// update

const updateSchoolEmail = createEvent<IUpdateModerator.Email>();
const updateSchoolPhone = createEvent<IUpdateModerator.Phone>();
const updateSchoolState = createEvent<IUpdateModerator.State>();
const updateSchoolFirstName = createEvent<IUpdateModerator.FirstName>();

// tracking upload

const createGuard = (fx: any) => {
  guard({
    source: fx.doneData,
    filter: (data) => data !== null,
  }).watch(() => {
    getAllSchoolsWithNameFx();
  });
};

createGuard(addSchoolFx);

// store

const $schoolUsers = createStore<MGetAllUsers.Response | null>(null).on(
  getAllSchoolsFx.doneData,
  (_, data) => data,
);

const $allSchools = createStore<IOnlineSchoolModel | null>(null).on(
  getAllSchoolsWithNameFx.doneData,
  (_, data) => data,
);

const $schoolEmailStore = createStore({
  email: "",
}).on(updateSchoolEmail, (email, newData) => ({
  ...email,
  ...newData,
}));

const $schoolPhoneStore = createStore({
  phone: "",
}).on(updateSchoolPhone, (phone, newData) => ({
  ...phone,
  ...newData,
}));

const $schoolStateStore = createStore({
  state: "",
}).on(updateSchoolState, (_, newData) => ({
  state: newData.state,
}));

const $schoolFirstNameStore = createStore({
  first_name: "",
}).on(updateSchoolFirstName, (first_name, newData) => ({
  ...first_name,
  ...newData,
}));

export {
  /**
   * @effects
   */
  getAllSchoolsFx,
  getAllSchoolsWithNameFx,
  addSchoolFx,
  /**
   * @store
   */
  $schoolUsers,
  $schoolEmailStore,
  $schoolPhoneStore,
  $schoolStateStore,
  $schoolFirstNameStore,
  $allSchools,
  /**
   * @event
   */
  updateSchoolEmail,
  updateSchoolPhone,
  updateSchoolState,
  updateSchoolFirstName,
};
