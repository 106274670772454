import React, { FC } from "react";
import { useEvent, useStore } from "effector-react";
// shared
import { Button } from "shared/ui/button";
import { Title } from "shared/ui/title";
import plusFilled from "shared/icons/plus-filled.svg";
import { useImageTempUri } from "shared/lib/hooks";
// entities
import { ArticlesModel } from "entities/articles";

const CreateArticleFeature: FC<{
  $ref?: React.MutableRefObject<HTMLDivElement | null>;
  haveAbilityToDelete?: boolean;
}> = ({ $ref, haveAbilityToDelete }) => {
  const articleCover = useStore(ArticlesModel.$coverArticle);

  const fileTempUri = useImageTempUri(articleCover);
  const createArticle = useEvent(ArticlesModel.createArticleFx);
  const changeArticle = useEvent(ArticlesModel.changeArticleFx);
  const statusArticle = useStore(ArticlesModel.$currentArticle)?.article.status;
  const idArticle = useStore(ArticlesModel.$currentArticle)?.article
    .id as string;

  return (
    <div className="flex flex-col w-[19vw] m-[30px] gap-[16px]" ref={$ref}>
      <Title
        children={
          haveAbilityToDelete ? "Редактировать статью" : "Создать статью"
        }
        dark
      />
      <div className="flex flex-col gap-[10px]">
        {haveAbilityToDelete ? (
          <Button
            onClick={() =>
              changeArticle({
                status: "published",
                type: "",
                id: idArticle,
              })
            }
            children={"Сохранить"}
          />
        ) : (
          <Button onClick={createArticle} children={"Опубликовать"} />
        )}
        {statusArticle === "draft" ? (
          <Button
            onClick={() =>
              changeArticle({
                status: "published",
                type: "",
                id: idArticle,
              })
            }
            children={"Опубликовать черновик"}
          />
        ) : null}
        {statusArticle !== "deleted" && statusArticle !== undefined ? (
          <Button
            onClick={() =>
              changeArticle({
                status: "deleted",
                type: "",
                id: idArticle,
              })
            }
            children={"Удалить"}
            background="rgba(242, 100, 100, 1)"
          />
        ) : null}
        {!haveAbilityToDelete ? (
          <div className="flex flex-row gap-[10px]">
            <Button
              onClick={() =>
                createArticle({
                  status: "draft",
                  type: void 0,
                })
              }
              children={"В черновики"}
            />
            {haveAbilityToDelete ? (
              <Button children={"Удалить"} background="#FF7253" />
            ) : null}
          </div>
        ) : (
          void 0
        )}
      </div>
      {/*<Select*/}
      {/*  label="Опубликовать от аккаунта"*/}
      {/*  options={[*/}
      {/*    { label: "test", value: "option1" },*/}
      {/*    { label: "test", value: "option2" },*/}
      {/*  ]}*/}
      {/*/>*/}
      <label
        htmlFor={"filePicker"}
        style={{
          background: `url("${fileTempUri}") no-repeat center`,
        }}
        className={
          "w-[19vw] h-[19vw] border-[#0967FD] border-[2px] border-dashed rounded-lg flex justify-center items-center cursor-pointer bg-cover"
        }
      >
        <input
          type={"file"}
          id={"filePicker"}
          style={{ display: "none" }}
          onChange={(file) =>
            file.target.files
              ? ArticlesModel.changeСoverArticleEv(file.target.files[0])
              : void function () {}
          }
        />
        <img src={plusFilled} alt="" />
      </label>
    </div>
  );
};

export { CreateArticleFeature };
