import { statisticsAPI } from "./api";
import { IStatisticsModel } from "./types";
import { createEffect, createStore } from "effector";

const getStatisticsFX = createEffect({
  handler: async (params: IStatisticsModel.Params) => {
    const response = await statisticsAPI.getStatistics({ ...params });
    return response.data;
  },
});

const getStatisticsPayFX = createEffect({
  handler: async (params: IStatisticsModel.Params) => {
    const response = await statisticsAPI.getStatisticsPay({ ...params });
    return response.data;
  },
});

const $statisticsExel = createStore<string | null>(null).on(
  getStatisticsFX.doneData,
  (_, data) => data,
);

const $statisticsPay = createStore<string | null>(null).on(
  getStatisticsPayFX.doneData,
  (_, data) => data,
);

export {
  /**
   * @effects
   */
  getStatisticsFX,
  getStatisticsPayFX,
  /**
   * @store
   */
  $statisticsExel,
  $statisticsPay,
};
