import React, { FC, useState } from "react";
import { useStore } from "effector-react";
import classNames from "classnames";
// shared
import ArrowBottom from "shared/icons/controls/arrow-bottom.svg";
// lib
import { ISelect } from "./types";
// entities
import { ArticlesModel } from "entities/articles";

const SelectCategory: FC<ISelect<string>> = ({
  type,
  className,
  minWidth = 100,
  options,
  maxWidth = 100,
}) => {
  const [active, setActive] = useState<boolean>(false);
  const viewCategory = useStore(ArticlesModel.$viewCategoryArticle);
  const viewAgeCategory = useStore(ArticlesModel.$viewAgeCategoryArticle);
  const placeholder = type === "Категории" ? viewCategory : "Возраст";

  const handleOptionClick = (
    selectedValue: string,
    selectedLabel: string,
    viewLabel: string,
  ) => {
    setActive(false);

    if (type === "Категории") {
      ArticlesModel.changeCategoryArticleEv(selectedLabel);
      ArticlesModel.changeViewCategoryArticleEv(viewLabel);
    } else if (type === "Возраст") {
      const currentValue = ArticlesModel.$ageCategoryArticle.getState();
      const newValue = currentValue.includes(selectedValue)
        ? currentValue.filter((value) => value !== selectedValue)
        : [...currentValue, selectedValue];
      ArticlesModel.changeAgeCategoryArticleEv(newValue);
      const currentViewValue = ArticlesModel.$viewAgeCategoryArticle.getState();
      const newViewValue = currentViewValue.includes(selectedLabel)
        ? currentViewValue.filter((value) => value !== selectedLabel)
        : [...currentViewValue, selectedLabel];
      ArticlesModel.changeViewAgeCategoryArticleEv(newViewValue);
    }
  };

  return (
    <div
      className={classNames("h-[41px] relative", className)}
      style={{ minWidth: `${minWidth}%`, maxWidth: `${maxWidth}%` }}
    >
      <div
        onClick={() => setActive((prev) => !prev)}
        className={classNames(
          "flex items-center justify-between bg-white rounded-[8px] px-4 border-gray-input border-[1px] h-full cursor-pointer",
        )}
      >
        <span className={"text-sm mr-2"}>{placeholder}</span>
        <img src={ArrowBottom} alt="ArrowBottom" />
      </div>
      {active && (
        <div
          className={classNames(
            "absolute z-10 bg-[#EAF2FC] w-[100%] top-[110%] rounded-[10px] text-sm p-[10px]",
          )}
        >
          {options.map((option) => (
            <div
              key={option.value}
              style={{
                cursor: "pointer",
                margin: "10px",
                padding: "7px",
                borderRadius: "10px",
                transition: "0.3s",
              }}
              onClick={() =>
                handleOptionClick(option.value, option.label, option.value)
              }
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#fff";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "#EAF2FC";
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export { SelectCategory };
