import React, { FC, ChangeEvent, useState } from "react";
import classNames from "classnames";
import { InputMask, useMask } from "@react-input/mask";
import { IModuleInput } from "./types";

interface ModuleInputProps extends IModuleInput {
  onChange?: (value: string) => void;
}

const ModuleInput: FC<ModuleInputProps> = ({
  type,
  placeholder,
  maxLength,
  disabled,
  img,
  onChange,
}) => {
  const [valueMask, setValueMask] = useState("");

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValueMask(value);
    if (onChange) {
      onChange(value);
    }
  };

  const inputRef = useMask({
    mask: "+____________",
    replacement: { _: /\d/ },
  });

  return (
    <div
      className={classNames(
        "flex items-center border-gray-input border-[1px] w-full h-[50px] min-h-[50px] rounded-[10px] px-4 mb-3.5",
        { "opacity-50": disabled },
      )}
    >
      {type !== "tel" ? (
        <input
          disabled={disabled}
          maxLength={maxLength}
          type={type}
          className={
            "w-full h-full bg-transparent outline-none text-sm text-gray-36 mr-4"
          }
          placeholder={placeholder}
          onChange={handleInputChange}
        />
      ) : (
        <input
          value={valueMask}
          className={
            "w-full h-full bg-transparent outline-none text-sm text-gray-36 mr-4"
          }
          maxLength={maxLength}
          ref={inputRef}
          placeholder={placeholder}
          onChange={handleInputChange}
        />
      )}
      {img && <img src={img} alt="img" className="w-[15px] h-[16px]" />}
    </div>
  );
};

export { ModuleInput };
