import React, { FC, Fragment, useState } from "react";
import cameraIcon from "shared/icons/camera.svg";
import closeButton from "shared/icons/close.svg";

const EditableItem: FC<{
  contentRef: any;
  contentWidth: number;
  pushNewItem?: () => void;
}> = ({ contentRef, contentWidth, pushNewItem }) => {
  const [iconHidden, setIconHidden] = useState(false);
  const [iconCameraHidden, setIconCameraHidden] = useState(false);
  const [displayHelper, setDisplayHelper] = useState(false);
  const [showInput, setShowInput] = useState(false);

  // view button panel
  const handleSelection = () => {
    const selection = window.getSelection();
    if (selection && selection.toString() !== "") {
      setDisplayHelper(true);
    } else {
      setDisplayHelper(false);
    }
  };

  // click on camera icon
  const handleCameraIconClick = () => {
    setShowInput(true);
  };

  // add new img in container
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const img = document.createElement("img");
        img.src = reader.result as string;
        contentRef.current.appendChild(img);
        if (pushNewItem) {
          // проверка на существование pushNewItem
          pushNewItem();
        }
      };
      reader.readAsDataURL(file);
    }
    setShowInput(false);
  };

  // text editor
  const applyStyle = (style: string, value?: string) => {
    document.execCommand(style, false, value);
  };

  const changeFontSize = (size: string) => {
    document.execCommand("fontSize", false, size);
  };

  const toggleList = () => {
    document.execCommand("insertUnorderedList");
  };

  const wrapSelectionWithTag = (tag: string) => {
    const selection = window.getSelection();
    if (selection) {
      const range = selection.getRangeAt(0);
      const selectedText = range.toString();
      const newNode = document.createElement(tag);
      newNode.appendChild(document.createTextNode(selectedText));

      // Get the parent element of the selected text
      const parentElement = range.commonAncestorContainer.parentElement;

      // Replace the parent <li> element with the new <p> element
      if (parentElement && parentElement.tagName.toLowerCase() === "li") {
        parentElement.replaceWith(newNode);
      } else {
        // If the parent is not an <li> element, just insert the new <p> element
        range.deleteContents();
        range.insertNode(newNode);
      }
    }
  };

  const handleListButtonClick = (apply: boolean) => {
    if (apply) {
      wrapSelectionWithTag("li");
    } else {
      wrapSelectionWithTag("p");
    }
  };

  return (
    <Fragment>
      <div
        onMouseUp={handleSelection}
        onKeyDown={handleSelection}
        style={{
          display: "flex",
          alignItems: "flex-end",
          position: "relative",
          width: "100%",
        }}
      >
        {!iconHidden && (
          <>
            {showInput && (
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageUpload}
                ref={(input) => {
                  if (input) {
                    input.click();
                  }
                }}
              />
            )}
            <>
              {!iconCameraHidden && (
                <img
                  src={cameraIcon}
                  alt=""
                  style={{
                    marginRight: 8,
                    marginBottom: 12,
                    cursor: "pointer",
                  }}
                  onClick={handleCameraIconClick}
                />
              )}
            </>
          </>
        )}
        <div
          onDoubleClick={(event) => {
            event.stopPropagation();
          }}
          style={{
            wordWrap: "break-word",
            cursor: "text",
          }}
          placeholder={"Напишите что-нибудь"}
          className={`contentEditableItem w-full my-2 outline-none cursor-text overflow-y-scroll w-[${contentWidth}px] max-w-[${contentWidth}px]`}
          contentEditable
          ref={contentRef}
        />
        {displayHelper ? (
          <div
            style={{
              padding: 8,
              position: "absolute",
              zIndex: 999,
              top: -60,
              backgroundColor: "black",
              borderRadius: "8px",
              color: "white",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div className="flex flex-row items-center">
              <button
                style={{ cursor: "pointer", padding: "8px 12px" }}
                onClick={() => {
                  changeFontSize("6");
                }}
              >
                Заголовок
              </button>
              <button
                style={{ cursor: "pointer", padding: "8px 12px" }}
                onClick={() => {
                  changeFontSize("4.5");
                }}
              >
                Подзаголовок
              </button>
              <button
                style={{ cursor: "pointer", padding: "8px 12px" }}
                onClick={() => {
                  changeFontSize("3");
                  handleListButtonClick(false);
                }}
              >
                Обычный текст
              </button>
              <button
                style={{ cursor: "pointer", padding: "8px 12px" }}
                onClick={() => applyStyle("bold")}
              >
                Жирный
              </button>
              <button
                style={{ cursor: "pointer", padding: "8px 12px" }}
                onClick={() => applyStyle("italic")}
              >
                Курсив
              </button>
              <button
                style={{ cursor: "pointer", padding: "8px 12px" }}
                onClick={() => applyStyle("underline")}
              >
                Подчеркнутый
              </button>
              <button
                style={{ cursor: "pointer", padding: "8px 12px" }}
                onClick={() => handleListButtonClick(true)}
              >
                • Список
              </button>
              <button
                style={{
                  cursor: "pointer",
                  padding: "8px 8px",
                  borderRadius: "8px",
                  fontWeight: "400",
                }}
                onClick={() => setDisplayHelper(false)}
              >
                <img src={closeButton} alt="close" />
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export { EditableItem };
