class AppStorage {
  /**
   * @description Получает заданное значение из window.localStorage
   * @param key
   */
  public getItem(key: string): string | null {
    return window.localStorage.getItem(key);
  }

  /**
   * @description Устанавливает значение в window.localStorage
   * @param key
   * @param value
   */
  public setItem(key: string, value: string): void {
    window.localStorage.setItem(key, value);
  }

  /**
   * @description Очищает весь window.localStorage
   */
  public clear(): void {
    window.localStorage.clear();
  }
}

export { AppStorage };
