import { $http } from "shared/services/http";
import { MFeedbackList, IStatusFeedback, IGoFeedbackChat } from "./types";
import { AxiosResponse } from "axios";

class FeedbackApi {
  getList(
    params?: MFeedbackList.Params,
  ): Promise<AxiosResponse<MFeedbackList.Response>> {
    return $http.get(`/moderator/create/feedback/list`, { params });
  }

  changeStatusFeedback(params: IStatusFeedback.Params) {
    return $http.patch(`/moderator/create/feedback`, params);
  }

  goFeedbackChat(params: IGoFeedbackChat) {
    return $http.post(`/moderator/create/feedback/go_chat`, params);
  }
}

export const feedbackApi = new FeedbackApi();
