import React from "react";

const finalValues = (
  role: string | undefined,
  firstName: string | undefined,
  secondName: string | undefined,
  lastName: string | undefined,
  schoolName: string | undefined,
) => {
  let userRole;
  switch (role) {
    case "ADMIN":
      userRole = "Администратор";
      break;
    case "MODERATOR":
      userRole = "Модератор";
      break;
    case "DOCTOR":
      userRole = "Доктор";
      break;
    case "ONLINE_SCHOOL":
      userRole = "Онлайн-школа";
      break;
    default:
      userRole = "роль не указана";
      break;
  }

  const fullName = `${secondName ?? ""} ${firstName ?? ""} ${lastName ?? ""} ${
    schoolName ?? ""
  }`;
  return [userRole, fullName];
};

export { finalValues };
