import React, { FC, useEffect, useState } from "react";
import { BarLoader } from "react-spinners";
// shared
import { Title } from "shared/ui/title";
import { BaseInput } from "shared/ui/input";
import SearchIcon from "shared/icons/controls/search.svg";
import icon from "shared/icons/navbar/moderator-active.svg";
import { Button } from "shared/ui/button";
// widgets
import { AccountListWidget } from "widgets/account-list/ui";
// features
import { ModuleCreateUsersFeature } from "features/module/module-add-users";
import "features/module/style.css";
// effector
import { useStore } from "effector-react";
// entities
import { ModeratorModel } from "entities/moderator";

const ModeratorPage: FC = () => {
  const [visible, setVisible] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleAddButtonClick = () => {
    setVisible(!visible);
  };

  const allModerators = useStore(ModeratorModel.$moderatorUsers);
  const isLoading = useStore(ModeratorModel.getAllModeratorsFx.pending);

  useEffect(() => {
    ModeratorModel.getAllModeratorsFx();
  }, []);

  return (
    <div style={{ height: "calc(100vh - 70px)" }} className={"bg-[#F5F8FE]"}>
      <div className={"w-full px-4 py-4 h-[100%]"}>
        <Title className={"mb-6"} dark>
          Модераторы
        </Title>
        <div
          className={
            "flex justify-between items-center mb-5 bg-[#EAF2FC] p-3 rounded-xl"
          }
        >
          <div className={"w-[38%]"}>
            <BaseInput
              placeholder={"Найти модератора"}
              renderLeftIcon={SearchIcon}
              onInputChange={setSearchText}
            />
          </div>
          <div className={"flex"}>
            <div className={"w-[201px]"}>
              <Button onClick={handleAddButtonClick}>Добавить</Button>
            </div>
          </div>
        </div>

        {!isLoading ? (
          <AccountListWidget
            users={allModerators}
            searchText={searchText}
            page="twoStatus"
            type="moderators"
          />
        ) : (
          <div className="flex items-center justify-center w-full h-[74%]">
            <BarLoader color="#36D7B7" width={500} loading={isLoading} />
          </div>
        )}

        {visible && (
          <div className="overlay">
            <ModuleCreateUsersFeature
              icon={icon}
              title="Новый модератор"
              onChange={handleAddButtonClick}
              visible={visible}
              options={[
                { label: "Активный", value: "ACTIVE" },
                { label: "Приостановлен", value: "INACTIVE" },
              ]}
              role="moderator"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { ModeratorPage };
