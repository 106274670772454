import React, { FC, useState } from "react";
import classNames from "classnames";
// shared
import { Select } from "shared/ui/select";
import { ButtonRoute } from "shared/ui/button/route";
import pauseButton from "shared/icons/pauseButton.png";
// entities
import { getStatusBackgroundColor } from "shared/ui/table-item/helpers";
// lib
import { finalyValues } from "../lib/helpers";
import { MusicItemProps } from "../types";
import {
  $currentSongPlay,
  $isSongPlay,
  changeCurrentSongEvent,
  patchMusicStatusFx,
} from "../model";
import { useEvent, useStore } from "effector-react";

const MusicItem: FC<MusicItemProps> = ({
  index,
  title,
  status = "",
  path = "",
  duration = 0,
  name,
}) => {
  const [selectedStatus, setSelectedStatus] = useState(status);
  const { icon, musicTitle, musicStatus, row, rowText } = finalyValues(
    duration,
    title,
    status,
  );
  const changeCurrentSong = useEvent(changeCurrentSongEvent);
  const isSongPlay = useStore($isSongPlay);
  const currentSongPlay = useStore($currentSongPlay);

  const conditionIcon = !isSongPlay;

  const handleStatusChange = (selectedValue: string) => {
    setSelectedStatus(selectedValue);
    patchMusicStatusFx({
      music_id: name || "",
      status: selectedValue,
    });
  };

  return (
    <div
      className={classNames(
        "flex items-center justify-between w-full py-[11px] p-6 hover:bg-white rounded-xl cursor-pointer",
      )}
    >
      <div className={classNames(rowText, "w-[6%]")}>
        <span className={classNames(rowText)}>{index}</span>
      </div>
      <div className={classNames(row, "w-[23.5%]")}>
        <div className={"flex items-center "}>
          <img
            src={
              currentSongPlay === name
                ? conditionIcon
                  ? icon
                  : pauseButton
                : icon
            }
            width={29}
            height={29}
            className={"rounded-full mr-4 w-[29px] h-[29px]"}
            alt="AvatarIcon"
            onClick={() => changeCurrentSong(name as string)}
          />
          <span className={classNames(rowText)}>{musicTitle}</span>
        </div>
      </div>
      {status && (
        <div className={classNames(row, "w-[23.5%]")}>
          <span
            className={classNames(rowText, "px-4 py-[7px] rounded-[6px]")}
            style={{
              backgroundColor: getStatusBackgroundColor(status),
            }}
          >
            {musicStatus}
          </span>
        </div>
      )}
      <div className={classNames(row, "w-[11%]")}>
        {status ? (
          <Select
            label={"Выберите статус"}
            options={[
              { value: "ACTIVE", label: "Активен" },
              { value: "SUSPENDED", label: "Приостановлен" },
              { value: "DELETE", label: "Удалить" },
            ]}
            onSelect={handleStatusChange}
            id={name || ""}
          />
        ) : (
          <ButtonRoute children="Перейти" path={path} />
        )}
      </div>
    </div>
  );
};

export { MusicItem };
