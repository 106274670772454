import React, { FC, useState } from "react";
import classNames from "classnames";
// entities
import { Table } from "entities/users";
import { MusicItem } from "entities/audio/ui";
// lib
import { finalyValues } from "../lib/helpers";
import { IMusicWidget } from "../types";
// shared
import { SortButton } from "shared/ui/sort-button";
import Pagination from "widgets/page-pagination";

const MusicWidget: FC<IMusicWidget> = ({
  music,
  isAllMusic = false,
  searchText,
}) => {
  // sorted and filtered
  const [sort, setSort] = useState(true);
  const { rowsTitle, filteredMusic } = finalyValues(music, sort, isAllMusic);
  const searchedMusic = searchText
    ? filteredMusic.filter(
        (track) =>
          track &&
          track.title &&
          track.title.toLowerCase().includes(searchText.toLowerCase()),
      )
    : filteredMusic;

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(searchedMusic.length / 6);
  const startIndex = (currentPage - 1) * 6;
  const endIndex = startIndex + 6;
  const usersOnCurrentPage = searchedMusic.slice(startIndex, endIndex);

  const goToPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className={"w-full h-[80%] rounded-xl overflow-auto  flex flex-col"}>
      <Table
        cells={rowsTitle.map((cell) => (
          <div
            key={cell.id}
            className={classNames("flex")}
            style={{ width: cell.width }}
          >
            <span className={"text-[#111111] opacity-30"}>{cell.title}</span>
            {cell.title === "Статус" ? (
              <SortButton sort={sort} setSort={setSort} />
            ) : null}
          </div>
        ))}
        rows={usersOnCurrentPage.map((row, index: number) => (
          <MusicItem
            index={startIndex + index + 1}
            key={index}
            name={row.name}
            status={row.status}
            path={row.path}
            description={row.description}
            title={row.title}
            duration={row.duration}
          />
        ))}
      />
      <div className="mt-auto">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          goToPage={goToPage}
        />
      </div>
    </div>
  );
};

export { MusicWidget };
