import React, { FC } from "react";
import classNames from "classnames";
import { IFilledButton } from "./types";

const FilledButton: FC<IFilledButton> = ({
  children,
  className,
  onClick,
  disabled,
}) => {
  return (
    <div
      onClick={!disabled ? onClick : undefined}
      className={classNames(
        "w-full rounded-lg bg-[#0767FD] h-[50px] flex justify-center items-center",
        className,
        {
          "opacity-50": disabled,
          "cursor-pointer": !disabled,
        },
      )}
    >
      <span className={"text-white font-medium text-sm"}>{children}</span>
    </div>
  );
};

export { FilledButton };
