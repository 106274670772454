import { $http } from "shared/services/http";
import { ICreateModerator } from "./types";

class ModeratorsApi {
  addModerator(params: ICreateModerator.Params) {
    return $http.post(`/moderator/create/moderator`, params);
  }
}

export const moderatorsApi = new ModeratorsApi();
