import React, { useState } from "react";
import classNames from "classnames";
import { Table, TableItem } from "entities/users";
import { rowNames } from "widgets/users/users-items/lib";
import Pagination from "widgets/page-pagination";
import { ISchoolListWidget } from "../types";
import { finalyValues } from "../lib/helpers";
import { SortButton } from "shared/ui/sort-button";

const SchoolListWidget: React.FC<ISchoolListWidget> = ({
  haveChat,
  users,
  page = "twoStatus",
  searchText,
}) => {
  // sorted and filtered
  const [sort, setSort] = useState(true);
  const filteredUsers = finalyValues(users, sort);
  const searchedUsers = searchText
    ? filteredUsers.filter(
        (user) =>
          user.name &&
          user.name.toLowerCase().includes(searchText.toLowerCase()),
      )
    : filteredUsers;

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(searchedUsers.length / 7);
  const startIndex = (currentPage - 1) * 7;
  const endIndex = startIndex + 7;
  const usersOnCurrentPage = searchedUsers.slice(startIndex, endIndex);

  const goToPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className={"w-full h-[74%] rounded-xl overflow-auto flex flex-col"}>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        goToPage={goToPage}
      />
      <Table
        cells={rowNames.map((cell) => (
          <div
            key={cell.id}
            className={classNames("flex")}
            style={{ width: cell.width }}
          >
            <span className={"text-[#111111] opacity-30"}>{cell.title}</span>
            {cell.title === "Статус" ? (
              <SortButton sort={sort} setSort={setSort} />
            ) : null}
          </div>
        ))}
        rows={usersOnCurrentPage.map((row, index: number) => (
          <TableItem
            index={startIndex + index + 1}
            key={row.id}
            image={row.account.avatar}
            firstName={row.name}
            secondName={row.account.second_name}
            lastName={row.account.last_name}
            status={row.account.state}
            haveChat={haveChat}
            id={row.account.id}
            page={page}
          />
        ))}
      />
      <div className="mt-auto">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          goToPage={goToPage}
        />
      </div>
    </div>
  );
};

export { SchoolListWidget };
