import { useState } from "react";
import { useSpring } from "react-spring";
export const useDropdownAnimation = () => {
  const [isOpen, setIsOpen] = useState(true);

  const springProps = useSpring({
    maxHeight: isOpen ? "500px" : "0",
    opacity: isOpen ? 1 : 0,
  });

  const rotateArrow = useSpring({
    transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
  });

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return {
    isOpen,
    springProps,
    rotateArrow,
    toggleDropdown,
  };
};
