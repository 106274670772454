import React, { FC, useState } from "react";
import classNames from "classnames";
// lib
import { ITableItem } from "../types";
import { articlesValues } from "../hepers";

const TableItem: FC<ITableItem> = ({
  id,
  name,
  title,
  index,
  tags,
  avatar,
  statusArticle,
  onClick,
  category,
}) => {
  const rowText = "text-[14px] font-normal";
  const [statusColor, statusTitle] = articlesValues(statusArticle);

  const getCategoryText = (category: string): string => {
    if (category === "from0to0.5") {
      return "До 6 месяцев";
    } else if (category === "from0.5to1") {
      return "6–12 месяцев";
    } else if (category === "from1to2") {
      return "12–24 месяцев";
    } else if (category === "from2to") {
      return "От 24 месяцев";
    } else {
      return category;
    }
  };

  return (
    <div
      onClick={onClick}
      className={
        "flex items-center justify-between gap-2 w-full py-[11px] p-6 hover:bg-white rounded-xl cursor-pointer"
      }
    >
      <div className={classNames(rowText, "w-[5%]")}>
        <span className={classNames(rowText)}>{index}</span>
      </div>

      <span className={classNames(rowText, "text-[10px] w-[15%]")}>
        {tags.join(" ") || ""}
      </span>
      <div className="text-[10px] w-[15%] flex flex-wrap">
        {category &&
          category.length > 1 &&
          category.map((item, index) => (
            <span
              key={index}
              className={classNames(
                rowText,
                "bg-[#CFD6E3] rounded-md  p-1 m-1",
              )}
            >
              {getCategoryText(item)}
            </span>
          ))}
      </div>
      <span className={classNames(rowText, "text-[10px] w-[40%]")}>
        {title}
      </span>

      <span className={classNames(rowText, "text-[10px] w-[10%]")}>{name}</span>
      <div className={classNames(rowText, "w-[15%]")}>
        <span
          className={classNames(rowText, "flex px-4 py-[7px] rounded-[6px]")}
          style={{ backgroundColor: statusColor }}
        >
          {statusTitle}
        </span>
      </div>
    </div>
  );
};

export { TableItem };
