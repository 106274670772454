import { createEffect, createStore, guard } from "effector";
import { accountAPI } from "./api";
import {
  AccountAvatar,
  MAccount,
  OnlineSchoolChangeInfo,
  AccountChangePhone,
  AccountAllCourse,
  AccountAddSchoolCourse,
  DoctorChangeInfo,
} from "./types";

// Effect
const getAccountInfoFx = createEffect({
  handler: async () => {
    return await accountAPI.getAccountInfo();
  },
});

const getOnlineSchoolCoursesFX = createEffect({
  handler: async (data: AccountAllCourse.Params) => {
    return await accountAPI.getOnlineSchoolCourses(data);
  },
});

const putAccountAvatarFx = createEffect({
  handler: async (data: AccountAvatar.Params) => {
    const response = await accountAPI.putAccountAvatar(data);
    return response.data;
  },
});

const putOnlineSchoolChangeInfoFx = createEffect({
  handler: async (data: OnlineSchoolChangeInfo.Params) => {
    const response = await accountAPI.putOnlineSchoolChangeInfo(data);
    return response.data;
  },
});

const patchDoctorChangeInfoFx = createEffect({
  handler: async (data: DoctorChangeInfo.Params) => {
    const response = await accountAPI.patchDoctorChangeInfo(data);
    return response.data;
  },
});

const patchAccountChangePhoneFx = createEffect({
  handler: async (data: AccountChangePhone.Params) => {
    const response = await accountAPI.patchAccountChangePhone(data);
    return response.data;
  },
});

const postOnlineSchoolAddCourseFx = createEffect({
  handler: async (data: AccountAddSchoolCourse.Params) => {
    const response = await accountAPI.postOnlineSchoolAddCourse(data);
    return response.data;
  },
});

const deletedOnlineSchoolAddCourseFx = createEffect({
  handler: async (data: string) => {
    const response = await accountAPI.deletedOnlineSchoolAddCourse(data);
    return response.data;
  },
});

// tracking upload
const createGuard = (fx: any) => {
  guard({
    source: fx.doneData,
    filter: (data) => data !== null,
  }).watch(() => {
    getAccountInfoFx();
  });
};

createGuard(putAccountAvatarFx);
createGuard(putOnlineSchoolChangeInfoFx);
createGuard(patchAccountChangePhoneFx);
createGuard(patchDoctorChangeInfoFx);

// Store
const $accountInfo = createStore<MAccount.Response | null>(null).on(
  getAccountInfoFx.done,
  (_, { result }) => result.data,
);

const $schoolCourses = createStore<AccountAllCourse.Respose | null>(null).on(
  getOnlineSchoolCoursesFX.done,
  (_, { result }) => result.data,
);

export {
  /**
   * @effects
   */
  getAccountInfoFx,
  putAccountAvatarFx,
  putOnlineSchoolChangeInfoFx,
  patchAccountChangePhoneFx,
  getOnlineSchoolCoursesFX,
  postOnlineSchoolAddCourseFx,
  deletedOnlineSchoolAddCourseFx,
  patchDoctorChangeInfoFx,
  /**
   * @stores
   */
  $accountInfo,
  $schoolCourses,
};
