import { createEffect, createEvent, createStore, guard } from "effector";
import { useStore } from "effector-react";
import { articlesAPI } from "./api";
import { MGetAllArticles } from "./types";
import { toast } from "react-toastify";
import { $moderatorProfileMeStore } from "../users";
import React from "react";
import { IArticleModel, MCreateArticle, MGetOneArticle } from "../audio";
import { $accountInfo } from "entities/account/model";

const uploadFileImageFx = createEffect({
  handler: async (file: File) => {
    const formData = new FormData();
    formData.append("photo", file);

    const { data } = await articlesAPI.uploadFileImage(formData);
    return data;
  },
});

const getAllArticlesFx = createEffect({
  handler: async (params: MGetAllArticles.Params | void) => {
    const { data } = await articlesAPI.getAllArticles(params);
    return data;
  },
});

const changeArticleStatusFx = createEffect({
  handler: async (params: MGetAllArticles.Params | void) => {
    const { data } = await articlesAPI.getAllArticles(params);
    return data;
  },
});

const resetEditorToDefaultEv = createEvent();

const changeCurrentArticlePayloadEv = createEvent<string>();
const $currentArticlePayload = createStore<string>("").on(
  changeCurrentArticlePayloadEv,
  (_, data) => {
    return data;
  },
);

const changeCurrentArticlePayloadTitleEv = createEvent<string>();
const $currentArticleTitlePayload = createStore<string>("")
  .on(changeCurrentArticlePayloadTitleEv, (_, data) => data)
  .on(resetEditorToDefaultEv, () => "");

const watcherOn = false;

$currentArticlePayload.watch(
  watcherOn
    ? (_, payload) => {
        if (!payload) return;
        const dom = new DOMParser().parseFromString(payload, "text/html");
        console.log(dom);
      }
    : function () {},
);

const $articlesList = createStore<MGetAllArticles.Response | null>(null).on(
  getAllArticlesFx.doneData,
  (_, data) => data,
);
const changeArticleEditorEvent = createEvent<string>();
const $currentArticleEditor = createStore<string>("")
  .on(changeArticleEditorEvent, (_, data) => data)
  .on(resetEditorToDefaultEv, () => "");

// create article
const createArticleFx = createEffect({
  handler: async (dto?: MCreateArticle.PartialParams) => {
    console.log("СОЗДАНИЕ");
    // values
    const userDoneArticleData = $currentArticlePayload.getState();
    const { data } = await articlesAPI.requestUUID();
    const articleCover = $coverArticle.getState();
    const category = $categoryArticle.getState();
    const ageCategory = $ageCategoryArticle.getState();
    const accountId =
      $moderatorProfileMeStore.getState()?.account.id ??
      $accountInfo.getState()?.account.account.id;
    const tags = $tagsArticle.getState().split(",");

    try {
      // validation
      if (!userDoneArticleData) {
        console.log(userDoneArticleData);
        toast.error("Для начала напишите статью");
        return void -1;
      }

      if (!accountId) {
        toast.error("Данные профиля не были получены");
        return void -1;
      }

      if (!$currentArticleTitlePayload.getState()) {
        toast.error("Необходимо ввести название статьи");
        return void -1;
      }

      // api
      await articlesAPI.createArticle({
        photo: articleCover,
        id: data.id,
        account_id: accountId,
        title: $currentArticleTitlePayload.getState(),
        tags: tags,
        body: [
          {
            type: "text",
            payload: userDoneArticleData,
          },
        ],
        category: category,
        age_category: ageCategory,
        ...dto,
      });

      toast.success("Спасибо! Статья успешно создана");
    } catch (e) {
      toast.error("Ошибка при сохранении данных");
    }
  },
});

// change article
const changeArticleFx = createEffect({
  handler: async (dto: MCreateArticle.PartialParams & { id: string }) => {
    console.log("ИЗМЕНЕНИЕ");
    // values
    const userDoneArticleData = $currentArticlePayload.getState();
    const accountId =
      $moderatorProfileMeStore.getState()?.account.id ??
      $accountInfo.getState()?.account.account.id;
    const tags = $tagsArticle.getState().split(",");
    const articleCover = $coverArticle.getState();
    const category = $categoryArticle.getState();
    const ageCategory = $ageCategoryArticle.getState();

    try {
      // validation
      if (!accountId) {
        toast.error("Данные профиля не были получены");
        return void -1;
      }
      if (!$currentArticleTitlePayload.getState()) {
        toast.error("Необходимо ввести название статьи");
        return void -1;
      }

      // api
      await articlesAPI.changeArticle({
        photo: articleCover,
        account_id: accountId,
        title: $currentArticleTitlePayload.getState(),
        tags: tags,
        body: [
          {
            type: "text",
            payload: userDoneArticleData,
          },
        ],
        category: category,
        age_category: ageCategory,
        ...dto,
      });

      toast.success("Спасибо! Статья успешно отредактирована");
    } catch (e) {
      toast.error("Ошибка при сохранении данных");
    }
  },
});

$currentArticleEditor.watch(console.log);

guard({
  source: createArticleFx.doneData,
  filter: (data) => data !== null,
}).watch(() => {
  // resetEditorToDefaultEv();
  void getAllArticlesFx({ sort_by: "created_at", sort_order: "desc" });
});

const getOneArticleFX = createEffect({
  handler: async (dto: MGetOneArticle.Params) => {
    const { data } = await articlesAPI.getOne(dto);
    return data;
  },
});

// store
const $currentArticle = createStore<MGetOneArticle.Response | null>(null).on(
  getOneArticleFX.doneData,
  (_, data) => data,
);

// Cover article
const changeСoverArticleEv = createEvent<File | null>();
const $coverArticle = createStore<File | null>(null).on(
  changeСoverArticleEv,
  (_, newСoverArticle) => newСoverArticle,
);

// Category article
const changeCategoryArticleEv = createEvent<string>();
const changeViewCategoryArticleEv = createEvent<string>();
const $categoryArticle = createStore<string>("").on(
  changeCategoryArticleEv,
  (_, newCategoryArticle) => newCategoryArticle,
);
const $viewCategoryArticle = createStore<string>("Категории").on(
  changeViewCategoryArticleEv,
  (_, newCategoryArticle) => newCategoryArticle,
);

// Age category article
// Age category article
const changeAgeCategoryArticleEv = createEvent<string | string[]>();
const changeViewAgeCategoryArticleEv = createEvent<string | string[]>();
const $ageCategoryArticle = createStore<string[]>([]).on(
  changeAgeCategoryArticleEv,
  (_, newAgeCategoryArticle) => {
    if (Array.isArray(newAgeCategoryArticle)) {
      return newAgeCategoryArticle;
    } else {
      return [newAgeCategoryArticle];
    }
  },
);
const $viewAgeCategoryArticle = createStore<string[]>([]).on(
  changeViewAgeCategoryArticleEv,
  (_, newViewAgeCategoryArticle) => {
    if (Array.isArray(newViewAgeCategoryArticle)) {
      return newViewAgeCategoryArticle;
    } else {
      return [newViewAgeCategoryArticle];
    }
  },
);

// Tags article
const changeTagsArticleEv = createEvent<string>();
const $tagsArticle = createStore<string>("").on(
  changeTagsArticleEv,
  (_, newTagsArticle) => newTagsArticle,
);

export {
  /**
   * @effects
   */
  uploadFileImageFx,
  getAllArticlesFx,
  createArticleFx,
  getOneArticleFX,
  changeArticleFx,
  /**
   * @stores
   */
  $articlesList,
  $currentArticleEditor,
  $currentArticlePayload,
  $currentArticleTitlePayload,
  $currentArticle,
  $coverArticle,
  $categoryArticle,
  $ageCategoryArticle,
  $viewCategoryArticle,
  $viewAgeCategoryArticle,
  $tagsArticle,
  /**
   * @events
   */
  changeArticleEditorEvent,
  changeCurrentArticlePayloadEv,
  changeCurrentArticlePayloadTitleEv,
  changeСoverArticleEv,
  changeCategoryArticleEv,
  changeAgeCategoryArticleEv,
  changeViewCategoryArticleEv,
  changeViewAgeCategoryArticleEv,
  changeTagsArticleEv,
};
