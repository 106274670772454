import { ISchoolModel } from "entities/school";
import { MGetAllUsers } from "entities/users";
import { MGetAllDoctors } from "entities/doctors";

// moderator filtering
const filterModerators = /* #__PURE__ */ (
  users: ISchoolModel[],
  sortUp: boolean,
) => {
  const statusOrder = sortUp ? ["ACTIVE", "INACTIVE"] : ["INACTIVE", "ACTIVE"];

  const compareByStatus = (a: ISchoolModel, b: ISchoolModel) =>
    statusOrder.indexOf(a.state) - statusOrder.indexOf(b.state);

  return [...users].sort(compareByStatus);
};

// return final values
const finalyValues = /* #__PURE__ */ (
  users: MGetAllUsers.Response | MGetAllDoctors.Response | null,
  sort: boolean,
) => {
  const list: Array<ISchoolModel> = users ? Object.values(users)[0] : [];
  const filteredUsers = filterModerators(list, sort);

  return filteredUsers;
};

export { finalyValues };
