import React from "react";
import Logo from "shared/icons/logo.svg";
import { AuthForm } from "features/auth";

const AuthPage = () => {
  return (
    <div
      className={
        "flex justify-center flex-col items-center w-[100vw] h-[100vh]"
      }
    >
      <img className={"fixed top-12"} src={Logo} alt={"logo"} />
      <AuthForm />
    </div>
  );
};

export { AuthPage };
