import { IPromocode, IUpdatePromocode, IAddPromocode } from "./types";
import { AxiosResponse } from "axios";
import { $http } from "shared/services/http";

class PromocodeAPI {
  fetchAllPromocode(): Promise<AxiosResponse<IPromocode.Response>> {
    return $http.get("/moderator/create/promocode/list");
  }

  updatePromocode(params: IUpdatePromocode.Params) {
    return $http.patch("/moderator/create/promocode", params);
  }

  deletePromocode(params: string) {
    return $http.delete(`/moderator/create/promocode?id=${params}`);
  }

  addPromocode(params: FormData) {
    return $http.post(`/moderator/create/promocode`, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export const promocodeAPI = new PromocodeAPI();
