import { MGetAllUsers } from "../users";
import { createStore, createEffect, guard } from "effector";
import { feedbackApi } from "./api";
import { MFeedbackList, IStatusFeedback, IGoFeedbackChat } from "./types";

const getFeedbackListFx = createEffect({
  handler: async (params?: MFeedbackList.Params) => {
    const data = await feedbackApi.getList(params);

    return data.data;
  },
});

const changeStatusFeedbackFx = createEffect({
  handler: async (params: IStatusFeedback.Params) => {
    const data = await feedbackApi.changeStatusFeedback(params);

    return data.data;
  },
});

const goFeedbackChatFx = createEffect({
  handler: async (params: IGoFeedbackChat) => {
    const data = await feedbackApi.goFeedbackChat(params);
    return data.data;
  },
});

// tracking upload
const createGuard = (fx: any) => {
  guard({
    source: fx.doneData,
    filter: (data) => data !== null,
  }).watch(() => {
    getFeedbackListFx();
  });
};

createGuard(changeStatusFeedbackFx);

const feedbackList = createStore<MFeedbackList.Response | null>(null).on(
  getFeedbackListFx.doneData,
  (_, data) => data,
);

export {
  /**
   * @effects
   */
  getFeedbackListFx,
  changeStatusFeedbackFx,
  goFeedbackChatFx,
  /**
   * @store
   */
  feedbackList,
  /**
   * @event
   */
};
