import { AxiosResponse } from "axios";
import { $http } from "shared/services/http";
import { MCreateArticle, MGetMusic, MUpdateMusic } from "./types";

class MusicAPI {
  fetchMusic(music: string): Promise<AxiosResponse<MGetMusic.Response>> {
    return $http.get(`music/descriptions/${music}`);
  }

  updateMusicStatus(
    music_id: string,
    status: string,
  ): Promise<AxiosResponse<MUpdateMusic.Response>> {
    return $http.patch(`music/`, {
      music_id,
      status,
    });
  }

  deleteMusic(id: string) {
    return $http.delete(`/moderator/create/music/delete/`, {
      data: { id },
    });
  }

  addMusic(data: MCreateArticle.Body) {
    return $http.post(`/moderator/create/music/addComposition`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export const musicAPI = new MusicAPI();
