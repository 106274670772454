import {
  MAccount,
  AccountAvatar,
  OnlineSchoolChangeInfo,
  AccountChangePhone,
  AccountAllCourse,
  AccountAddSchoolCourse,
  DoctorChangeInfo,
} from "./types";
import { AxiosResponse } from "axios";
import { $http } from "shared/services/http";

class AccountAPI {
  /**
   * @description Возвращает информацию по аккаунту
   */
  getAccountInfo(): Promise<AxiosResponse<MAccount.Response>> {
    return $http.get("account");
  }
  /**
   * @description Изменить аватар аккаунта
   */
  putAccountAvatar(
    data: AccountAvatar.Params,
  ): Promise<AxiosResponse<AccountAvatar.Response>> {
    return $http.put("account/avatar", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  /**
   * @description Изменить инфо онлайн школы
   */
  putOnlineSchoolChangeInfo(data: OnlineSchoolChangeInfo.Params) {
    return $http.put("online-school", data);
  }
  /**
   * @description Изменить инфо онлайн школы
   */
  patchDoctorChangeInfo(data: DoctorChangeInfo.Params) {
    return $http.patch("doctor", data);
  }
  /**
   * @description Изменить телефон онлайн школы
   */
  patchAccountChangePhone(data: AccountChangePhone.Params) {
    const encodedNumber = encodeURIComponent(data.number);
    return $http.patch(
      `account/number?id=${data.id}&number=${encodedNumber}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
  }
  /**
   * @description Получить список всех курсов школы
   */
  getOnlineSchoolCourses(
    data: AccountAllCourse.Params,
  ): Promise<AxiosResponse<AccountAllCourse.Respose>> {
    return $http.get(`online-school/course/all?school_id=${data.school_id}`);
  }
  /**
   * @description Добавить курс к школе
   */
  postOnlineSchoolAddCourse(data: AccountAddSchoolCourse.Params) {
    return $http.post("online-school/course", data);
  }
  /**
   * @description Удалить курс к школе
   */
  deletedOnlineSchoolAddCourse(data: string) {
    return $http.delete(`online-school/course?id=${data}`);
  }
}

export const accountAPI = new AccountAPI();
